import "../../assets/css/SearchItem.css";
import { Link } from 'react-router-dom';
//import { useDispatch, useSelector } from 'react-redux';
import React from "react";


export default function AllTitlesSearchItem({title}) {

    return (
        <>
        <div className="simpleFade searchItem">
            
            <div className="searchItemHeader">
                    <h3 className="searchItemTitle">
                        <Link className="searchItemLink" to={`/title/${title.id}`}>{ title.title }</Link>
                        
                        { title.year[0] && <div className="searchItemYear">({ title.year[0] })</div>}
                        
                    </h3>
                    
            </div>
            
            
            <div className="searchItemDetails">
                <ul>
                    { title.subMedium && 
                        <li>
                            <strong>Media:</strong>&nbsp;
                            <em>
                                <Link rel="nofollow" to={`/search/subMedium=${title.subMedium}`}>{ title.subMedium }</Link>
                            </em>
                        </li> 
                    }
                    
                    { title.forms && title.forms.length > 0 && 
                        <li>
                            <strong>Form:</strong>
                            { title.forms.map((e, i) => {
                                return (
                                    <span key={i}>&nbsp;
                                        <em>
                                            <Link rel="nofollow" to={`/search/forms=${e}`}>{e}</Link>
                                        </em>
                                    </span>
                                )
                            }) 
                            }
                        </li>
                    }

                    { title.genres && title.genres.length > 0 && 
                        <li>
                            <strong>Genre:</strong>
                            { title.genres.map((e, i) => {
                                return (
                                    <span key={i}>&nbsp;
                                        <em>
                                            <Link rel="nofollow" to={`/search/parentTitle.genres=${e}`}>{e}</Link>
                                        </em>
                                    </span>
                                )
                            }) 
                            }
                        </li>
                    }
                    
                </ul>

                
            </div>

           

        </div>

        </>
    );

}