import { createSlice } from "@reduxjs/toolkit";

export const listSlice = createSlice({
    name: "list",
    initialState: {
        items: [],
        //total: 0,
        // isLoading: true
    },
    reducers: {
        setListItems: (state, action) => {
            state.items = action.payload.items;
        },
        addItemToList: (state, action) => {
            state.items.push(action.payload);
            localStorage.setItem("nfsaItems", JSON.stringify(state));
        },
        removeItemFromList: (state, action) => {
            //console.log(JSON.stringify(state));
            const i = state.items.indexOf(action.payload);
            if (i > -1) {
                state.items.splice(i, 1);
            }
            localStorage.setItem("nfsaItems", JSON.stringify(state));
           // console.log(JSON.stringify(state));
        },
        clearList: (state) => {
            state.items = [];
            localStorage.removeItem("nfsaItems");
        },
    },
});

export const { setListItems, clearList, addItemToList, removeItemFromList } = listSlice.actions;

export default listSlice.reducer;