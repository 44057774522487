import React from "react";


export default function FacetButton({handleHomeFieldCheck, iconClass, labelText}) {

    const [facetChecked, setFacetChecked] = React.useState(false);

    function onCheckChange(e) {
        setFacetChecked(!facetChecked);
        handleHomeFieldCheck(e);
    }


    return (
        <div className={`facetButton ${facetChecked ? 'checked' : ''} ${iconClass}`}>
            <label htmlFor={iconClass}>
                <span>{labelText}</span>
                {/* <input onChange={e => handleHomeFieldCheck(e)} type="checkbox" value={labelText}/> */}
                <input id={iconClass} onChange={e => onCheckChange(e)} type="checkbox" value={labelText}/>
            </label>
            
        </div>
    )
}