import { Link } from 'react-router-dom';
import creditLinkClicked from '../../../helpers/creditLinkClicked';
import formatCreditName from '../../../helpers/formatCreditName';

export default function Credit({title}) {
    
    if (!title.credits) return;
    if (!title.forms || title.forms < 1) return;

    let creditObj;

    /* These rules defined in "Older STC Requirements_v02_2016.doc" */
    switch(title.forms[0].toLowerCase()) { 
        case "actuality": 
            /* Director > Production Company > Cinematographer */
            title.credits.every((e) => {
                if (e == null || e.role == null) return true;
                if (e.role.toLowerCase() === "director") {
                    creditObj = e;
                    return false; //bail from loop
                }
                if (e.role.toLowerCase() === "production company") {
                    creditObj = e;
                    return false; //bail from loop
                }
                if (e.role.toLowerCase() === "cinematographer") {
                    creditObj = e;
                    return false; //bail from loop
                }
                return true; //continue loop
            });
        break;
        case "advertisement (includes promotional)":
            /* Advertising Agency > Production Compony > Director */
            title.credits.every((e) => {
                if (e == null || e.role == null) return true;
                if (e.role.toLowerCase() === "advertising agency") {
                    creditObj = e;
                    return false; //bail from loop
                }
                if (e.role.toLowerCase() === "production company") {
                    creditObj = e;
                    return false; //bail from loop
                }
                if (e.role.toLowerCase() === "director") {
                    creditObj = e;
                    return false; //bail from loop
                }
                return true; //continue loop
            });
        break;
        case "documentary":
            /* Director > Production Company */
            title.credits.every((e) => {
                if (e == null || e.role == null) return true;
                if (e.role.toLowerCase() === "director") {
                    creditObj = e;
                    return false; //bail from loop
                }
                if (e.role.toLowerCase() === "production company") {
                    creditObj = e;
                    return false; //bail from loop
                }
                return true; //continue loop
            });
        break;
        case "Feature Film":
            /* Director > Production Company */
            title.credits.every((e) => {
                if (e == null || e.role == null) return true;
                if (e.role.toLowerCase() === "director") {
                    creditObj = e;
                    return false; //bail from loop
                }
                if (e.role.toLowerCase() === "production company") {
                    creditObj = e;
                    return false; //bail from loop
                }
                return true; //continue loop
            });
        break;
        case "field recording":
            /* Recording Engineer > Editor (Sound) */
            title.credits.every((e) => {
                if (e == null || e.role == null) return true;
                if (e.role.toLowerCase() === "Recording engineer") {
                    creditObj = e;
                    return false; //bail from loop
                }
                if (e.role.toLowerCase() === "editor (sound)") {
                    creditObj = e;
                    return false; //bail from loop
                }
                return true; //continue loop
            });
        break;
        case "game (interactive)":
            /* creator */
            title.credits.every((e) => {
                if (e == null || e.role == null) return true;
                if (e.role.toLowerCase() === "creator") {
                    creditObj = e;
                    return false; //bail from loop
                }
                return true; //continue loop
            });
        break;
        case "live performance (includes concert)":
            /* performer */
            title.credits.every((e) => {
                if (e == null || e.role == null) return true;
                if (e.role.toLowerCase() === "performer") {
                    creditObj = e;
                    return false; //bail from loop
                }
                return true; //continue loop
            });
        break;
        case "manuscript":
            /* author */
            title.credits.every((e) => {
                if (e == null || e.role == null) return true;
                if (e.role.toLowerCase() === "author") {
                    creditObj = e;
                    return false; //bail from loop
                }
                return true; //continue loop
            });
        break;
        case "mini series": 
            /* Production Company > Producer > Director*/
            title.credits.every((e) => {
                if (e == null || e.role == null) return true;
                if (e.role.toLowerCase() === "production company") {
                    creditObj = e;
                    return false; //bail from loop
                }
                if (e.role.toLowerCase() === "producer") {
                    creditObj = e;
                    return false; //bail from loop
                }
                if (e.role.toLowerCase() === "director") {
                    creditObj = e;
                    return false; //bail from loop
                }
                return true; //continue loop
            });
        break;
        case "music": 
            /* Performer > Composer */
            title.credits.every((e) => {
                if (e == null || e.role == null) return true;
                if (e.role.toLowerCase() === "performer") {
                    creditObj = e;
                    return false; //bail from loop
                }
                if (e.role.toLowerCase() === "composer") {
                    creditObj = e;
                    return false; //bail from loop
                }
                return true; //continue loop
            });
        break;
        case "newsreel": 
            /* Production Company > Director */
            title.credits.every((e) => {
                if (e == null || e.role == null) return true;
                if (e.role.toLowerCase() === "production company") {
                    creditObj = e;
                    return false; //bail from loop
                }
                if (e.role.toLowerCase() === "director") {
                    creditObj = e;
                    return false; //bail from loop
                }
                return true; //continue loop
            });
        break;
        case "oral history": 
            /* interviewer */
            title.credits.every((e) => {
                if (e == null || e.role == null) return true;
                if (e.role.toLowerCase() === "interviewer") {
                    creditObj = e;
                    return false; //bail from loop
                }
                return true; //continue loop
            });
        break;
        case "personal effects": 
            /* subject */
            title.credits.every((e) => {
                if (e == null || e.role == null) return true;
                if (e.role.toLowerCase() === "subject") {
                    creditObj = e;
                    return false; //bail from loop
                }
                return true; //continue loop
            });
        break;
        case "play": 
            /* Director > Production Company*/
            title.credits.every((e) => {
                if (e == null || e.role == null) return true;
                if (e.role.toLowerCase() === "director") {
                    creditObj = e;
                    return false; //bail from loop
                }
                if (e.role.toLowerCase() === "production company") {
                    creditObj = e;
                    return false; //bail from loop
                }
                return true; //continue loop
            });
        break;
        case "series": 
            /* Production Company > Presenter > Producer > Broadcaster */
            title.credits.every((e) => {
                if (e == null || e.role == null) return true;
                if (e.role.toLowerCase() === "production company") {
                    creditObj = e;
                    return false; //bail from loop
                }
                if (e.role.toLowerCase() === "presenter") {
                    creditObj = e;
                    return false; //bail from loop
                }
                if (e.role.toLowerCase() === "producer") {
                    creditObj = e;
                    return false; //bail from loop
                }
                if (e.role.toLowerCase() === "broadcaster") {
                    creditObj = e;
                    return false; //bail from loop
                }
                return true; //continue loop
            });
        break;
        case "sheet music": 
            /* Composer > Production Company*/
            title.credits.every((e) => {
                if (e == null || e.role == null) return true;
                if (e.role.toLowerCase() === "composer") {
                    creditObj = e;
                    return false; //bail from loop
                }
                if (e.role.toLowerCase() === "production company") {
                    creditObj = e;
                    return false; //bail from loop
                }
                return true; //continue loop
            });
        break;
        case "short": 
            /* director */
            title.credits.every((e) => {
                if (e == null || e.role == null) return true;
                if (e.role.toLowerCase() === "director") {
                    creditObj = e;
                    return false; //bail from loop
                }
                return true; //continue loop
            });
        break;
        case "sound effects": 
            /* Recording Engineer > Editor (Sound) */
            title.credits.every((e) => {
                if (e == null || e.role == null) return true;
                if (e.role.toLowerCase() === "recording engineer") {
                    creditObj = e;
                    return false; //bail from loop
                }
                if (e.role.toLowerCase() === "editor (sound)") {
                    creditObj = e;
                    return false; //bail from loop
                }
                return true; //continue loop
            });
        break;
        case "special (a one off program for TV or Radio)": 
            /* Production Company > Producer > Performer */
            title.credits.every((e) => {
                if (e == null || e.role == null) return true;
                if (e.role.toLowerCase() === "production company") {
                    creditObj = e;
                    return false; //bail from loop
                }
                if (e.role.toLowerCase() === "producer") {
                    creditObj = e;
                    return false; //bail from loop
                }
                if (e.role.toLowerCase() === "performer") {
                    creditObj = e;
                    return false; //bail from loop
                }
                return true; //continue loop
            });
        break;
        case "spoken word": 
            /* Speaker > Performer */
            title.credits.every((e) => {
                if (e == null || e.role == null) return true;
                if (e.role.toLowerCase() === "speaker") {
                    creditObj = e;
                    return false; //bail from loop
                }
                if (e.role.toLowerCase() === "performer") {
                    creditObj = e;
                    return false; //bail from loop
                }
                return true; //continue loop
            });
        break;
        case "telemovie": 
            title.credits.every((e) => {
                if (e == null || e.role == null) return true;
                if (e.role.toLowerCase() === "director") {
                    creditObj = e;
                    return false; //bail from loop
                }
                if (e.role.toLowerCase() === "production company") {
                    creditObj = e;
                    return false; //bail from loop
                }
                return true; //continue loop
            });
        break;
        // case "video game": 
        //     title.credits.every((e) => {
        //         if (e == null || e.role == null) return true;
        //         if (e.role.toLowerCase() === "developer") {
        //             creditObj = e;
        //             return false; //bail from loop
        //         }
        //         return true; //continue loop
        //     });
        // break;
        case "Trailer": 
            /* Director > Production Company */
            title.credits.every((e) => {
                if (e == null || e.role == null) return true;
                if (e.role.toLowerCase() === "director") {
                    creditObj = e;
                    return false; //bail from loop
                }
                if (e.role.toLowerCase() === "production company") {
                    creditObj = e;
                    return false; //bail from loop
                }
                return true; //continue loop
            });
        break;
        default:
    }

    if (!creditObj) {
        //return;
        title.credits.every((e) => { //grab the first that isnt copyright contact
            if (e == null || e.role == null) return true;
            if (e.role.toLowerCase() !== "copyright contact") {
                creditObj = e;
                return false; //bail from loop
            }
            
            return true; //continue loop
        });
    }

    if (!creditObj) return; 

    let credit = <div className='searchItemExtra'>
        <span>{creditObj.role}:</span><Link onClick={() => creditLinkClicked(formatCreditName(creditObj), creditObj.role)} to={`/${createCreditSearchLink(creditObj.id, creditObj.role)}`}>{formatCreditName(creditObj)}</Link>
    </div> 

    return (
        <>
           { credit } 
           
        </>
    )

    function createCreditSearchLink(cNameID, crole) {
        //let sQuery = `search/crole=${encodeURIComponent(crole)}&cNameID=${cNameID}`;
        let sQuery = `search/credits.id=${cNameID}&credits.role=${encodeURIComponent(crole)}`;
        return sQuery;
    }
}
