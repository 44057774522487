import { Link } from 'react-router-dom';
import creditLinkClicked from '../../helpers/creditLinkClicked';
import formatCreditName from '../../helpers/formatCreditName';
export default function Titlecredits({title}) {

    if (!title.credits || title.credits.length < 1) return null;
    let creditElements = [];

    //remove null values;
    let credits = title.credits.filter(n => n);

    /*group the credits into groupedcredits associtive array like
        [Director] -> [name, name, ..etc]
        [Producer] -> [name, name,..etc]
    */
    let groupedcredits = [];
    credits.forEach(function(credit, i){
        if (credit.role in groupedcredits) {
            groupedcredits[credit.role].push(credit);
        } else {
            groupedcredits[credit.role] = [credit];
        }
    });

    //create elements from grouped titles
    for (var key in groupedcredits) {
        //console.log(groupedcredits[key]);
        creditElements.push(
            <div key={key} className="creditItem">
                <span className='creditTitle'>{key}</span>
                <div className="creditList">
                    { groupedcredits[key].map((c,i) => {
                        return (
                            <Link key={i} onClick={() => creditLinkClicked(c.name.trim(), c.role)} to={`/search/credits.id=${c.id}&credits.role=${encodeURIComponent(c.role)}`}>
                                { formatCreditName(c) }
                            </Link>
                        )
                    }) }
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="creditsTable">
                {creditElements}
            </div>
        </>

    )
}