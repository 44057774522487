import { createSlice } from "@reduxjs/toolkit";

//This is for the SEARCHBAR FIELD DROPDOWN - it is so we can toggle it off on body/main click
export const fieldDropSlice = createSlice({
    name: "fieldDrop",
    initialState: {
        showFieldDrop: false,
    },
    reducers: {
        setShowFieldDrop: (state, action) => {
            state.showFieldDrop = action.payload;
        },
    },
});

export const { setShowFieldDrop } = fieldDropSlice.actions;

export default fieldDropSlice.reducer;