//import React, { useEffect, useLayoutEffect } from "react";
//import React, { useEffect } from "react";
import React from "react";
import HoldingTabs from "../Holdings/HoldingTabs";
import HoldingPlayer from "./HoldingPlayer";

export default function MasonryHoldingItem({fadeIn, holding, title, closed}) {

    const [expanded, setExpanded] = React.useState(false);

     //for animation of expanded content, the height can be different for each HoldingItem
    const [componentHeight, setComponentHeight] = React.useState(null);

    const [initialComponentHeight, setInitialComponentHeight] = React.useState(null); //unexpanded
    const [expandedTab1Height, setExpandedTab1Height] = React.useState(null); //full component height with expanded and first tab
    const [expandedTab2Height, setExpandedTab2Height] = React.useState(null); //full component height with expanded and first tab

    const [tabSelected, setTabSelected] = React.useState("tab1"); //on refilter/close we need to send down 'tab' state so on refilter items get set back to tab1

    const expandedContent = React.useRef(null); //passed to HoldingTabs component - the height of this changes dynamically depending on what tab is selected/shown
    const holdingItemRef = React.useRef(null); //component in full - includes expandedContent as child
    const imageRef = React.useRef(null); //used to access the image onload 'complete' property


    function onExpandClick() {
        
        if (expanded) { //already open/expanded
            //set compnent back to normal unexpanded height
            setComponentHeight(initialComponentHeight);
        } else { //if not expanded, add the expanded content height to the component height
            
            setComponentHeight(holdingItemRef.current.clientHeight + expandedContent.current.clientHeight);

            // if (tabSelected === "tab1") { //expanding can occur with either tab selected
            //     setExpandedTab1Height(holdingItemRef.current.clientHeight + expandedContent.current.clientHeight); 
            // } else {
            //     setExpandedTab2Height(holdingItemRef.current.clientHeight + expandedContent.current.clientHeight); 
            // }
        }
        setExpanded(!expanded);
    }

    React.useEffect(() => { //for images/videos we set initial height and component height onImageLoad() but for non-image items we set at first [] render.
        if (typeof holding.preview === 'undefined' || (holding.preview.type !== 'video' && holding.preview.type !== 'image')) {
        //if (typeof holding.preview === 'undefined') {
            setComponentHeight(holdingItemRef.current.clientHeight); 
            setInitialComponentHeight(holdingItemRef.current.clientHeight); 
        }
    }, [holding.preview]);

    function onTabResize(tab) {

        if (!expanded) return;

        setTabSelected(tab);

        if (tab === "tab1") {
             //expandedTab1Height is always set on initial expand - but NOT if initial expand occurs on tab2
            if (expandedTab1Height === null) {
                let newHeight = initialComponentHeight + expandedContent.current.clientHeight;
                setComponentHeight(newHeight); 
                setExpandedTab1Height(newHeight)
            } else {
                setComponentHeight(expandedTab1Height);
            }
        } else { //tab2
            //because tab2 will by default not be selected, we need to calculated its height for the first time here
            if (expandedTab2Height === null) {
                let newHeight = initialComponentHeight + expandedContent.current.clientHeight;
                setComponentHeight(newHeight); 
                setExpandedTab2Height(newHeight)
            } else { //tab2height has already been prefilled
                setComponentHeight(expandedTab2Height);
            }
            
        }
    }

    

    // if 'closed' value sent we unexpand - this is for refiltering buttons
    React.useEffect(() => {
        setExpanded(false);
        setComponentHeight(initialComponentHeight);
        //need to set tabs back to null to ensure tab heights are calculated anew on each expand
        setExpandedTab1Height(null); 
        setExpandedTab2Height(null);
        setTabSelected("tab1");
    }, [closed, initialComponentHeight])

    function onImageLoad() {//reset the component height after the image has loaded
        if (imageRef.current && imageRef.current.complete) {
            setTimeout(() => { //sometimes triggers before image completed??
                setComponentHeight(holdingItemRef.current.clientHeight); //this changes
                setInitialComponentHeight(holdingItemRef.current.clientHeight); //we store the original height to reference it back
            }, 300);
        }
    }

    function onVideoLoad() {
        //console.log("parent on video load");
       
        setTimeout(() => { //sometimes triggers before image completed??
            setComponentHeight(holdingItemRef.current.clientHeight); //this changes
            setInitialComponentHeight(holdingItemRef.current.clientHeight); //we store the original height to reference it back
        }, 300);
    }
    return (
        // simpleFade class is removed after a few seconds
        // <div ref={holdingItemRef} className={`holdingItem ${fadeIn ? '' : 'simpleFade'}`} style={componentHeight ? {height: componentHeight + "px"} : {}}>
        <div ref={holdingItemRef} className={`holdingItem ${fadeIn ? '' : 'simpleFade'}`} style={componentHeight ? {height: componentHeight + "px"} : {}}>
            <div className="holdingItemInner">
                
                { holding.preview && holding.preview.type === 'image' &&
                    <div className="previewImage">
                        <img ref={imageRef} onLoad={() => onImageLoad()} src={`https://media.nfsacollection.net/${holding.preview.thumbnailFilePath}`} alt={title.title} />
                    </div>
                }
                
                { holding.preview && holding.preview.type === 'audio' &&
                    <HoldingPlayer holding={holding} />
                }

                { holding.preview && holding.preview.type === 'video' &&
                    <HoldingPlayer holding={holding} onVideoLoad={onVideoLoad}/>
                }

                { holding.preview && holding.preview.type === 'file' &&
                    <div className="file">
                        <a rel="noreferrer" target="_blank" href={`https://media.nfsacollection.net/${holding.preview.filePath}`}>
                            View/Download
                        </a>
                    </div>
                }
                <div className="holdingItemContent">
                    
                    <HoldingTabs expandedContent={expandedContent} tabSelected={tabSelected} expanded={expanded} holding={holding} onExpandClick={onExpandClick} onTabResize={onTabResize}/>
                    
                </div>
            </div>
        </div>
    )
}