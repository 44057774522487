
import "../assets/css/BurgerHeader.css";
import { useDispatch, useSelector } from 'react-redux';
import { setShowFilterMenu } from '../redux/filterMenuSlice';

export default function BurgerHeader() {

    const dispatch = useDispatch();

    let showFilters = useSelector((state) => {
        return state.filterMenu.showFilterMenu
    });

    function toggleFilter() {
        document.querySelector('.filtersButton').focus();
        window.scrollTo(0, 0);
        dispatch(setShowFilterMenu(false));
    }

    return (
        <div className={`burgerHeader${showFilters ? ' show' : ''}`}>
            <a className="burgerLogo" aria-label="Go to main website" href="https://www.nfsa.gov.au">
                <span>Go to https://www.nfsa.gov.au</span>
            </a>
            <button aria-label="Close the filters menu" className="burgerClose" tabIndex="-1" onClick={() => toggleFilter()}>
                <span>Close</span>
            </button>

        </div>
    );
}