//import { useDispatch } from 'react-redux';
//import { removeItemFromList } from '../redux/listSlice';
import { Link } from 'react-router-dom';
//import Credit from '../SearchResults/SearchItem/Credit';
import Year from '../SearchResults/SearchItem/Year';
import React from "react";

export default function ListItem({title, onRemove}) {

    //const [isRemoving, setIsRemoving] = React.useState(false);
    // function removeClicked(id) {
    //     console.log("removeClicked " + id);
    //     setIsRemoving(true);

    //     setTimeout(function() {
    //         console.log("calling on remove");
    //         onRemove(id);

    //     }, 1000)
    // }

    return (
        <li className="listItem">
        {/*  <li className={`listItem ${isRemoving ? 'removing' : ''}`}> */}
            <div className="listItemRow">
                <div className="listItemLine">
                    <div className='left'>
                        <Link className="listItemLink" to={`/title/${title.id}`}>{ title.title }</Link>
                        <Year title={title} />
                    </div>
                    {/* { title.name && title.name !== 'TITLE VERSION' &&
                        <><span className="versionName">{ title.name }</span></>
                    } */}
                    
                    {/* <br /><Credit title={title}/> */} 

                    {/* { title.summary && 
                        <div className="listItemText">
                            
                            <div>
                                { title.summary.substring(0,250) }... 
                                <Link to={`/title/${title.id}`}>[more]</Link>
                            </div>
                            
                        </div>
                    } */}
                    <div className='right'>
                        <div className='labelId'>NFSA ID: {title.id}</div>
                    </div>
                </div>

                <button onClick={() => (onRemove(title.id))} title="Remove this title" className="trashButton"></button>
            </div>
            
            
            



            
        </li>
    );
}