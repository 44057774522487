import { useDispatch } from 'react-redux';
import { setShowFilterMenu } from '../redux/filterMenuSlice';
import { useLocation } from 'react-router-dom';
import "../assets/css/FilterButton.css";
export default function FilterButton() {
    
    const dispatch = useDispatch();

    let location = useLocation();

    function toggleFilter() {
        dispatch(setShowFilterMenu(true));
        window.scrollTo(0, 0);
        document.querySelector('.colSide').focus();
    }

    if (!location.pathname.includes("/search")) return null; //only show on search result pages

    return (
        <button aria-label="Show Filters" className="filtersButton" onClick={() => toggleFilter()}>
            <span>Add Filters</span>
        </button>
    )
}