


export default function generateIconClass(facetName, facetValue) {
    if (facetName === 'subMedium') {
        switch (facetValue) {
            case 'Artefact': return ' icon artefact';
            case 'Documentation': return ' icon documentation';
            case 'Film': return ' icon film';
            case 'Multimedia': return ' icon multimedia';
            case 'Online Media': return ' icon onlineMedia';
            case 'Radio': return ' icon radio';
            case 'Software': return ' icon software';
            case 'Sound Recording, Published': return ' icon soundPublished';
            case 'Sound Recording, Unpublished': return ' icon soundUnPublished';
            case 'Television': return ' icon television';
            default: return '';
        }
    }
}