import getSearchFromRoute from "../helpers/getSearchFromRoute";
export default function getFieldsFromURL() {
    let urlSearch = getSearchFromRoute("/search/");
        var sTextInOrItems = []
        if (urlSearch.includes("queryIn")) {
            let urlItems = urlSearch.split('&');
            for (var i = 0; i < urlItems.length; i++) {
                var pair = urlItems[i].split('=');
                if (pair[0] === "queryIn") {
                    sTextInOrItems = decodeURIComponent(pair[1]).split('|');
                }
            }
        }
    return sTextInOrItems;
}