import { createSlice } from "@reduxjs/toolkit";

/*
    Recaptcha script is loaded on the request form route in useEffect mount
    but recaptcha scripts inserts extra random scripts.
    So if you land on the request form page, navigate away, and then return, then
    the random recaptcha scripts keep being added. This toggle ensures that once recaptcha is loaded,
    it does not get loaded again.
*/
export const recaptchaSlice = createSlice({
    name: "recaptcha",
    initialState: {
        recaptchaLoaded: false 
    },
    reducers: {
        setRecaptchaLoaded: (state, action) => {
            state.recaptchaLoaded = action.payload;
        }
    },
});

export const { setRecaptchaLoaded } = recaptchaSlice.actions;

export default recaptchaSlice.reducer;