import { createSlice } from "@reduxjs/toolkit";

export const popMessageSlice = createSlice({
    name: "popmessage",
    initialState: {
        show: false,
    },
    reducers: {
        togglePopMessage: (state) => {
            state.isDown = !state.show;
        },
        setPopMessage: (state, action) => {
            state.show = action.payload;
        }
    },
});

export const { togglePopMessage, setPopMessage } = popMessageSlice.actions;

export default popMessageSlice.reducer;