//Just on onClick function used across multiple components to capture facet actions.
import googleTagPush from "./googleTagPush";

const sendFacetGoogle = (facetName, facetValue) => {
    //console.log("link clicked with facet: " + facetName + " and value " + facetValue);
    googleTagPush('facet_selected', {
        "facet_name": facetName,
        "facet_value": facetValue
    });
}

export default sendFacetGoogle;