
export default function Year({title}) {

    if (!title.productionDates || title.productionDates.length < 1) return;

    let year;
    title.productionDates.every((date,i) => {
        if (date.type === "Release" ||
            date.type === "Production" ||
            date.type === "Broadcast" ||
            date.type === "Covered" ||
            date.type === "Published" ||
            date.type === "Printed" ||
            date.type === "Manufactured" ||
            date.type === "Created" ||
            date.type === "Compiled" ||
            date.type === "Recorded"
        ) {
            year = date.fromYear;
            return false;
        }

        return true;
    } );

    if (!year) return;

    return (
        <div className="searchItemYear"> &mdash; { year }</div>
    );
}