import { createSlice } from "@reduxjs/toolkit";

export const searchSlice = createSlice({
    name: "search",
    initialState: {
        searchString: "",
        searchFields: [],
        searchFacets: [],
        exactSearch: false,
        previewsSearch: false,
        exactStringSearch: "",
        excludeStringSearch: "",
    },
    reducers: {
        setSearchString: (state, action) => {
            state.searchString = action.payload;
        },
        setSearchFields: (state, action) => {
            state.searchFields = action.payload;
        },
        setSearchFacets: (state, action) => {
            state.searchFacets = action.payload;
        },
        setExactSearch: (state, action) => {
            state.exactSearch = action.payload;
        },
        setPreviewsSearch: (state, action) => {
            state.previewsSearch = action.payload;
        },
        setExactStringSearch: (state, action) => {
            state.exactStringSearch = action.payload;
        },
        setExcludeStringSearch: (state, action) => {
            state.excludeStringSearch = action.payload;
        }

    },
});

export const { setSearchString, setSearchFields, setSearchFacets, setExactSearch, setPreviewsSearch, setExactStringSearch, setExcludeStringSearch } = searchSlice.actions;
//export const { setSearchString, setSearchFields, setExactSearch, setExactStringSearch, setExcludeStringSearch } = searchSlice.actions;

export default searchSlice.reducer;