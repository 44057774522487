import "../../../assets/css/SearchItem.css";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React from "react";
import Credit from './Credit';
import Year from './Year';
import { addItemToList, removeItemFromList } from '../../../redux/listSlice';
import { setPopMessage } from '../../../redux/popMessageSlice';
import googleTagPush from "../../../helpers/googleTagPush";
import sendFacetGoogle from "../../../helpers/sendFacetGoogle";
import wrapFacetValueCommas from "../../../helpers/wrapFacetValueCommas";
import generateIconClass from "../../../helpers/generateIconClass";
import ListButton from "./ListButton";

export default function SearchItem({title}) {

    const dispatch = useDispatch();
    const isInList = useSelector((state) => {
        return state.list.items.includes(title.id);
    });

    function onListClick(_id, textTitle) {
        if (isInList) {
            dispatch(removeItemFromList(_id));
            dispatch(setPopMessage(false));
        } else {
            googleTagPush("title_added_to_list", {
                "add_to_list_title_number" : _id,
                "add_to_list_title" : textTitle
            });
            dispatch(addItemToList(_id));
            dispatch(setPopMessage(true));
        }
    }

    function highlightText(path) { 
        
        if (!title.highlight) return {__html: title[path]};

        let dontHighlight = title.highlight.every((h,i) => {
            if (h.path === path) { //if it matches, bail from every() loop
                return false; //this means we have a match and should continue to highlight
            } 
            return true; //this means no highlight found, so we are not going not process the highlight
        })

        if (dontHighlight) {
            return {__html: title[path]};
        }

        //not entirely sure how i got this working..
        title.highlight.forEach(highlight => {
            let texts = highlight.texts;

            let replacements = texts.map(text => {
                if (text.type === "hit") {
                    return "<mark>" + text.value + "</mark>";
                } else {
                    return text.value;
                }
            }).join("");

            let originals = texts.map(text => {
                return text.value;
            }).join("");

            //console.log("title[path]:" + title[path]);

            if (title[path]) title[path] = title[path].replace(originals, replacements);

        });

        return {__html: title[path]};
    }

    
    return (
        <div className="simpleFade searchItem">
            <div className="searchItemHeader">

                <div className={`searchItemTitle${generateIconClass('subMedium', title.subMedium)}`}>
                    <span className="searchItemLink">
                        <Link to={`/title/${title.id}`} dangerouslySetInnerHTML={highlightText('title')}></Link>
                        <Year title={title} />
                    </span>
                </div>

                {!window.matchMedia("(max-width: 630px)").matches &&
                    <div className="listButton" aria-live="polite">
                        <ListButton isInList={isInList} title={title} onListClick={onListClick}/>
                    </div>
                }

            </div>
            
            { title.name && title.name !== 'TITLE VERSION' &&
                <div className="versionName" to={`/title/${title.id}`} dangerouslySetInnerHTML={highlightText('name')}></div>
            }

            <div className="searchItemBody">
                <div className="left">
                    <Credit title={title}/>

                    { title.summary && 
                        <div className="searchItemText">
                            <span className="searchItemSummary" dangerouslySetInnerHTML={highlightText('summary')}></span>
                            &nbsp;<Link className="viewMore" to={`/title/${title.id}`}>View more</Link>
                        </div> 
                    }

                    <div className="searchItemDetails">
                        <ul>
                            <li>
                                NFSA ID:&nbsp;
                                <span>{title.id}</span>
                            </li> 
                            {/* { title.subMedium && 
                                <li>
                                    Medium:&nbsp;
                                    <Link rel="nofollow" onClick={() => sendFacetGoogle("subMedium", title.subMedium)} to={`/search/subMedium=${wrapFacetValueCommas(title.subMedium)}`}>{ title.subMedium }</Link>
                                </li> 
                            } */}
                    
                            { title.forms && title.forms.length > 0 && 
                                <li>
                                    Form:
                                    { title.forms.map((e, i) => {
                                            return (
                                                <span key={i}>&nbsp;
                                                    <Link rel="nofollow" onClick={() => sendFacetGoogle("forms", e)} to={`/search/forms=${wrapFacetValueCommas(e)}`}>{e}</Link>
                                                </span>
                                            )
                                        }) 
                                    }
                                </li>
                            }

                            { title.parentTitle.genres && title.parentTitle.genres.length > 0 && 
                                <li>
                                    Genre:
                                    { title.parentTitle.genres.map((e, i) => {
                                        return (
                                            <span key={i}>&nbsp;
                                                <Link rel="nofollow" onClick={() => sendFacetGoogle("genres", e)} to={`/search/parentTitle.genres=${e}`}>{e}</Link>
                                            </span>
                                        )
                                    }) 
                                    }
                                </li>
                            }
                        </ul>
                    </div>

                </div>
                { title.hasMedia === 'yes' && 
                    <div className="right">
                        
                        {(title.preview[0].type === 'video' || title.preview[0].type === 'image') &&
                         
                            <Link to={`/title/${title.id}`} className={`preview ${title.preview[0].type}`} style={{backgroundImage: "url(https://media.nfsacollection.net/" + title.preview[0].thumbnailFilePath + ")"}}>
                            </Link>
                        }
                        {title.preview[0].type === 'audio' &&
                            <Link to={`/title/${title.id}`} className={`preview ${title.preview[0].type}`}>
                            </Link>
                        }
                        {title.preview[0].type === 'file' &&
                            <Link to={`/title/${title.id}`} className={`preview ${title.preview[0].type}`}>
                            </Link>
                        }
                        { title.preview.length > 0 && 
                            <span className="previewAmount">
                                {title.preview.length} <span>x</span> {title.preview[0].type} preview{title.preview.length > 1 && <>s</>}
                            </span>
                        }
                        
                    </div>
                }
            </div>

            {window.matchMedia("(max-width: 630px)").matches &&
                <ListButton aria-live="polite" isInList={isInList} title={title} onListClick={onListClick}/>
            }

        </div>
        // <>
        // <div className={`simpleFade searchItem ${ isInList ? 'listed' : ''}`}>
            
        //     <div className="searchItemHeader">
        //             <div className="searchItemTitle">
        //                 <Link className="searchItemLink" to={`/title/${title.id}`} dangerouslySetInnerHTML={highlightText('title')}></Link>
                        
        //                 <Year title={title} />
        //                 { title.name && title.name !== 'TITLE VERSION' &&
        //                     <span className="versionName" to={`/title/${title.id}`} dangerouslySetInnerHTML={highlightText('name')}></span>
        //                 }
                       
        //             </div>
                    
        //             <div className="listButton">
        //                 { isInList ? 
        //                     <button className="addListButton listed small" onClick={() => onListClick(title.id, title.title.replaceAll("<mark>", "").replaceAll("</mark>", ""))}>
        //                         <span>Remove</span>
        //                     </button>
        //                     : 
        //                     <button className="addListButton small" onClick={() => onListClick(title.id, title.title.replaceAll("<mark>", "").replaceAll("</mark>", ""))}>
        //                         <span>Add</span>
        //                     </button>
        //                 } 
        //             </div>
                
        //     </div>
        //     <Credit title={title}/>
        //     { title.summary && 
        //         <div className="searchItemText">
        //             <span className="searchItemSummary" dangerouslySetInnerHTML={highlightText('summary')}></span>
        //             <br /><Link className="viewMore" to={`/title/${title.id}`}>View more</Link>
        //         </div> 
        //     }
            
        //     <div className="searchItemDetails">
        //         <ul>
        //             { title.subMedium && 
        //                 <li>
        //                     Medium:&nbsp;
        //                     <Link rel="nofollow" onClick={() => sendFacetGoogle("subMedium", title.subMedium)} to={`/search/subMedium=${wrapFacetValueCommas(title.subMedium)}`}>{ title.subMedium }</Link>
        //                 </li> 
        //             }
                    
        //             { title.forms && title.forms.length > 0 && 
        //                 <li>
        //                     Form:
        //                     { title.forms.map((e, i) => {
        //                         return (
        //                             <span key={i}>&nbsp;
        //                                 <Link rel="nofollow" onClick={() => sendFacetGoogle("forms", e)} to={`/search/forms=${wrapFacetValueCommas(e)}`}>{e}</Link>
        //                             </span>
        //                         )
        //                     }) 
        //                     }
        //                 </li>
        //             }

        //             { title.parentTitle.genres && title.parentTitle.genres.length > 0 && 
        //                 <li>
        //                     Genre:
        //                     { title.parentTitle.genres.map((e, i) => {
        //                         return (
        //                             <span key={i}>&nbsp;
        //                                 <Link rel="nofollow" onClick={() => sendFacetGoogle("genres", e)} to={`/search/parentTitle.genres=${e}`}>{e}</Link>
        //                             </span>
        //                         )
        //                     }) 
        //                     }
        //                 </li>
        //             }
                    
        //         </ul>

                
        //     </div>

        // </div>

        // </>
    );

}