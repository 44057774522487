import { configureStore } from "@reduxjs/toolkit";
import listReducer from "./listSlice";
import searchReducer from "./searchSlice";
import fieldDropReducer from "./fieldDropSlice";
import loaderReducer from "./loaderSlice";
import popMessageReducer from "./popMessageSlice";
import recaptchaReducer from "./recaptchaSlice";
import skipReducer from "./skipSlice";
import filterMenuReducer from "./filterMenuSlice";
import sortDropSlice from "./sortDropSlice";

export default configureStore({
    reducer: {
        list: listReducer,
        search: searchReducer,
        loader: loaderReducer,
        popmessage: popMessageReducer,
        fieldDrop: fieldDropReducer,
        sortDrop: sortDropSlice,
        recaptcha: recaptchaReducer,
        skip: skipReducer,
        filterMenu: filterMenuReducer
        //list more reducers here..
    }
});