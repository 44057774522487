import React from "react";
import "../../../assets/css/HoldingTabs.css"

export default function HoldingTabs({expandedContent, tabSelected, expanded, holding, onExpandClick, onTabResize}) {

    const [currentTab, setCurrentTab] = React.useState("tab1"); //explicit text instead of toggle so easier to debug
    const [tabsRendered, setTabsRendered] = React.useState(false); //bool to stop useEffect running on first render

    React.useEffect(() => { //we need useEffect because we need to wait for the component to render before sending to parent and measuring height
        if (tabsRendered) { //stop render on first run
            onTabResize(currentTab);
        } else {
            setTabsRendered(true);
        }
    }, [currentTab, onTabResize, tabsRendered]);

    let filteredDigitalTechInfo = digitalTechInfo(holding);
    let filteredAnalogTechInfo = analogTechInfo(holding);

    React.useEffect(() => {
        setCurrentTab(tabSelected);
    }, [tabSelected])

    function onTabClick(tabClicked) {

        if (tabClicked === currentTab) return;
        
        if (currentTab === "tab1") {
            setCurrentTab("tab2");
        } else {
            setCurrentTab("tab1");
        }
    }

    function renderHoldingFileType() {
        if (holding.fileType && holding.fileExtension) {
            return <span className="holdingFileExt">{holding.fileType}/{holding.fileExtension}</span>
        } else {
            return <span className="holdingFileExt">{holding.itemType &&  holding.itemType}</span>
        }
    }

    function renderHoldingMedia() {
        if (holding.mediaFormatName && holding.mediaFormatName) {
            return <span className="holdingFileFormat">{ holding.mediaFormatName && holding.mediaFormatName }</span>
        } else {
            return <span className="holdingFileFormat">{ holding.mediaType && holding.mediaType }</span>
        }
    }

    return (

        <>
            <div className="tabHeader">
                <div className="tabHeaderId">
                    NFSA ID - {holding.id.replace(/_/g, "-")}
                </div>
                <button aria-label="Click to expand" className={`expandButton${expanded ? ' expanded' : ''}`} onClick={() => onExpandClick()}>
                    {renderHoldingFileType()}
                    {renderHoldingMedia()}
                    <span className="arrow"></span>
                </button>
                
            </div>
            
            <div ref={expandedContent} className={`holdingTabs ${expanded ? 'expanded' : ''}`} aria-hidden={!expanded} tabIndex={expanded ? '' : -1}>

                <div className="tabControl">
                    <button className={`${(currentTab === "tab1") ? 'selected' : ''}`} onClick={() => onTabClick("tab1")}>Holding <span>Details</span></button>
                    <button className={`${(currentTab === "tab2") ? 'selected' : ''}`} onClick={() => onTabClick("tab2")}>Technology <span>Information</span></button>
                </div>
                
                { (currentTab === "tab1") && 
                    <div className="tab">
                        { holding.id && 
                            <div className="tabLine">
                                <span className="tabLineTitle">Holding Number</span>
                                <span className="tabLineValue">{holding.id.replace("_", "-")}</span>
                            </div>
                        }

                        { holding.recordLabel && 
                            <div className="tabLineMult">
                                <div className="tabLineListTitle">Record Label</div>
                                <div className="tabInnerLine">
                                    <div className="tabLine">
                                        <span className="tabLineTitle">Company</span>
                                        <span className="tabLineValue">{holding.recordLabel.name }</span>
                                    </div>
                                    <div className="tabLine">
                                        <span className="tabLineTitle">Catalogue Number</span>
                                        <span className="tabLineValue">{holding.recordLabel.catalogueNumber }</span>
                                    </div>
                                </div>
                            </div>
                        }
                        
                        { holding.itemType && 
                            <div className="tabLine">
                                <span className="tabLineTitle">Item Type</span>
                                <span className="tabLineValue">{holding.itemType}</span>
                            </div>
                        }

                        { holding.mediaType && 
                            <div className="tabLine">
                                <span className="tabLineTitle">Media Type</span>
                                <span className="tabLineValue">{holding.mediaType}</span>
                            </div>
                        }

                        { holding.itemUsage && 
                            <div className="tabLine">
                                <span className="tabLineTitle">Item Usage</span>
                                <span className="tabLineValue">{holding.itemUsage}</span>
                            </div>
                        }

                        { holding.mediaFormatName &&
                            <div className="tabLine">
                                <span className="tabLineTitle">Format</span>
                                <span className="tabLineValue">{holding.mediaFormatName}</span>
                            </div>
                        }

                        { holding.fileType &&
                            <div className="tabLine">
                                <span className="tabLineTitle">File Type</span>
                                <span className="tabLineValue">{holding.fileType}</span>
                            </div>
                        }

                        { holding.fileSizeBytes &&
                            <div className="tabLine">
                                <span className="tabLineTitle">File Size</span>
                                <span className="tabLineValue">{(holding.fileSizeBytes / (1024*1024)).toFixed(2) } MiB</span> {/* 1 MB actually equals 1000 KB, while 1 MiB = 1024 KiB */}
                            </div>
                        }

                        { holding.md5 &&
                            <div className="tabLine md">
                                <span className="tabLineTitle">MD5</span>
                                <span className="tabLineValue">{holding.md5}</span>
                            </div>
                        }

                        { holding.restrictionStatus &&
                            <div className="tabLine">
                                <span className="tabLineTitle">Status</span>
                                <span className="tabLineValue">{holding.restrictionStatus}</span>
                            </div>
                        }

                        
                    </div>
                }

                { (currentTab === "tab2") && 
                    <div className="tab">
                        
                        {/* Object.keys(holding.digitalTechInfo).length < 1 && Object.keys(holding.analogueTechInfo).length < 1 &&
                            <div className="tabLine">No technical information provided</div>
                        */}

                        { filteredAnalogTechInfo.length < 1 && filteredDigitalTechInfo.length < 1 &&
                            <div className="tabLine">No technical information provided</div>
                        }
                    
                        { holding.digitalTechInfo && Object.keys(holding.digitalTechInfo).length > 0 &&
                            //digitalTechInfo(holding).map((item,i) => {
                            filteredDigitalTechInfo.map((item,i) => {
                                return (
                                    <React.Fragment key={i}>
                                        <div key={i} className="tabLine">
                                            <span className="tabLineTitle">{item.title}</span>
                                            <span className="tabLineValue">{item.value}</span>
                                        </div>
                                    </React.Fragment>
                                )
                            })
                        }

                        { holding.analogueTechInfo && Object.keys(holding.analogueTechInfo).length > 0 &&
                            
                            //analogTechInfo(holding).map((item,i) => {
                            filteredAnalogTechInfo.map((item,i) => {
                                return (
                                    <React.Fragment key={i}>
                                     {/*{checkBadTechData(item) &&*/}
                                        <div key={i} className="tabLine">
                                            <span className="tabLineTitle">{item.title}</span>
                                            <span className="tabLineValue">{item.value}</span>
                                        </div>
                                   {/*} */}
                                    </React.Fragment>
                                )
                            })
                        }
                    </div>
                }


            </div>

        </>

    )
}


const digitalTechInfo = (holding) => {
    //deconstruct the array into single column of title:value(s)
    let cleanedDigitalTechInfo = [];
    
    //holding.digitalTechInfo.audio?.Audio?.Duration?.Minutes
    if (validateTechField(holding.digitalTechInfo.audio?.Audio?.Duration?.Minutes)) { 
        cleanedDigitalTechInfo.push({'title': 'Minutes', 'value': holding.digitalTechInfo.audio.Audio.Duration?.Minutes})
    }

    // holding.digitalTechInfo.audio?.Audio?.Duration?.Seconds
    if (validateTechField(holding.digitalTechInfo.audio?.Audio?.Duration?.Seconds)) { 
        cleanedDigitalTechInfo.push({'title': 'Seconds', 'value': holding.digitalTechInfo.audio.Audio.Duration.Seconds})
    }

    // holding.digitalTechInfo.audio?.Audio?.Duration
    if (validateTechField(holding.digitalTechInfo.audio?.Audio?.Duration)) { 
        cleanedDigitalTechInfo.push({'title': 'Duration', 'value': holding.digitalTechInfo.audio.Audio.Duration})
    }

    // holding.digitalTechInfo.audio?.Audio?.FileFormat
    if (validateTechField(holding.digitalTechInfo.audio?.Audio?.FileFormat)) { 
        cleanedDigitalTechInfo.push({'title': 'File Format', 'value': holding.digitalTechInfo.audio?.Audio.FileFormat})
    }
    // holding.digitalTechInfo.audio?.Audio?.FileSize
    if (validateTechField(holding.digitalTechInfo.audio?.Audio?.FileSize)) { 
        cleanedDigitalTechInfo.push({'title': 'File Size', 'value': holding.digitalTechInfo.audio.Audio.FileSize})
    }

    // holding.digitalTechInfo.exif?.RDF?.Description?.File?.FileType
    if (validateTechField(holding.digitalTechInfo.exif?.RDF?.Description?.File?.FileType)) { 
        cleanedDigitalTechInfo.push({'title': 'File Type', 'value': holding.digitalTechInfo.exif.RDF.Description.File.FileType})
    }

    // holding.digitalTechInfo.exif?.RDF?.Description?.File?.ImageHeight
    if (validateTechField(holding.digitalTechInfo.exif?.RDF?.Description?.File?.ImageHeight)) { 
        cleanedDigitalTechInfo.push({'title': 'Image Height', 'value': holding.digitalTechInfo.exif.RDF.Description.File.ImageHeight})
    }

    // holding.digitalTechInfo.exif?.RDF?.Description?.File?.ImageWidth
    if (validateTechField(holding.digitalTechInfo.exif?.RDF?.Description?.File?.ImageWidth)) { 
        cleanedDigitalTechInfo.push({'title': 'Image Width', 'value': holding.digitalTechInfo.exif.RDF.Description.File.ImageWidth})
    }

    // holding.digitalTechInfo.exif?.RDF?.Description?.IFD0?.ImageHeight
    if (validateTechField(holding.digitalTechInfo.exif?.RDF?.Description?.IFD0?.ImageHeight)) { 
        cleanedDigitalTechInfo.push({'title': 'Image Height', 'value': holding.digitalTechInfo.exif.RDF.Description.IFD0.ImageHeight})
    }

    // holding.digitalTechInfo.exif?.RDF?.Description?.IFD0?.ImageWidth
    if (validateTechField(holding.digitalTechInfo.exif?.RDF?.Description?.IFD0?.ImageWidth)) { 
        cleanedDigitalTechInfo.push({'title': 'Image Width', 'value': holding.digitalTechInfo.exif.RDF.Description.IFD0.ImageWidth})
    }

    // holding.digitalTechInfo.mediainfo?.MediaInfo?.Audio?.Format
    if (validateTechField(holding.digitalTechInfo.mediainfo?.MediaInfo?.Audio?.Format)) { 
        cleanedDigitalTechInfo.push({'title': 'Format', 'value': holding.digitalTechInfo.mediainfo.MediaInfo.Audio.Format})
    }

    // holding.digitalTechInfo.mediainfo?.MediaInfo?.Audio?.SamplingRate
    if (validateTechField(holding.digitalTechInfo.mediainfo?.MediaInfo?.Audio?.SamplingRate)) { 
        cleanedDigitalTechInfo.push({'title': 'Sampling Rate', 'value': holding.digitalTechInfo.mediainfo.MediaInfo.Audio.SamplingRate})
    }

    // holding.digitalTechInfo.mediainfo?.MediaInfo?.Video?.BitRate_Mode
    if (validateTechField(holding.digitalTechInfo.mediainfo?.MediaInfo?.Video?.BitRate_Mode)) { 
        cleanedDigitalTechInfo.push({'title': 'BitRate Mode', 'value': holding.digitalTechInfo.mediainfo.MediaInfo.Video.BitRate_Mode})
    }

    // holding.digitalTechInfo.mediainfo?.MediaInfo?.Video?.DisplayAspectRatio
    if (validateTechField(holding.digitalTechInfo.mediainfo?.MediaInfo?.Video?.DisplayAspectRatio)) { 
        cleanedDigitalTechInfo.push({'title': 'Display Aspect Ratio', 'value': holding.digitalTechInfo.mediainfo.MediaInfo.Video.DisplayAspectRatio})
    }

    // holding.digitalTechInfo.mediainfo?.MediaInfo?.Video?.Format
    if (validateTechField(holding.digitalTechInfo.mediainfo?.MediaInfo?.Video?.Format)) { 
        cleanedDigitalTechInfo.push({'title': 'Format', 'value': holding.digitalTechInfo.mediainfo.MediaInfo.Video.Format})
    }
    
    return cleanedDigitalTechInfo;
}

const analogTechInfo = (holding) => {

    let cleanedAnalogTechInfo = [];

    if (validateTechField(holding.analogueTechInfo.diskAudio?.DiscAudio?.Duration?.Minutes)) {
        cleanedAnalogTechInfo.push({'title': 'Minutes', 'value': holding.analogueTechInfo.diskAudio.DiscAudio.Duration.Minutes})
    }

    if (validateTechField(holding.analogueTechInfo.diskAudio?.DiscAudio?.Duration?.Seconds)) {
        cleanedAnalogTechInfo.push({'title': 'Seconds', 'value': holding.analogueTechInfo.diskAudio.DiscAudio.Duration.Seconds})
    }

    if (validateTechField(holding.analogueTechInfo.diskAudio?.DiscAudio?.Duration)) {
        cleanedAnalogTechInfo.push({'title': 'Duration', 'value': holding.analogueTechInfo.diskAudio.DiscAudio.Duration})
    }

    if (validateTechField(holding.analogueTechInfo.film?.Film?.Duration?.Minutes)) {
        cleanedAnalogTechInfo.push({'title': 'Minutes', 'value': holding.analogueTechInfo.film.Film.Duration.Minutes})
    }

    if (validateTechField(holding.analogueTechInfo.film?.Film?.Duration?.Seconds)) {
        cleanedAnalogTechInfo.push({'title': 'Seconds', 'value': holding.analogueTechInfo.film.Film.Duration.Seconds})
    }

    if (validateTechField(holding.analogueTechInfo.film?.Film?.Duration)) {
        cleanedAnalogTechInfo.push({'title': 'Duration', 'value': holding.analogueTechInfo.film.Film.Duration})
    }

    if (validateTechField(holding.analogueTechInfo.opticalDiskSoftware?.OpticalDiscSoftware?.Duration?.Minutes)) {
        cleanedAnalogTechInfo.push({'title': 'Minutes', 'value': holding.analogueTechInfo.opticalDiskSoftware.OpticalDiscSoftware.Duration.Minutes})
    }

    if (validateTechField(holding.analogueTechInfo.opticalDiskSoftware?.OpticalDiscSoftware?.Duration?.Seconds)) {
        cleanedAnalogTechInfo.push({'title': 'Seconds', 'value': holding.analogueTechInfo.opticalDiskSoftware.OpticalDiscSoftware.Duration.Seconds})
    }

    if (validateTechField(holding.analogueTechInfo.opticalDiskSoftware?.OpticalDiscSoftware?.Duration)) {
        cleanedAnalogTechInfo.push({'title': 'Seconds', 'value': holding.analogueTechInfo.opticalDiskSoftware.OpticalDiscSoftware.Duration})
    }

    if (validateTechField(holding.analogueTechInfo.paper?.Paper?.Dimensions)) {
        cleanedAnalogTechInfo.push({'title': 'Dimensions', 'value': holding.analogueTechInfo.paper.Paper.Dimensions})
    }

    if (validateTechField(holding.analogueTechInfo.paper?.Paper?.Orientation)) {
        cleanedAnalogTechInfo.push({'title': 'Orientation', 'value': holding.analogueTechInfo.paper.Paper.Orientation})
    }

    if (validateTechField(holding.analogueTechInfo.paper?.Paper?.Pages)) {
        cleanedAnalogTechInfo.push({'title': 'Pages', 'value': holding.analogueTechInfo.paper.Paper.Pages})
    }

    if (validateTechField(holding.analogueTechInfo.paper?.Paper?.Size)) {
        cleanedAnalogTechInfo.push({'title': 'Size', 'value': holding.analogueTechInfo.paper.Paper.Size})
    }

    if (validateTechField(holding.analogueTechInfo.paper?.Paper)) {
        cleanedAnalogTechInfo.push({'title': 'Paper', 'value': holding.analogueTechInfo.paper.Paper})
    }

    if (validateTechField(holding.analogueTechInfo.photograph?.Photograph?.Dimensions)) {
        cleanedAnalogTechInfo.push({'title': 'Dimensions', 'value': holding.analogueTechInfo.photograph.Photograph.Dimensions})
    }

    if (validateTechField(holding.analogueTechInfo.photograph?.Photograph?.Orientation)) {
        cleanedAnalogTechInfo.push({'title': 'Orientation', 'value': holding.analogueTechInfo.photograph.Photograph.Orientation})
    }

    if (validateTechField(holding.analogueTechInfo.photograph?.Photograph?.Size)) {
        cleanedAnalogTechInfo.push({'title': 'Size', 'value': holding.analogueTechInfo.photograph.Photograph.Size})
    }

    if (validateTechField(holding.analogueTechInfo.tapeAudio?.TapeAudio?.Duration)) {
        cleanedAnalogTechInfo.push({'title': 'Duration', 'value': holding.analogueTechInfo.tapeAudio.TapeAudio.Duration})
    }

    if (validateTechField(holding.analogueTechInfo.tapeAudio?.TapeAudio?.Speed)) {
        cleanedAnalogTechInfo.push({'title': 'Speed', 'value': holding.analogueTechInfo.tapeAudio.TapeAudio.Speed})
    }

    if (validateTechField(holding.analogueTechInfo.tapeMovingImage?.TapeMovingImage?.AspectRatio)) {
        cleanedAnalogTechInfo.push({'title': 'Aspect Ratio', 'value': holding.analogueTechInfo.tapeMovingImage.TapeMovingImage.AspectRatio})
    }

    if (validateTechField(holding.analogueTechInfo.tapeMovingImage?.TapeMovingImage?.Duration)) {
        cleanedAnalogTechInfo.push({'title': 'Duration', 'value': holding.analogueTechInfo.tapeMovingImage.TapeMovingImage.Duration})
    }

    return cleanedAnalogTechInfo;
}

const validateTechField = (field) => {
    if (typeof field !== "undefined" 
            && field !== ""
            && typeof field !== "object"
            && field !== "0"
        ) {
        return field;
    } else {
        return false;
    }
}

// const digitalTechInfo = (holding) => {
//     //deconstruct the array into single column of title:value(s)
//     let techInfo = [];
//     getTechnicalInfo(holding.digitalTechInfo, techInfo);

//     //console.log(techInfo.length);

//     const cleanedTechInfo = techInfo.filter((item) => {
//         //if (checkBadTechData(i)) return i;
//         return checkBadDigitalTechData(item);
//     });


//     return cleanedTechInfo;
// }


//recursive function that builds a flat array of objects from the multi (nth)level array of JSON data from holdings. 
// const getTechnicalInfo = (obj, techInfo) => {
//     Object.keys(obj).forEach(key => {
//         if (obj[key] && typeof obj[key] === "object") {
//             getTechnicalInfo(obj[key], techInfo); // recurse.
//         } else {
//             //console.log(key, obj[key]);
//             let item = {'title': key, 'value': obj[key]}
//             techInfo.push(item);
//         }
//     });
// };







