

//this function also ADDs if it cant find the value
export default function replaceQueryValue(sText, queryParam, replaceValue) {
    //console.log("in getPath sText is : " + sText);
    
    var vars = sText.split('&');
    for (var i = 0; i < vars.length; i++) { //this possibly wont work for multiple same queryParams in string
        var pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) === queryParam) {
            //console.log("fullvar: " + vars[i]);
            
            let newQuery = sText.replace(vars[i], queryParam + "=" + replaceValue);
            //console.log("old query is: " + sText);
            //console.log("new query is now: " + newQuery);
            return newQuery;
            //console.log("found: " + pair[0] + "=" + pair[1]);
            //return decodeURIComponent(pair[1]);
        }
    }

    //if we get here it has not been found so just add it to the end
    return sText + "&" + queryParam + "=" + replaceValue;

}