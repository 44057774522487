import isFacetValueQuoted from "./isFacetValueQuoted";
import cleanFacetValue from "./cleanFacetValue";
/*
    Checks if multiple values are assigned to a facet with || or &&'s
*/
export default function checkMultiFacetInUse(sText, queryParam, queryValue) {
    //console.log("checkMultiFacetInUse sText: " + sText + " queryValue " + queryValue);
    
    var vars = sText.split('&');
    //console.log("split vars: ");
    //console.log(JSON.stringify(vars));
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        let queryValues = [];

        if (pair[0] === 'query') continue; //dont process the search string

        let decodedValue = decodeURIComponent(pair[1]);

        //console.log("pair[1]=" + pair[1] + " decoded: " +decodeURIComponent(pair[1]));

        let decodedQueryValue = decodeURIComponent(queryValue);

         //check if value has the OR or AND chars and split those
        //if (decodeURIComponent(pair[1]).includes("||")) {
        if (decodedValue.includes("|")) {
             //queryValues = decodeURIComponent(pair[1]).split("||");
             //console.log("Contains an OR")
             queryValues = decodedValue.split("|");
             //console.log("splits values: ");
             //console.log(queryValues);
             for (var j = 0; j < queryValues.length; j++) {
                 //console.log("compare: " + pair[0] + "===" + queryParam + " && " + cleanFacetValue(queryValues[j]) + "===" + cleanFacetValue(decodedQueryValue));
                 if (pair[0] === queryParam && cleanFacetValue(queryValues[j]) === cleanFacetValue(decodedQueryValue)) { //always clean the potentially quoted facet value
                    //console.log("returning true for: " + queryParam);
                    return true;
                 }
             }
        } else if (!isFacetValueQuoted(decodedValue) && decodedValue.includes(",")) {
            //console.log(decodedValue + " includes a comma");
            queryValues = decodedValue.split(","); //dont split if we have quotes
            for (var k = 0; k < queryValues.length; k++) {
                //console.log("checking: " + queryValues[k] + " against " + queryValue);
                if (pair[0] === queryParam && queryValues[k] === decodedQueryValue) {
                    return true;
                }
            }
        }

         
    }
    //console.log("returning false for " + queryValue);
    return false;
 }