
import "../assets/css/Footer.css";
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import nfsaLogo from '../assets/images/nfsa_logo_cropped.svg';
import { useLocation } from 'react-router-dom';
export default function Footer() {

    let location = useLocation();

    const isLoading = useSelector((state) => {
        return state.loader.isLoading;
    });

    // do not show on home page
    if (location.pathname === "/") return null;

    if (!isLoading) return (
        <footer>
            <div className="wrapper">
                <div className="footer-inner">
                    
                <div className="col1">
                        <a className="footer-logo" href="https://www.nfsa.gov.au/">
                            <img src={nfsaLogo} alt="National Film and Sound Archive Logo" />
                        </a>
                        <div className="nav-block">

                            <nav className="footer-menu1">
                                <a href="https://www.nfsa.gov.au/collection">Collection</a><br />
                                <a href="https://www.nfsa.gov.au/latest">Stories</a><br />
                                <a href="https://www.nfsa.gov.au/events">Events</a><br />
                                <a href="https://www.nfsa.gov.au/our-work">Our work</a><br />
                                <a href="https://www.nfsa.gov.au/about">About</a>
                            </nav>

                            <nav className="footer-menu2">
                                <a href="https://www.nfsa.gov.au/web-accesibility">Web accessibility</a><br />
                                <a href="https://www.nfsa.gov.au/privacy">Privacy policy</a><br />
                                <a href="https://www.nfsa.gov.au/contact-us">Contact us</a><br />
                                <a href="https://www.nfsa.gov.au/feedback-form">Feedback</a>
                                <Link className="hidden-link" to="/all-titles">All Titles</Link>
                            </nav>
                        </div>
                    </div>
                    <div className="col2">
                        <div className="disclaimer-block">
                            <div className="footer-title">Disclaimer</div>
                            <div className="disclaimer-text">
                                Titles and related text are often exact transcriptions from original items and reflect attitudes of the time.
                                 Some text may also contain inappropriate or offensive language. This collection contains descriptions, 
                                 images and audio recordings from audiovisual material relating to Indigenous Australians. Users 
                                 should be aware that, in some Aboriginal and Torres Strait Islander communities, seeing the names 
                                 and/or images of deceased persons may cause sadness or distress, particularly to the relatives of 
                                 these people.
                            </div>
                        </div>

                        <div className="contact-block">
                            <div className="footer-title">Contact</div>
                            <a className="contact-link" rel="noreferrer" href="/request-form/">
                                <span>Access enquiry</span>
                            </a>
                        </div>
                    </div>
                    
                </div>
            </div>
        </footer>
    );
}