import SeoHeader from "./SeoHeader";
import useClearTabIndex from "../hooks/useClearTabIndex";

import imgKeywordSearchBar1 from '../assets/images/help-keyword-search-bar1.png';
import imgKeywordSearchBar2 from '../assets/images/help-keyword-search-bar2.png';
import imgMediumFilters from '../assets/images/help-medium-filters.png';
import imgSearchResultsCount from '../assets/images/help-search-results-count.png';
import imgSearchResultsSearchBarTop from '../assets/images/help-search-results-search-bar-top.png';
import imgSearchResultsItems from '../assets/images/help-search-results-items.png';
import imgPagination1 from '../assets/images/help-pagination1.png';
import imgSearchBarCross from '../assets/images/help-search-bar-cross.png';
import imgSearchFields1 from '../assets/images/help-search-fields1.png';
import imgSearchFields2 from '../assets/images/help-search-fields2.png';
import imgSearchBarAdvanced from '../assets/images/help-search-bar-advanced.png';
import imgSearchBarExclude from '../assets/images/help-search-bar-exclude.png';
import imgSearchBarExact from '../assets/images/help-search-bar-exact.png';
import imgSearchBarAlsoExact from '../assets/images/help-search-bar-also-exact.png';
import imgSearchFieldsTitle from '../assets/images/help-search-fields-title.png';
import imgSearchBarOR from '../assets/images/help-search-bar-OR.png';
import imgSearchORresults from '../assets/images/help-search-OR-results.png';
import imgSearchBarOR2 from '../assets/images/help-search-bar-OR2.png';
import imgFacetList1 from '../assets/images/help-facet-list1.png';
import imgMultipleFacets from '../assets/images/help-multiple-facets.png';
import imgMadMaxPage from '../assets/images/help-mad-max-page.png';
import imgListNav from '../assets/images/help-list-nav.png';
import imgSearchResultsAdd from '../assets/images/help-search-results-add.png';
import imgTitlePageAdd from '../assets/images/help-title-page-add.png';
import imgHeaderList from '../assets/images/help-header-list.png';
//import imgListNavSelected from '../assets/images/help-list-nav-selected.png';
import imgListPage1 from '../assets/images/help-list-page1.png';
import imgListPage2 from '../assets/images/help-list-page2.png';
import imgAccessService1 from '../assets/images/help-access-service1.png';








export default function Help() {
    useClearTabIndex();
    return (
        <>
        <SeoHeader title={"Help"} metadesc={"National Film and Sound Archive, Search the Collection, Help page"} />
        <div className="help wrapper simpleFade">
            <div className="content cms" id="contentStart">

                <h2>Help</h2>

                <ul>
                    <li>
                        <a href="#Keyword-Search">Keyword Search</a>
                        <ul>
                            <li>
                                <a href="#Search-tips">Search tips</a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a href="#Limiting-keyword-search-using-medium-filters">Limiting keyword search using 'medium' filters</a>
                    </li>
                    <li>
                        <a href="#Search-results ">Search results </a>
                    </li>
                    <li>
                        <a href="#Refining-or-narrowing-search-results">Refining or narrowing search results</a>
                        <ul>
                            <li>
                                <a href="#Clearing-keywords-and-starting-a-new-search">Clearing keywords and starting a new search</a>
                            </li>
                            <li>
                                <a href="#Applying-field-search-filters">Applying field search filters</a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a href="#Advanced-searching">Advanced searching</a>
                        <ul>
                            <li>
                                <a href="#Excluding-specific-keywords">Excluding specific keywords</a>
                            </li>
                            <li>
                                <a href="#Exact-matching-to-keywords">Exact matching to keywords</a>
                            </li>
                            <li>
                                <a href="#Further-refining-advanced-search-with-All-fields-filter-options">Further refining advanced search with 'All' fields filter options and exact match</a>
                            </li>
                            <li>
                                <a href="#Using-an-OR-in-searching-keywords">Using an 'OR' in searching keywords</a>
                            </li>
                            <li>
                                <a href="#Refining-search-results-using-filter-categories-or-facets">Refining search results using filter categories or facets</a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a href="#Viewing-an-individual-title">Viewing an individual title</a>
                    </li>
                    <li>
                        <a href="#Adding-titles-to-a-list">Adding titles to a list</a>
                    </li>
                    <li>
                        <a href="#Adding-titles-to-an-access-request-form">Adding titles to an access request form</a>
                    </li>
                    <li>
                        <a href="#More-searching-hints-and-tips">More searching hints and tips</a>
                    </li>
                    <li>
                        <a href="#For-more-information">For more information</a>
                    </li>
                    <li>
                        <a href="#Glossary-of-terms">Glossary of terms</a>
                    </li>
                </ul>


                <h3 id="Keyword-Search">Keyword Search</h3>

                <p>On the home screen, you can start your <strong>keyword search</strong>.</p>

                <img className="helpImg imgKeywordSearchBar1" src={imgKeywordSearchBar1} alt="Keyword search bar"/>

                <p>For the best results, simply enter words into the <strong>search box</strong> which best describe what you are looking for. A search query can consist of a single keyword or multiple keywords. 
                    Start with broad descriptive words, review the search results, and then refine your search using one or more filters.</p>

                <img className="helpImg imgKeywordSearchBar2" src={imgKeywordSearchBar2} alt="Keyword search bar"/>

                <p>The <strong>search button</strong> (magnifying glass icon) activates your search and will retrieve titles based on relevancy to 
                your search criteria.</p>

                <p>This field operates as an 'or' search, that is, it searches for each individual word entered into the search 
                    box and looks for these words anywhere in the catalogue record. For example, a search on <em>'kangaroo palace'</em>
                    will bring back results with the words <em>kangaroo</em> or <em>palace</em> anywhere in catalogue record, including the title, 
                    summary, credits, name record or holding details fields.</p>

                <h4 id="Search-tips">Search Tips</h4>

                <p>Search is <strong>case insensitive</strong> - you may use upper- or lower-case characters.</p>

                <p><strong>Non-alphanumeric characters</strong> are NOT currently supported. Entering characters, for example # or $, are ignored by the search.</p>

                <a className="backTop" href="#top">Back to top of page</a>

                <h3 id="Limiting-keyword-search-using-medium-filters">Limiting keyword search using 'medium' filters</h3>

                <p>On the home screen, you can also limit your keyword search by applying 'medium' filters to refine your initial search.</p>

                <img className="helpImg imgMediumFilters" src={imgMediumFilters} alt="Home screen medium filters" />

                <p>You can select one or more 'medium' filters to refine your search from the available options:</p>

                <p>If you select multiple filters, it operates as an 'OR' search, for example, by selecting the 
                filters film and documentation, you will get both film and documentation items relevant to 
                your search.</p>
                
                <a className="backTop" href="#top">Back to top of page</a>

                <h3 id="Search-results">Search results</h3>

                <p><strong>Search results</strong> will be displayed along with a <strong>visual 
                indicator</strong> showing how many results are currently being displayed on the page and the total number of 
                titles matching your search criteria. </p>

                <img className="helpImg small imgSearchResultsCount" src={imgSearchResultsCount} alt="A count of the search results" />
                
                <p>The search criteria used in the keyword search will appear in the search box above the search results.</p>

                <img className="helpImg imgSearchResultsSearchBarTop" src={imgSearchResultsSearchBarTop} alt="Search bar on the search results page" />
                
                <img className="helpImg imgSearchResultsItems" src={imgSearchResultsItems} alt="Search results listed results" />

                <p>Each individual result (title) in the <strong>search results</strong> is displayed in order of 
                    relevance and contains a summary of key information as follows:</p>

                <ul>
                    <li><strong>Title name:</strong> provides more detail about the work or title.</li>
                    <li><strong>Date:</strong> Year associated with the title.</li>
                    <li><strong>Role:</strong> the role and name of the principal credit role for the title, such as director for film or performer for recorded sound.</li>
                    <li><strong>Summary:</strong> a short description of the title highlighted.</li>
                    <li><strong>Medium</strong>, <strong>Form</strong> and <strong>Genre:</strong> Additional classifications describing the title.</li>
                </ul>

                <p>If you scroll to the bottom of the list of search results, you can use the 'page' 
                    numbers to <strong>navigate</strong> through the <strong>pages</strong> of <strong>search results</strong>.</p>

                <img className="helpImg small imgPagination1" src={imgPagination1} alt="Page numbers of search results" />
                
                <p>Use <strong>Previous</strong> or <strong>Next</strong> to move backwards or forwards with the results pages. 
                    You can also click a displayed 'page' number to go to a specific page.</p>
                
                <a className="backTop" href="#top">Back to top of page</a>

                <h3 id="Refining-or-narrowing-search-results">Refining or narrowing search results</h3>

                <p>On the search result page, there are options to <strong>apply further filters</strong> to your search, 
                    and under the <strong>'Advanced'</strong> option to <strong>exclude specific keywords</strong> or 
                    <strong>specify exact words</strong> to 
                    match, to allow you to refine your search.</p>

                <p>You can also choose to clear your current search and enter a new search.</p>

                <h4 id="Clearing-keywords-and-starting-a-new-search">Clearing keywords and starting a new search</h4>

                <p>Click the "X" in the search box and advanced search fields to clear any keywords.</p>

                <img className="helpImg imgSearchBarCross" src={imgSearchBarCross} alt="Search bar with cross circled in red" />

                <p>Type in new keywords and click on the search button (magnifying glass) to activate a new search.</p>
                
                <a className="backTop" href="#top">Back to top of page</a>

                <h3 id="Applying-field-search-filters">Applying field search filters</h3>

                <p>To narrow your search, you can <strong>select one or more values</strong> from the <strong>'ALL FIELDS'</strong> field list of <strong>options</strong>.</p>

                <p>This allows you to <strong>focus your search</strong> within specific data fields that are common across titles.  
                    The search will be <strong>limited to those selected fields</strong> and if the keyword(s) do not exist within those fields, no results will be returned. 
                    It's important to remember that ALL of the keywords have to exist in the selected fields for a result to match.</p>

                <img className="helpImg imgSearchFields1" src={imgSearchFields1} alt="Dropdown of all the search fields available" />
                
                <p>For a broad, full text search, start without selecting any of the 'ALL FIELDS' for search fields, 
                    then review your results and select additional fields from the drop-down 
                    list to narrow your search.</p>

                <p>When one or more values have been selected in the <strong>'ALL FIELDS'</strong> field list of <strong>options</strong>, an 
                asterix(*) will appear in the field. </p>

                <img className="helpImg imgSearchFields2" src={imgSearchFields2} alt="Dropdown of all the search fields with title field checked" />

                <p>Before starting a new search, untick those selections before you search again.</p>

                <a className="backTop" href="#top">Back to top of page</a>

                <h3 id="Advanced-searching">Advanced searching</h3>

                <p>To refine your search and set more specific search conditions, click on the <strong>'ADVANCED'</strong> option.</p>

                <img className="helpImg small imgSearchBarAdvanced" src={imgSearchBarAdvanced} alt="Search bar with advanced highlighted" />

                <h4 id="Excluding-specific-keywords">Excluding specific keywords</h4>

                <p>In addition to the main search keyword(s) in the search box, you can enter other keyword(s) to <strong>exclude</strong> specific words/terms from the search results. </p>

                <img className="helpImg small imgSearchBarExclude" src={imgSearchBarExclude} alt="Search bar with exclude highlighted" />

                <h4 id="Exact-matching-to-keywords">Exact matching to keywords</h4>

                <p>The concept of <strong>exact match searching</strong> can be executed in two ways. </p>

                <p>Firstly, by switching on the 'Only exact matches' checkbox below the main search box. This will bring back 
                    search results that contain the exact string of keywords entered into the search box. 
                    This allows for a narrower search as opposed to if 'Only exact match' is switched off, where 
                    the search results will be returned for any of the keywords entered. </p>

                <p>For example, "mad max" with the 'Only exact matches' switched on will return results where 
                the keywords/phrase "mad max" appears in that specific sequence of words.</p>

                <img className="helpImg imgSearchBarExact" src={imgSearchBarExact} alt="Search bar with exact checkbox highlighted" />

                <p>To remove the 'Only exact matches' filter, before doing another search, uncheck the box.</p>

                <p>An alternate way to refine your search is by entering <strong>additional keywords</strong> into 
                the <strong>'ALSO EXACTLY MATCH TO'</strong> field to refine your search 
                    results to show results from the main keyword search and then narrowed down by the additional keywords.</p>

                <p>For example, in a search for "mad max", and adding the word "thunderdome" in 
                    the <strong>'ALSO EXACTLY MATCH TO'</strong> field, 
                    this will return results which contain the keywords "mad max" <strong>AND</strong> "thunderdome" in any sequence.</p>

                <img className="helpImg imgSearchBarAlsoExact" src={imgSearchBarAlsoExact} alt="Search bar with also exactly match highlighted" />

                <h4 id="Further-refining-advanced-search-with-All-fields-filter-options">
                    Further refining advanced search with 'ALL FIELDS' fields filter options and using 'Only exact matches'
                </h4>

                <p>To narrow your search results even further, you can focus your search within specific data fields in 
                    the collection that are common across titles by using the options in the <strong>'ALL FIELDS' fields drop-down list.</strong></p>

                <img className="helpImg imgSearchFieldsTitle" src={imgSearchFieldsTitle} alt="Search bar with the fields dropdown title highlighted" />

                <p>For example, in a search for 'mad max fury road' with the 'Title' field selected and 'Only exact matches' is switched on, 
                    the search will only return 
                    results where the exact phrase (that is the entire four words "mad max fury road") entered into the search field 
                    appear in the 
                    'Title' only and not where they appear in the 'Summary'.  In other words, selecting one or more fields from the 
                    options in the 
                    drop-down list, forces the search to look ONLY in those selected fields in the collection. </p>

                <h4 id="Using-an-OR-in-searching-keywords">Using an 'OR' in searching keywords</h4>

                <p>By using the | ('pipe') symbol in the search field, you can apply 'OR' to the keywords 
                    entered into the search field.  The 'OR' search will return results containing either/any of the words entered. </p>
                <p>The pipe symbol can be found on your keyboard above the Enter button.</p>

                <p>For example, in a search for either "mad max fury road" OR "mad max thunderdome" as keywords, using the | ('pipe') symbol 
                    appropriately between the worlds/phrases will return results which contain either of the two keywords/phrases.</p>

                <img className="helpImg imgSearchBarOR" src={imgSearchBarOR} alt="Search bar with OR expression shown" />
                
                <img className="helpImg imgSearchORresults" src={imgSearchORresults} alt="Search results shown for an OR expression" />

                <p>In a further example, in a search for either “fury road” OR “thunderdome” as keywords it is possible to refine this further 
                    by adding “mad max” to the 'Also exactly match to' field 
                    to produce the same search results in the previous example. </p>
                
                <img className="helpImg imgSearchBarOR2" src={imgSearchBarOR2} alt="Search bar with another OR expression shown" />

                <h4 id="Refining-search-results-using-filter-categories-or-facets">Refining search results using filter categories or facets</h4>

                <p>Search <strong>filter categories</strong> (also called facets) can be applied to <strong>refine search results</strong>. 
                    These filter categories/facets appear down the left-hand panel on the search results page.</p>

                <img className="helpImg small imgFacetList1" src={imgFacetList1} alt="A listing of filters or facets" />

                <p>Filter categories are as follows:</p>

                
                <table className="table">
                    <tbody>
                        <tr>
                            <th>Category</th>
                            <th>Definition</th>
                        </tr>
                        <tr>
                            <th>Medium</th>
                            <td>Medium type in which the title was produced. For example: film, television, sound (published),
                                multimedia, software, network, documentation, radio, online media, artefact and sound (unpublished).
                            </td>
                        </tr>
                        <tr>
                            <th>Form</th>
                            <td>Form type relevant to the specific title, for example series, still image, script, publicity, 
                                music, poster, organisational papers, feature film, documentary, clip.</td>
                        </tr>
                        <tr>
                            <th>Colour</th>
                            <td>Colour in which the work was produced.</td>
                        </tr>
                        <tr>
                            <th>Year</th>
                            <td>Date range during which the work was created (recorded, published or broadcast).</td>
                        </tr>
                        <tr>
                            <th>Country</th>
                            <td>Country or countries from where the work originated. </td>
                        </tr>
                        <tr>
                            <th>Genre</th>
                            <td>Categories of works with similar plots, themes or setting, situation or characters. Categories include: Drama, Action, Crime, Adventure, Fantasy.</td>
                        </tr>
                        <tr>
                            <th>Language</th>
                            <td>Original language of the work. Additional languages also describe whether a work has been subtitled or dubbed in another language.</td>
                        </tr>
                        <tr>
                            <th>File Extension</th>
                            <td>The suffix at the end of a computer file, normally existing of 2-4 characters.</td>
                        </tr>
                        <tr>
                            <th>File Type</th>
                            <td>Description of the digital file type associated with the title. </td>
                        </tr>
                        <tr>
                            <th>Format</th>
                            <td>Broad technical category applied to a Medium. Categories are Film; Disc (Audio); Disc (Moving Image); 
                                Digital; Photographic; Paper; Object; Tape (Audio); Tape (Moving Image); 
                                Tape (Software); Magnetic Disk (Software); Cartridge (Software); Optical Disc (Software).</td>
                        </tr>
                        <tr>
                            <th>Usage</th>
                            <td>Archival category of a Holding (Media Item) which affects where the item is stored and its possible use. 
                                Broadly defined as Preservation, Duping or Access, with some variations on those depending on format.</td>
                        </tr>
                    </tbody>
                </table>
                
                <p>You can <strong>select facets from multiple filter categories</strong> and also select <strong>one or more facets</strong> within 
                those categories which support multiple facet values.</p>

                <p>The results page clearly displays which filters have been applied to the search results, and those filters can 
                    be removed by clicking the 'X' next the filter facet.</p>

                <img className="helpImg imgMultipleFacets" src={imgMultipleFacets} alt="Search results showing mutliple filters selected" />
                
                <a className="backTop" href="#top">Back to top of page</a>

                <h3 id="Viewing-an-individual-title">Viewing an individual title</h3>

                <p>Clicking on the <strong>title link</strong> will reveal further detail for that individual title record.</p>

                <img className="helpImg imgMadMaxPage" src={imgMadMaxPage} alt="The title page for mad max" />

                <p>Credit names and items under the 'Form' details that are hyperlinked will start a new search listing for that item.
                    
                For example, clicking on <strong>Feature Film</strong> will show a list of all feature films in the collection. 
                Clicking on a name in an individual title, for example, <strong>Byron Kennedy</strong>, will 
                show a list of all titles in which <strong>Byron Kennedy</strong> is included in the credits with that same credit role e.g. Byron Kennedy as Producer.</p>
                
                <a className="backTop" href="#top">Back to top of page</a>

                <h3 id="Adding-titles-to-a-list">Adding titles to a list</h3>

                <p>The <strong>list</strong> feature allows you to compile a list of titles. 
                From this List you can submit an Access Request (more information see below).</p>

                <img className="helpImg small imgListNav" src={imgListNav} alt="The title page for mad max" />

                <p>You can select titles to add to your list be either clicking 
                    the <strong>'+ Add'</strong> button to the right of the title on the search 
                    results page or by clicking on the <strong>'+ Add'</strong> button on the individual title page.</p>

                <img className="helpImg imgSearchResultsAdd" src={imgSearchResultsAdd} alt="The title page for mad max" />

                <img className="helpImg imgTitlePageAdd" src={imgTitlePageAdd} alt="The title page for mad max with add button highlighted" />
                
                <img className="helpImg imgHeaderList" src={imgHeaderList} alt="List options in the header of the site" />

                <p>You can access your list via the <strong>main menu navigation bar </strong>
                    using the <strong>'Your List'</strong> option or by the link showing <strong>"You have x item(s) in your list"</strong>.</p>

                

                <img className="helpImg imgListPage1" src={imgListPage1} alt="List page shown" />

                <p>Titles can be <strong>removed</strong> from your list by 
                clicking the <strong>trashcan icon</strong> within the list view page.</p>

                <p>You can <strong>remove all</strong> titles from your list using the <strong>'Clear All'</strong> button.</p>

                <a className="backTop" href="#top">Back to top of page</a>

                <h3 id="Adding-titles-to-an-access-request-form">Adding titles to an Access Request form</h3>

                <p>An access request can be submitted by clicking on the <strong>'Access Request'</strong> button at
                 the top of your list view. Note: you need to have at least one item in your list to make a request.</p>

            

                <img className="helpImg small imgListPage2" src={imgListPage2} alt="List page shown with access button highlighted" />

                <p>Note, this does not remove the titles from your list, but simply <strong>copies</strong> them into an <strong>Access Request form</strong>.</p>

                <img className="helpImg imgAccessService1" src={imgAccessService1} alt="Access Request page shown" />

                <p>You can <strong>remove titles from your Access Request form</strong>. Note that this <strong>does not</strong> remove the titles from your list view. </p>

                <p>If you delete a title(s) from your access request form in error, you can go back to your list and add the title again.</p>

                <p>You will need to complete all of the required fields on the form and then submit your request.</p>

                <p>Before submitting an Access Request we encourage you to read <a rel="noreferrer" target="_blank" href="https://www.nfsa.gov.au/collection/using-collection/using-collection">Using the Collection</a>. </p>

                <p>Once submitted, you will receive an on-screen message, and email, containing a reference number 
                    and an email address for enquiries relating to your access request.</p>

                <a className="backTop" href="#top">Back to top of page</a>

                <h3 id="More-searching-hints-and-tips">More searching hints and tips</h3>

                <ul>
                    <li>Multiple searches using different terms is advisable, in order to give the best chance of finding content that is of interest.</li>
                    <li>Frequently, catalogue records have no date, or an approximate date is entered. Be aware that  by specifying a date, you 
                may unintentionally exclude results that have no date, or an incorrect date entered. </li>
                    <li>When searching for oral histories, some relevant material may have been classified as an "Interview" rather than 
                an "Oral History" so it is better to search for both. </li>
                </ul>

                <h3 id="For-more-information">For more information</h3>

                <p>If you require additional information on our holdings after searching the collection catalogue or would like to find out more information about obtaining a copy of a particular title, please submit an <a rel="noreferrer" target="_blank" href="/request-form">Access Enquiry</a>. The Access team's response times are listed on the Access and Enquiry forms.</p>

                <a className="backTop" href="#top">Back to top of page</a>

                <h3 id="Glossary-of-terms">Glossary of terms</h3>


                <table className="table">
                    <tbody>
                        <tr>
                            <th>Term</th>
                            <th>Definition</th>
                        </tr>
                        <tr>
                            <th>Title</th>
                            <td>A specific work that contains intellectual and descriptive information about the work.</td>
                        </tr>
                        <tr>
                            <th>Form</th>
                            <td>Broader classification of works by a particular format and/or purpose for which a title was produced as, 
                                for example: advertisement, documentary, features, newsreels, spoken word, sport.</td>
                        </tr>
                        <tr>
                            <th>Medium</th>
                            <td>Content category applied to titles. There are nine mediums: Artefact; Documentation; Film; Multimedia; 
                                Networked Media; Radio; Sound Recording Published; Sound Recording Unpublished and Television.</td>
                        </tr>
                        <tr>
                            <th>Genre</th>
                            <td>Categories of works that are characterised by similar plots, themes or setting, situation or characters.</td>
                        </tr>
                        <tr>
                            <th>Holdings</th>
                            <td>Represents the physical or digital items belonging to the title, e.g., the set of six reels of film of an original release 
                                version. Contains technical information relating to the item as a whole.</td>
                        </tr>
                        <tr>
                            <th>Title Number</th>
                            <td>Unique identification number assigned to a specific title.</td>
                        </tr>
                        <tr>
                            <th>Object</th>
                            <td>Physical objects or artefacts related to the works or their creators, such as film equipment, musical instruments, costumes, or props.</td>
                        </tr>
                        <tr>
                            <th>Format</th>
                            <td>Broad technical category applied to a Medium. Categories are Film; Disc (Audio); Disc (Moving Image); Digital; Photographic; Paper; Object; 
                                Tape (Audio); Tape (Moving Image); Tape (Software); Magnetic Disk (Software); Cartridge (Software); Optical Disc (Software).</td>
                        </tr>
                        <tr>
                            <th>Role</th>
                            <td>The name of the principal credit role that describes the role the person played in that work, such as Director 
                                for film or Performer for recorded sound.</td>
                        </tr>
                        <tr>
                            <th>Holding Number</th>
                            <td>Unique identifier for a given Item or Title. 
                                For example: '24423_0001' which represents the Title number and Holdings number, separated by an underscore '_'. The number can also appear with two underscores, for example, '24423_34422_3', which indicates that the Item belongs to a media set (a grouping/bundling of related media items together).</td>
                        </tr>

                    </tbody>
                </table>


                <a className="backTop" href="#top">Back to top of page</a>




                <br /><br />












            </div>{/* <!-- end content --> */}

        </div> {/* <!-- end help wrapper --> */}
        </>
    );

}