import React from "react";
import "../../../assets/css/HoldingPlayer.css"

export default function HoldingPlayer({holding, onVideoLoad}) {

    //const videoImageUrl = "https://media.nfsacollection.net/" + holding.preview.thumbnailFilePath;
    const previewRef = React.useRef(null);
    
    //const [defaultFrame, setDefaultFrame] = React.useState(0);
    const [previewLoaded, setPreviewLoaded] = React.useState(false);
    const [previewTime, setPreviewTime] = React.useState(0.0);
    const [playStarted, setPlayStarted] = React.useState(false); //set once after play hit for first time
    const [previewPlaying, setPreviewPlaying] = React.useState(false);
    const [seekerDragging, setSeekerDragging] = React.useState(false);
    const [previewHover, setPreviewHover] = React.useState(false);
    const [previewMuted, setPreviewMuted] = React.useState(false);

    function onVideoOverviewPlay() {
        //console.log("onvideoplay");
        if (previewPlaying) return;
        resetPreviewStart();
        setPreviewPlaying(true);
    }

    function onVideoPlayPause(e) {
        //console.log(e)
        e.stopPropagation();
        if (!playStarted) {
            setPlayStarted(true); //regardless play pause always set to true
            resetPreviewStart(); //because poster requires time set to 50%
        }
        
        //console.log("onVideoPlayPause")
        if (previewPlaying) {
           // console.log("pausing video");
            previewRef.current.pause();
            setPreviewPlaying(false);
        } else {
            previewRef.current.play();
            setPreviewPlaying(true);
        }
        
    }


    function resetPreviewStart() {
        previewRef.current.currentTime = 0;
        previewRef.current.play();
        //sometimes number display shows wrong time for a split moment, setTimeout ensures interface is fully updated before timing info shows.
        setTimeout(function() { 
            setPlayStarted(true);
        }, 300)
    }

    function previewReady() {
        //many videos have start frame that is black, so set it to halfway through until we get some sort of poster standard
        let posterFrame = previewRef.current.duration / 2;
        //console.log(posterFrame);
        previewRef.current.currentTime = posterFrame; 
        if (holding.preview.type === 'video') onVideoLoad();
        
        setPreviewLoaded(true);
    }

    function previewTimeUpdate() {
        setPreviewTime(previewRef.current.currentTime);
    }


    function formatPreviewTime(previewTime) {
        
        let vSeconds = Math.floor(previewTime);

        let vHours = Math.floor(vSeconds / 3600);
        vSeconds -= vHours*3600;
        let vMinutes = Math.floor(vSeconds / 60);
        vSeconds -= vMinutes*60;

        if (vHours < 10) {vHours = "0" + vHours;}
        if (vMinutes < 10) {vMinutes = "0" + vMinutes;}
        if (vSeconds < 10) {vSeconds = "0" + vSeconds;}

        let formattedTime = "";
        if (vSeconds > 3600) { //longer than an hour, show hours:minutes:seconds, otherwise just show minutes:seconds
            formattedTime = vHours+':'+vMinutes+':'+vSeconds;
        } else {
            formattedTime = vMinutes+':'+vSeconds;
        }
        
        return formattedTime;
    }

    function onSeekerClick(e) {
        e.stopPropagation();
        setSeekerDragging(true);
        previewRef.current.currentTime = calculaterSeekerPos(e);
    }

    function calculaterSeekerPos(e) {
        var bounds = e.target.getBoundingClientRect();
        let x = e.clientX - bounds.left;
        let percentage = (100 * x) / bounds.width;
        //console.log(percentage);

        //now get percentage of total duration
        let timeMark = (percentage / 100) * previewRef.current.duration;
        //console.log(percentage.toFixed(2) + "% of " + previewRef.current.duration + " is " + timeMark);
        return timeMark;
        
    }

    function onSeekerRelease(e) {
        setSeekerDragging(false);
    }

    function onSeekerMove(e) {
        if (!seekerDragging) return;
        previewRef.current.currentTime = calculaterSeekerPos(e);
    }

    function onEnd() {
        previewRef.current.currentTime = 0;
        setPlayStarted(false);
        setPreviewPlaying(false);
    }

    function goFullScreen() {

        if (previewRef.current.requestFullscreen) {
            previewRef.current.requestFullscreen();
        }
    }

    function muteToggle() {
        if (previewMuted) {
            setPreviewMuted(false);
            previewRef.current.muted = false;
        } else {
            setPreviewMuted(true);
            previewRef.current.muted = true;
        }
        
    }

    return (

        <div className="holdingPreview" tabIndex={0} onFocus={() => setPreviewHover(true)} onBlur={() => setPreviewHover(false)}>
            
            { holding.preview.type === 'video' && 
            <video crossOrigin="anonymous" onLoadedData={() => previewReady()} onTimeUpdate={() => previewTimeUpdate()} onEnded={() => onEnd()} ref={previewRef}>
                <source src={`https://media.nfsacollection.net/${holding.preview.filePath}`} />
            </video> }

            { holding.preview.type === 'audio' && 
                <div className={`audioWrapper${(previewPlaying || playStarted) ? ' playing' : ''}`}>
                    <audio crossOrigin="anonymous" onLoadedData={() => previewReady()} onTimeUpdate={() => previewTimeUpdate()} onEnded={() => onEnd()} ref={previewRef}>
                        <source src={`https://media.nfsacollection.net/${holding.preview.filePath}`} type="audio/mpeg" />
                    </audio>
                </div> }

            <div className={`previewOverlay${(previewPlaying || playStarted) ? ' playing' : ''}`} 
                onClick={() => onVideoOverviewPlay()} 
                onMouseOver={() => setPreviewHover(true)} 
                onMouseOut={() => setPreviewHover(false)}>
                
                <button 
                aria-label="Mute button" 
                className={`previewMute${previewHover ? ' show' : ''}${previewMuted ? ' muted' : ''}`} 
                aria-hidden={!previewHover} 
                tabIndex={previewHover ? '' : -1}
                onClick={() => muteToggle()}>
                    <span>Mute</span>
                </button>

                { holding.preview.type === 'video' && 
                    <button 
                    aria-label="Toggle full screen" 
                    className={`previewFullScreen${previewHover ? ' show' : ''}`} 
                    aria-hidden={!previewHover} 
                    tabIndex={previewHover ? '' : -1}
                    onClick={() => goFullScreen()}>
                        <span>fullscreen</span>
                    </button>
                }
                
                {previewLoaded && playStarted &&
                    <div className={`previewControls${(previewHover || holding.preview.type === 'audio') ? ' showControls' : ''}`} onClick={(e) => (e.stopPropagation())}>

                        <div className="controlsSection sectionPlay">
                            <button 
                            aria-label="Play the video" 
                            className={`playPauseButton${previewPlaying ? ' playing' : ''}`} 
                            aria-hidden={!previewHover} 
                            tabIndex={previewHover ? '' : -1}
                            onClick={(e) => onVideoPlayPause(e)}>
                                <span>Play</span>
                            </button>
                        </div>

                        <div className="controlsSection sectionTimer">
                            <div className="previewCurrentTime">
                                {playStarted ? formatPreviewTime(previewTime) : "00:00"}
                            </div>
                        </div>

                        <div className="controlsSection sectionSeek">
                            <div className="previewSeekBar" onClick={(e) => (e.stopPropagation())} onMouseDown={(e) => onSeekerClick(e)} onMouseMove={(e) => onSeekerMove(e)} onMouseUp={(e) => onSeekerRelease(e)}>
                                
                                <div className="seekBar">
                                </div>
                                {playStarted &&
                                        <div className="seeker" style={{width: `${(100 * previewTime) / previewRef.current.duration}%`}}></div>
                                    }
                            </div>
                        </div>

                        <div className="controlsSection sectionDuration">
                            <div className="previewEndTime">
                                { previewLoaded ? formatPreviewTime(previewRef.current.duration) : '00:00'}
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}