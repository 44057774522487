import React, { useEffect } from "react";
import MasonryHoldingItem from "./MasonryHoldingItem";
//import MasonryPreviewItem from "./MasonryPreviewItem";
import "../../../assets/css/MasonryHoldings.css";
import "../../../assets/css/MasonryPreviews.css";
import HoldingsMoreButton from "./HoldingsMoreButton";

export default function MasonryHoldings({title}) {

    const [fadeIn, setFadeIn] = React.useState(false);
    const [closed, setClosed] = React.useState(false); //sent to children to update their height values on refilter

    const [filteredItems, setFilteredItems] = React.useState([]);  //the currently filtered items, may include 'all'
    const [holdingItems, setHoldingItems] = React.useState([]); //original set of 'all' holdings (after previews removed)
    const [filterButtons, setFilterButtons] = React.useState([]); //filterable listings

    const [currentFilterButton, setCurrentFilterButton] = React.useState("all");

    const [masonryCol1, setMasonryCol1] = React.useState([]);
    const [masonryCol2, setMasonryCol2] = React.useState([]);
    const [masonryCol3, setMasonryCol3] = React.useState([]);

    const [viewLimit, setViewLimit] = React.useState(12); //show only 12 (eg, 3 rows)
    const [showAll, setShowAll] = React.useState(false);

    const tabletLayout = window.matchMedia("(max-width: 1020px)").matches;
    const mobileLayout = window.matchMedia("(max-width: 630px)").matches;

    useEffect(() => {
        const normalItems = title.media.filter( //remove items that contain previews
                (obj, index) => {
                    //return !Object.hasOwn(obj, 'previewPath');
                    return !Object.hasOwn(obj, 'preview'); 
                }
            );
        
        setFilteredItems([...normalItems]); //these get filtered
        setHoldingItems([...normalItems]); //these are the orginals.

    }, [title.media]);

    useEffect(() => {
        if (tabletLayout || mobileLayout) {
            setViewLimit(6);
        }
    },[viewLimit, tabletLayout, mobileLayout]);

    useEffect(() => {

        let filterButtons = [];
        holdingItems.forEach((obj, index) => {
            if (holdingItems.findIndex((item) => item.itemUsage === obj.itemUsage) === index) {
                filterButtons.push(obj.itemUsage);
            }
        });
        setFilterButtons([...filterButtons]);
    }, [holdingItems]);



    useEffect(() => { //after 300ms remove the fadeIn class on all child holding items
        const timeoutId = setTimeout(() => {
            setFadeIn(true);
        }, 300);
        return () => clearTimeout(timeoutId);
    });
    

    useEffect(() => {

        let iterations = 3;
        if (tabletLayout && !mobileLayout) {
            iterations = 2;
        } else if (mobileLayout) {
            iterations = 1;
        }
        //create 3 arrays representing each column but ordered as counting from left to right across the columns:
        let newCol1 = [];
        let newCol2 = [];
        let newCol3 = [];

        for (let i = 0; i <= filteredItems.length; i += iterations) { //every three iterations desktop, 2 iterations tablet, just stacked on mobile

            //if filterItems and holdingItems have the same length then there is no filtering applied and we show the 'show more' option
            if (i >= viewLimit && !showAll && filteredItems.length === holdingItems.length) { 
                break;
            }

            newCol1.push(filteredItems[i]); //col 1 first row item (1 - 0 - 0)

            if (mobileLayout) continue; //only need 1 col for mobile.

            if ((i + 1) <= (filteredItems.length - 1)) { //col 2 contains first row + 1 offset (0 - 1 - 0)
                newCol2.push(filteredItems[i + 1]);
            }

            if (!tabletLayout) { //third col not needed tablet layout, only 2 cols
                if ((i + 2) <= (filteredItems.length - 1)) { //col 3 contains first row + 2 offset (0 - 0 - 1)
                    newCol3.push(filteredItems[i + 2]);
                }
            } 
            

        }
        setMasonryCol1([...newCol1]);
        if (!mobileLayout) setMasonryCol2([...newCol2]);
        if (!tabletLayout) setMasonryCol3([...newCol3]);
        // console.log(newCol1);
        // console.log(newCol2);
        // console.log(newCol3);

    //}, [title.media, filteredItems]);
    }, [title.media, filteredItems, viewLimit, showAll, holdingItems, tabletLayout, mobileLayout]);

    const onShowAll = () => {
        setFadeIn(false);
        setShowAll(!showAll);
    }

    function reFilter(filterButton) {
  
        setFadeIn(!fadeIn); //fade in class gets reapplied
        //setClosed(true);
        setClosed(!closed);
        setCurrentFilterButton(filterButton);
        if (filterButton === "all") {
            setFilteredItems([...holdingItems]); //holdingItems contains the original unfiltered holdings (except for previews)
            return; //just bail now as we reset it all back
        }

        let newDisplayedItems = holdingItems.filter((holding, i) => {
            return holding.itemUsage === filterButton;
        });

        setFilteredItems([...newDisplayedItems]);
        
    }

    return (
        <>
            <div className="masonryHoldingsHeader">
                <h3 className="holdingsTitle">Holdings</h3>
                {/* must be at least 2 filters otherwise filters are meaningless */}
                { filterButtons.length > 1 && 
                    <div className="holdingsFilterButtons">
                        <button className={`${(currentFilterButton === 'all') ? 'selected' : ''}`} onClick={() => reFilter("all")}>
                            All
                        </button>
                        {
                            filterButtons.map((filterButtonText, i) => {
                                return (
                                    <button className={`${(currentFilterButton === filterButtonText) ? 'selected' : ''}`} key={i} onClick={() => reFilter(filterButtonText)}>
                                        { filterButtonText }
                                    </button>
                                )
                            })
                        }
                    </div>
                }
            </div>
            

            <div className="masonryHoldings">
                <div className="col col1">
                
                    {
                        masonryCol1.map((holding, i) => {
                            return (
                                holding && <MasonryHoldingItem holding={holding} closed={closed} fadeIn={fadeIn} title={title} key={i} />
                            )
                        })
                    }
                   { mobileLayout && <HoldingsMoreButton showAll={showAll} holdingItemsLength={holdingItems.length} filteredItemsLength={filteredItems.length} viewLimit={viewLimit} onShowAll={onShowAll}/> }
                   
                </div>

                {!mobileLayout &&
                    <div className="col col2">
                        {
                            masonryCol2.map((holding, i) => { 
                                return (
                                    holding && <MasonryHoldingItem holding={holding} closed={closed} fadeIn={fadeIn} title={title} key={i} />
                                )
                            })
                        }
                        { tabletLayout && <HoldingsMoreButton showAll={showAll} holdingItemsLength={holdingItems.length} filteredItemsLength={filteredItems.length} viewLimit={viewLimit} onShowAll={onShowAll}/> }
                    </div>
                }

                {!tabletLayout && 
                    <div className="col col3">
                        {
                            masonryCol3.map((holding, i) => {
                                return (
                                    holding && <MasonryHoldingItem holding={holding} closed={closed} fadeIn={fadeIn} title={title} key={i} />
                                )
                            })
                        }
                        
                        <HoldingsMoreButton showAll={showAll} holdingItemsLength={holdingItems.length} filteredItemsLength={filteredItems.length} viewLimit={viewLimit} onShowAll={onShowAll}/>

                    </div>
                }
            </div>
        </>
    )
}