//export default function getQueryValue(sText, v) {
import getSearchFromRoute from "./getSearchFromRoute";

export default function getQueryValue(v) {

    var urlString = getSearchFromRoute("/search/");
    //console.log("getQueryValue url is: " + urlString);
    var vars = urlString.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) === v) {
            //console.log("returning" + decodeURIComponent(pair[1]));
            return decodeURIComponent(pair[1]);
        }
    }
    //console.log('Query variable %s not found', v);
    return "";
}