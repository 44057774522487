const formatCreditName = (credit) => {

    let creditName = "";
    if (credit.about.firstName && credit.about.surnameOrCompanyName) {
        if (credit.about.otherName) {
            creditName = credit.about.firstName.trim() + " " + credit.about.otherName.trim() + " " + credit.about.surnameOrCompanyName.trim();
        } else {
            creditName = credit.about.firstName.trim() + " " + credit.about.surnameOrCompanyName.trim();
        }
        
    } else {
        //if all other name fields null or blank but surname/company still has value, it presumes to be the company name and overwrites 'name'
        if (credit.about.surnameOrCompanyName) { 
            creditName = credit.about.surnameOrCompanyName.trim();
        } else {
            creditName = credit.name.trim();
        }
        
    }

    return creditName;
}

export default formatCreditName;