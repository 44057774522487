import "../assets/css/Modal.css";
export default function Modal({children, close, confirm}) {

    function onOuterClick(e) {
        if(e.target === e.currentTarget) { // do not close on child nodes
            console.log("parent clicked");
            close();
        }
    }

    return (
        <div className="modal" onClick={onOuterClick}>
            <div className="inner">
                
                    
                {children}

                <div className="buttons">
                    { confirm ? 
                    
                    <div className="confirmButtons">
                        <button className="cancelButton" onClick={close}>
                            <span>Cancel</span>
                        </button>
                        <button className="confirmButton" onClick={confirm}>
                            <span>Yes</span>
                        </button>
                    </div>
                    :
                    <button className="closeButton" onClick={close}>
                        <span>Close</span>
                    </button>
                    }

                </div>
            </div>
        </div>
    );

}