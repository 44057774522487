
import { useDispatch, useSelector } from 'react-redux';
import "../assets/css/PopMessage.css";
import { useEffect } from "react";
import { setPopMessage } from '../redux/popMessageSlice';
import { Link } from 'react-router-dom';

export default function PopMessage() {

    const dispatch = useDispatch();
    const isDown = useSelector((state) => {
        return state.popmessage.show;
    });

    useEffect(() => {
        if (isDown) { //if popup is flagged to show, then run a timer to remove it after a few seconds.
            const popTimer = setTimeout(() => {
                dispatch(setPopMessage(false));
            }, 6000);
            return () => clearTimeout(popTimer); //cleanup function
        }
        
    }, [isDown, dispatch]);

    return (
        <div className={`popdown ${isDown ? 'down' : ''}`}>
            <p className='msg' role="alert">
                Saved to your <Link to="/list" tabIndex="-1">list</Link>
            </p>
        </div>
    );
}