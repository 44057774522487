import cleanFacetValue from "./cleanFacetValue";

export default function checkFullFacetInUse(urlPath, queryParam, queryValue) {

    let fullFacet = queryParam + "=" + cleanFacetValue(queryValue);

    var urlVars = urlPath.split('&');
    for (var i = 0; i < urlVars.length; i++) {
        var pair = urlVars[i].split('=');
        if (pair[0] === 'query') continue; //dont process the search string
        let cleanedFacet = cleanFacetValue(pair[1]);
        let urlFullFacetCleaned = pair[0] + "=" + cleanedFacet;

        if (urlFullFacetCleaned === fullFacet) { 
            return true;
        }
    }
    return false;
}