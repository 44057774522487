import "../assets/css/SearchControl.css";
import React, {useEffect} from "react";
//import FieldSelectDrop from "./FieldSelectDrop";
import SearchControlBox from "./SearchControlBox";
import { setSearchString, setSearchFields, setExactSearch, setPreviewsSearch, setExactStringSearch, setExcludeStringSearch } from '../redux/searchSlice';
import {useNavigate} from 'react-router-dom';
//import {useLocation} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import googleTagPush from "../helpers/googleTagPush";
import getQueryValue from "../helpers/getQueryValue";
import getFieldsFromURL from "../helpers/getFieldsFromURL";
import SearchClear from "./SearchClear";

export default function SearchControl({homeFacets, setShowFacetsPanel}) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    //let location = useLocation();

    const isLoading = useSelector((state) => {
        return state.loader.isLoading;
    });

    let globalSearchFields = useSelector((state) => {
        return state.search.searchFields;
    });

    let globalExactCheck = useSelector((state) => {
        return state.search.exactSearch;
    });

    let globalPreviewsCheck = useSelector((state) => {
        return state.search.previewsSearch;
    });
    
    let globalExactStringSearch = useSelector((state) => {
        return state.search.exactStringSearch;
    });

    let globalExcludeStringSearch = useSelector((state) => {
        return state.search.excludeStringSearch;
    });

    let searchString = useSelector((state) => {
        return state.search.searchString
    });

    //these are all LOCAL state, there are equivalent values in the global searchSlice store. 
    const [showAdvanced, setShowAdvanced] = React.useState(false);
    const [selectedFields, setSelectedFields] = React.useState([]);
    const [exactCheck, setExactCheck] = React.useState(false);
    const [previewsCheck, setPreviewsCheck] = React.useState(false);
    const [exactString, setExactString] = React.useState("");
    const [excludeString, setExcludeString] = React.useState("");

     //this useEffect is neccesary for showing advanced controls and values when search page has been refreshed or shared
    useEffect(() => {
    
        let queryExact = getQueryValue("queryExact");
        let currentQuery = getQueryValue("query");
        let queryExclude = getQueryValue("queryExclude");

        //if the address bar contains only queryExact then ensure exactCheckbox is checked
        if (!currentQuery && queryExact) { 
            setExactCheck(true);
        }

        //if address bar contains queryExact AND query then the exact query string is filled and the extra tools needs to be expanded + string filled.
        if (currentQuery && queryExact) { 
            setShowAdvanced(true);
            setExactString(queryExact)
        }

        //if address bar contains exlude string, then fill input + expand 
        if (queryExclude) {
            setShowAdvanced(true);
            setExcludeString(queryExclude);
        }

        //if no exclude or exact string, un-expand bar 
        if (!queryExclude && !queryExact) {
            setShowAdvanced(false);
        }

    }, []);

    useEffect(() => {
        if (document.getElementById("searchBox") !== null) {
            document.getElementById("searchBox").focus();
        }

        let urlFields = getFieldsFromURL();
        setSelectedFields([...urlFields]);
        dispatch(setSearchFields([...urlFields]));

    },[dispatch]);

    //we use this useEffect to check the global store values and update the gui as needed.
    //eg. the home page search will clear the exact checkbox in the global state so we need to ensure it is toggled off here.
    useEffect(() => { 
        
        //if we don't have exact match turned on, ensure the button is unchecked.
        let queryExact = getQueryValue("queryExact");
        if (!queryExact) {
            setExactCheck(false); //ensure local button state is off
        } else { //exact is in the url so ensure global state is on.
            dispatch(setExactSearch(true)); 
        }

        //previewscheck
        let previewsChecked = getQueryValue("hasMedia");
        if (previewsChecked === 'yes') {
            setPreviewsCheck(true);
            dispatch(setPreviewsSearch(true)); 
        } 

        //'also exactly match' needs to be cleared
        let currentQuery = getQueryValue("query");
        if (currentQuery && !queryExact) {
            setExactString("");
        } else {
            dispatch(setExactStringSearch(queryExact));
            setShowAdvanced(true);
        }

        let queryExclude = getQueryValue("queryExclude");
        if (!queryExclude) {
            setExcludeString("");
        } else {
            dispatch(setExcludeStringSearch(""));
            setShowAdvanced(true);
        }

        if (!queryExclude && !queryExact) {
            setShowAdvanced(false);
        }
        
        //if the url doesn't contain any fields, clear the global 'searchFields' state
        let urlFields = getFieldsFromURL();
        if (!urlFields || urlFields.length < 1) setSelectedFields([]);

    }, [dispatch, globalSearchFields, globalExactCheck, globalExactStringSearch, globalExcludeStringSearch, globalPreviewsCheck]);

    const toggleAdvanced = () => {
        if (setShowFacetsPanel) setShowFacetsPanel(!showAdvanced); //this is for the home page checkboxes
        setShowAdvanced(!showAdvanced);
    }

    function handleSearchString(e) {
        dispatch(setSearchString(e.target.value));
    }

    function handleExactString(e) {
        setExactString(e.target.value);
    }

    function handleExcludeString(e) {
        setExcludeString(e.target.value);
    }

    const handleSearch = (e) => {
        e.preventDefault();
        const s = e.target.elements.searchText.value.trim();
        if (!s) return;

        const excludeString = e.target.elements.excludeString.value.trim();
        const exactString = e.target.elements.exactMatchText.value.trim();

        let fullQuery = `/search/query=${encodeURIComponent(s)}`;
        if (!exactString && exactCheck) { //without query, pure exact search - if exact string provided, ignore
            fullQuery = `/search/queryExact=${encodeURIComponent(s)}`;
        }

        if (previewsCheck) {
            fullQuery += "&hasMedia=yes";
        }
        
        if (excludeString) {
            fullQuery += `&queryExclude=${encodeURIComponent(excludeString)}`;
        }

        if (exactString) {
            fullQuery += `&queryExact=${encodeURIComponent(exactString)}`;
            dispatch(setExactSearch(true));
        }

        if (selectedFields.length > 0) {
            fullQuery += "&queryIn=";
            selectedFields.forEach((f,i) => {
                //console.log(i);
                fullQuery += encodeURIComponent(f);
                if (i <= (selectedFields.length - 2)) {
                    fullQuery += encodeURIComponent("|");
                }
            });
            dispatch(setSearchFields([...selectedFields]));
        } else {
            dispatch(setSearchFields([]));
        }

        //if no exclude or exact string, un-expand bar 
        if (!excludeString && !exactString) {
            setShowAdvanced(false);
        }

        //check if home page facets have been provided;
        if (homeFacets && homeFacets.length > 0) {
            let homeFacetsString = "&subMedium=";

            //build facet url
            homeFacets.forEach((f,i) => {
                //if comma, wrap in quotes and encode
                if (f.includes(",")) { 
                    f = `"${f}"`;
                    f = encodeURIComponent(f);
                }

                homeFacetsString += f + "|";
            });

            homeFacetsString = homeFacetsString.substring(0, homeFacetsString.length - 1); //remove last "|"
            fullQuery += homeFacetsString;
        }

        googleTagPush('search', {
            'search_term': s,
            'search_excludes': excludeString,
            'search_exact': exactCheck ? s : exactString,
            'search_previews': previewsCheck ? true : false,
            'search_field_title': selectedFields.includes("title") ? true : false,
            'search_field_summary': selectedFields.includes("summary") ? true : false,
            'search_field_person_or_organisation': selectedFields.includes("CreditNames") ? true : false,
            'search_field_version_id': selectedFields.includes("id") ? true : false,
            'search_field_series_title': selectedFields.includes("parentTitle.seriesTitle") ? true : false,
            'search_field_version_name': selectedFields.includes("name") ? true : false,
            'search_field_alternative_title': selectedFields.includes("alternativetitles") ? true : false,
            'search_field_year': selectedFields.includes("year") ? true : false,
        });

        navigate(fullQuery);
    }


    function handleFieldCheck(e) {
        //console.log("handleFieldCheck " + e.target.value);
        let field = e.target.value;
        if (selectedFields.includes(field)) {
            let filteredFields = selectedFields.filter(item => item !== field);
            setSelectedFields([...filteredFields]);
        } else {
            setSelectedFields( (newField) => {
                return [...newField, e.target.value]
            });
        }
        
    }

    const handleExactCheck = (e) => {
        //console.log(e.target.checked);
        setExactCheck(e.target.checked);
    }

    const handlePreviewsCheck = (e) => {
        //console.log(e.target.checked);
        setPreviewsCheck(e.target.checked);
    }

    function clearText() {
        dispatch(setSearchString(""));
    }

    function clearExcludeText() {
        setExcludeString("");
    }

    function clearExactText() {
        setExactString("");
    }

    return (
            <div className="SearchControl">
                
                <form className="SearchControlForm" onSubmit={handleSearch}>
                    <div className="topBar">
                        <SearchControlBox 
                            handleSearchString={handleSearchString} 
                            isLoading={isLoading} 
                            clearText={clearText}
                            searchString={searchString}
                            handleFieldCheck={handleFieldCheck}
                            selectedFields={selectedFields} />
                    </div>
                    <div className="midBar">

                        <div className={`advancedToggle ${showAdvanced ? "show" : ""}`}>
                            <button className="simpleButton" type="button" onClick={() => toggleAdvanced()}>
                                <span>Simple</span>
                            </button>

                            <button className="advancedButton" type="button" onClick={() => toggleAdvanced()}>
                                <span>Advanced</span>
                            </button>
                        </div>

                        <div className="previewMediaButton">
                            <input onChange={e => handlePreviewsCheck(e)} className="previewMediaCheck" id="previewMediaCheck" type="checkbox" checked={previewsCheck ? "checked" : ""} />
                            <label htmlFor="previewMediaCheck">Has preview media</label>
                        </div>

                        { !exactString && <div className="exactMatchButton">
                            <input className="exactMatchCheck" onChange={e => handleExactCheck(e)} id="exactMatchCheck" type="checkbox" checked={exactCheck ? "checked" : ""} />
                            <label htmlFor="exactMatchCheck">Only exact matches</label>
                        </div> }

                    </div>
                    <div className={`bottomBar ${showAdvanced ? "open" : ""}`}>
                        <div className="advancedControls">

                            <div className="excludeBlock">
                                <label htmlFor="excludeString">Exclude:</label>
                                <div className="excludeStringBox">
                                    <input placeholder="Excluded keywords..." value={excludeString} onChange={handleExcludeString} type="text" id="excludeString"/>
                                    { excludeString && <SearchClear clearText={clearExcludeText} /> }
                                </div>
                            </div>

                            <div className={`exactMatchTextBlock ${exactCheck ? "hidden" : ""}`}>
                                <label htmlFor="exactMatchText">Also exactly match to:</label>
                                <div className="exactStringBox">
                                    <input disabled={`${exactCheck ? "disabled" : ""}`} placeholder="Additional keywords..." value={exactString} onChange={handleExactString} type="text" id="exactMatchText"/>
                                    { exactString && <SearchClear clearText={clearExactText} /> }
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </form>
                
            </div>
        
        
    )
}