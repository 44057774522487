import "../../../assets/css/FacetSidebar.css"
import { Link } from 'react-router-dom';
import createFacetToggleUrl from "../../../helpers/createFacetToggleUrl";
//import checkFullFacetInUse from "../../../helpers/checkFullFacetInUse";
// import checkMultiFacetInUse from "../../../helpers/checkMultiFacetInUse";
import getSearchFromRoute from "../../../helpers/getSearchFromRoute";
import { useSelector} from 'react-redux';
import cleanFacetValue from "../../../helpers/cleanFacetValue";

export default function FacetSidebarYear({facetCounts}) {

    const searchFacets = useSelector((state) => {
        return state.search.searchFacets;
    });

    if (!facetCounts.year.buckets || facetCounts.year.buckets.length < 1) return;

    let yearCounts = facetCounts.year.buckets;
    
    let urlSearch = getSearchFromRoute("/search/");

    //sort the array by year (_id = year)
    yearCounts.sort((a, b) => a._id - b._id);

    //find the lowest and highest year and round them up/down to a decade.
    let lowestYear = yearCounts[0]._id;
    let highestYear = yearCounts[yearCounts.length - 1]._id;

    let lowestYearDecade = Math.floor(lowestYear / 10) * 10;
    let highestYearDecade = Math.ceil(highestYear / 10) * 10;

    //create array of decades to hold our pairs/years
    let decades = [];

    //count through an array of decade pairs -starting at the lowest decade year and ending at the highest.
    for(let decade = lowestYearDecade; decade <= highestYearDecade; decade = decade + 10) {

        let decadePair = `${decade}-${decade + 9}`;

        let decadePairCount = 0;
        //for decade pair, find matching counts in original array
        yearCounts.forEach((year, i) => {
            
            if (year._id >= decade &&  year._id <= (decade + 9)) {
                decadePairCount += year.count;
            }
        });
        if (decadePairCount > 0) {
            let decadeObj = {
                decadePair: decadePair,
                facetTotalCount: decadePairCount
            }
            decades.push(decadeObj);
        } 
    }

    decades.sort((a, b) => { //sort so that the SELECTED facets are placed at the top (within a group)
        let facetAToggled = searchFacets.some((e, i) => { //match with the global searchFacets array
            let cleanFacetVal = cleanFacetValue(e.facetValue); //remove any quotes or %20 stuff for compare
            if (cleanFacetVal === a.decadePair) {
                return true;
            }
            return false;
        })
        let facetBToggled = searchFacets.some((e, i) => { //match with the global searchFacets array
            let cleanFacetVal = cleanFacetValue(e.facetValue); //remove any quotes or %20 stuff for compare
            if (cleanFacetVal === b.decadePair) {
                return true;
            }
            return false;
        })
        if (facetAToggled === facetBToggled) return 0;
        if (facetAToggled) return -1;
        if (facetBToggled) return 1;
        return 0;
    });
    

    let createDecadeLink = (yearLink) => {

        let newQueryString = createFacetToggleUrl(urlSearch, "year", encodeURIComponent(yearLink.decadePair)); 

        //check if this facet link is in use
        let facetToggled = searchFacets.some((e, i) => {
            let unquotedFacetValue = cleanFacetValue(e.facetValue); //remove quotes for compare
            if (unquotedFacetValue === yearLink.decadePair && e.facetName === "year") {
                //console.log("we have a match on facetValue " + facetValue + " and facetNAME " + e.facetName);
                return unquotedFacetValue === yearLink.decadePair
            }
            return false;
        })

        return (
             <>
                <Link className={`facetLink ${facetToggled ? 'toggle' : ''}`} to={`/search/${newQueryString}`}>
                <span className="facetTitle">{yearLink.decadePair}</span>
                { facetToggled ? null : <span className="facetCount"><span>{yearLink.facetTotalCount}</span></span> }
                </Link>
             </>
        )
    }

    return (

        <>
        { decades.length > 0 && 
            <div className="inner">
                <div className="sidebarHeader">Year</div>
                <ul>
                    {decades.map((yearLink, i) => {
                        return (
                            <li key={i}>
                                { createDecadeLink(yearLink) }
                            </li>
                        )
                    })}
                </ul>
            </div>
        }
        </>

    );

}