/*
    This function appends ',' to a facet already existing in the query
    eg, if we pass in fGenreList=Comedy and fullQuery already contains fGenreList=Drama,
    the return value should be fGenreList=Comedy,Drama
*/

export default function appendANDValue(fullQuery, facetName, newValue) {

    let parts = fullQuery.split('&');
    let newQuery = "";
    for (var i = 0; i < parts.length; i++) {
        var pair = parts[i].split('=');
        if (pair[0] === facetName) {

            //get full string
            let fullfacetStr = pair[0] + "=" + pair[1];

            //remove string
            newQuery = fullQuery.replace(fullfacetStr, ""); //ampersand might not be there on single facet listings.

            //if there are double ampersands leftover from the replace, set them back to single (these are not URI encoded &'s but querystring seperators)
            newQuery = newQuery.replace("&&", "&");

            //strip any ampersands left on the end (if facet was on the end it might be left over)
            newQuery = newQuery.replace(/&$/, "");

            //now build new facets with ANDS onto the end
            let newFacetWithANDs = pair[0] + "=" + pair[1] + "," + newValue; 
            newQuery = newQuery + "&" + newFacetWithANDs;

            // if its a pure facet listing with no search string, we need to also ensure the ampersand isn't left at the start
            if (!fullQuery.includes("query")) {
                newQuery = newQuery.replace(/^&/, "");
            }
            
        }
    }
    return newQuery;
}

// export default function appendANDValue(fullQuery, facetName, newValue) {

//     let parts = fullQuery.split('&');
//     let newQuery = "";
//     for (var i = 0; i < parts.length; i++) {
//         var pair = parts[i].split('=');
//         if (pair[0] === facetName) {

//             //get full string
//             let fullfacetStr = pair[0] + "=" + pair[1];

//             //remove string
//             newQuery = fullQuery.replace(fullfacetStr, ""); //ampersand might not be there on single facet listings.

//             //if there are double ampersands leftover from the replace, set them back to single (these are not URI encoded &'s but querystring seperators)
//             newQuery = newQuery.replace("&&", "&");

//             //strip any ampersands left on the end (if facet was on the end it might be left over)
//             newQuery = newQuery.replace(/&$/, "");

//             //now build new facets with ANDS onto the end
//             let newFacetWithANDs = pair[0] + "=" + pair[1] + "%26%26" + newValue; 
//             newQuery = newQuery + "&" + newFacetWithANDs;

//             // if its a pure facet listing with no search string, we need to also ensure the ampersand isn't left at the start
//             if (!fullQuery.includes("sText")) {
//                 newQuery = newQuery.replace(/^&/, "");
//             }
            
//         }
//     }
//     return newQuery;
// }