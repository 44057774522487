import { createSlice } from "@reduxjs/toolkit";

//This is for the dropdown skip link to be turned off from multiple components/pages
// NOTE: can't just use onBlur because it messes with link clicks 
export const skipSlice = createSlice({
    name: "skip",
    initialState: {
        showSkip: false,
    },
    reducers: {
        setShowSkip: (state, action) => {
            state.showSkip = action.payload;
        },
    },
});

export const { setShowSkip } = skipSlice.actions;

export default skipSlice.reducer;