import SeoHeader from "./SeoHeader";
import { useForm } from "react-hook-form";
import { useParams } from 'react-router-dom';
import React, { useEffect } from "react";
import { setLoading } from '../redux/loaderSlice';
import { useDispatch, useSelector } from 'react-redux';
import { setRecaptchaLoaded } from '../redux/recaptchaSlice';
import { Link } from 'react-router-dom';
//import Credit from './SearchResults/SearchItem/Credit';
import Year from './SearchResults/SearchItem/Year';
import "../assets/css/Form.css";
import "../assets/css/RequestForm.css";
import countries from "../assets/countries.js";
import googleTagPush from "../helpers/googleTagPush";
import useClearTabIndex from "../hooks/useClearTabIndex";

export default function RequestForm() {

    const dispatch = useDispatch();

    const isRecaptchaLoaded = useSelector((state) => {
        return state.recaptcha.recaptchaLoaded;
    });

    const [results, setResults] = React.useState(null);
    const [error, setError] = React.useState(null);
    const [submitError, setSubmitError] = React.useState(null);
    const [submitting, setSubmitting] = React.useState(false);
    const [submitted, setSubmitted] = React.useState(false);
    const [refNumber, setRefNumber] = React.useState(null);
    const [selectedCountry, setSelectedCountry] = React.useState("Australia");

    const { ids } = useParams();

    useClearTabIndex();

    /*
        accessibleRecaptcha: triggers on recaptcha load and updates the google forms to be accessible.
        window.accessibleRecaptcha puts the function in global namespace for recaptcha script to find it.
    */
    window.accessibleRecaptcha = function() { //triggers on recaptcha load and updates the google forms to be accessible

        //text areas dont have aria labels
        var recaptchaTextAreas = document.getElementsByName("g-recaptcha-response");
        recaptchaTextAreas.forEach((e,i) => {
            //console.log(e);
            e.setAttribute("aria-label", "do not use");
            e.setAttribute("aria-readonly", "true");
        });

    }
    
    useEffect(() => { //we dynamically insert the recaptcha script.

        if (!isRecaptchaLoaded) { 
            const s = document.createElement('script');
            //NOTE: onload function accessibleRecaptcha triggers when recaptcha is ready
            s.setAttribute('src', 'https://www.google.com/recaptcha/api.js?render=6LdJUd0lAAAAAL9dKihpxkl2H0ye59iJpBKhMDVI&onload=accessibleRecaptcha'); 
            document.head.appendChild(s);
            dispatch(setRecaptchaLoaded(true)); //if we dont use 'isRecaptchaLoaded' the script is re-added when navigating back to this page.
            return (() => {
                s.remove();
            });
        }
    }, [isRecaptchaLoaded, dispatch]);

    useEffect(() => {
        async function fetchItems() {
            try {
                
                let getURL = `${process.env.REACT_APP_APIURL}title-list/${ids}`;
                //console.log("GetURL: " + getURL);
                let response = await fetch(getURL);
                let jsonData = "";
                if (response.ok) { 
                    jsonData = await response.json();
                    if (jsonData !== "") {
                        //console.log(jsonData);
                        dispatch(setLoading(false));
                        setResults(jsonData); 
                    }
                }

            } catch(error) {
                dispatch(setLoading(false));
                setError(error); 
            }
        }

        if (ids && ids.length > 0) { 
            dispatch(setLoading(true));
            setError(null); //in case previous error
            fetchItems();
        }
        
    }, [dispatch, ids]);

    async function submitData(data) {
        // console.log(data);
        // return;
        setSubmitting(true); 

        try {
            let recaptchaToken = "";

            await window.grecaptcha.ready(async function() { //WRAP CALL IN RECAPTCHA

                //first get recaptcha token to send with request
                try {
                    recaptchaToken = await window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {action: 'submit'});
                } catch(e) {
                    console.error(e);
                    setSubmitError("Recaptcha token failed");
                    setSubmitting(false); 
                    window.scrollTo(0, 0);
                    return; //bail
                }
                
                //add recaptcha token to request
                data['u_recaptcha_token'] = recaptchaToken;
                data['u_requested_titles'] = false;

                if (ids && ids.length > 0) {
                    //add list of ids to request 
                    //- need to get this from results array as the results array is what is edited when removing items, 
                    //not the router param ids
                    var itemIds = "";
                    results.forEach((e) => {
                        itemIds += e.id + ",";
                    });
                    data['u_requested_titles'] = itemIds.slice(0, -1);
                }
                

                //console.log(JSON.stringify(data));

                const response = await fetch(`${process.env.REACT_APP_APIURL}access-request`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                });

                const responseDetails = await response.json();

                if (responseDetails.error) {
                    setSubmitError(responseDetails.error);
                    window.scrollTo(0, 0);
                } else {
                    setSubmitted(true);
                    setSubmitError(null);
                    clearUrl();
                    googleTagPush('access_request_sent', {
                        'access_request_postcode': data['u_post_code'],
                        'access_request_street_address': data['u_street_address'],
                        'access_request_city': data['u_city'],
                        'access_request_client_type': data['u_client_type'],
                        'access_request_project_type': data['u_project_type'],
                    });
                }
                //console.log(responseDetails);
                if (responseDetails.requestReferenceNumber) {
                    setRefNumber(responseDetails.requestReferenceNumber);
                } else {
                    if (!responseDetails.error) {
                        setSubmitError("No reference number generated");
                        window.scrollTo(0, 0);
                    }
                }
                setSubmitting(false); 

            });
        }
        catch(e) {
            setSubmitError(e);
            setSubmitting(false); 
            console.log(e);
        }
    }

    function clearUrl() {
      window.history.pushState(null, '', './');
    }

    const { 
        register, 
        handleSubmit, 
        formState: {errors} 
    } = useForm({
        defaultValues: {
            u_first_name: "",
            u_last_name: "",
            u_organisation: "",
            u_role: "",
            u_street_address: "",
            u_city: "",
            u_state: "",
            u_post_code: "",
            u_country: "Australia",
            u_email: "",
            u_phone: "",
            u_client_type: "",
            u_project_type: "",
            u_request_description: "",
            u_deadline: "",
        }
    });

    let errorMsg = 'This field is required';

    function removeItem(index) {
        //console.log("remove item at: " + index);
        if (results) {
            results.splice(index, 1);
            setResults([...results]);
            //console.log("results now: " + results);

            //rebuild query string in address bar (in case page refresh)
            var queryString = "";
            results.forEach((e, i) => {
                queryString += e.id + ",";
            });
            window.history.pushState(null, '', queryString.slice(0, -1)); //put new string into the url bar
        }
        
    }

    if (error) return (
        <div className="request wrapper">
            <div className="content">
                <div>{ error.message }</div>
            </div>
        </div>
    );

    if (submitted && submitError === null && refNumber) return (
        <div className="request wrapper">
            <div className="content">

                <div className="confirmSuccess" id="requestSuccess"> 
                    <p><strong>Your access request has successfully been lodged.</strong></p>
                    
                    <p>Your reference number is {refNumber} </p>
                    
                    <p>Any issues? Please send to <a href="mailto:access@nfsa.gov.au">access@nfsa.gov.au</a></p>
                </div>
            </div>
        </div>
    );

    return (
        <div className="request wrapper simpleFade">
            <SeoHeader title={"Access Services Request"} metadesc={""} />

            <div className="content">
                { submitError && 
                    <div className="topError">There was a problem submitting your request. Please try again later or email <a href="mailto:access@nfsa.gov.au">access@nfsa.gov.au</a>
                    <br /> { console.log(submitError) }
                    <small>{ submitError.constructor === Array && submitError[0].replace(/"/g,"") }</small></div>
                }


                <div className="requestCols">
                    <div className="requestSide">
                        <h2 className="formTopRowTitle">
                            Access Services Request
                        </h2>     
                        
                        <h3 className="requestSideSubTitle">Timeframe for response</h3>
                        <p>Requests from the A/V industry, media, and cultural sectors, with clear deadlines, should receive a response within 5 working days.</p>
                        <p>For all other requests we aim to provide an initial response within 30 working days. </p>
                        <p><strong>Please use</strong> the <a href="/" target="_blank">NFSA catalogue</a> to search and request specific collection items.</p>
 
                        {/* <p>Requests from the A/V industry, media, cultural and education sectors, with clear deadlines, 
                                should receive a response within <strong>5 working days</strong>. </p>
  
                            <p>For all other clients we will aim to provide an initial response within <strong>30 working days</strong></p> */}
                        
                        { results && 
                            <h3>Request items <span> ({results.length})</span></h3>
                        }
                        <div className="requestItemsList">
                            {  
                                results && results.map((title, index) => {
                                    return (
                                        <div key={title.id} className="requestItemRow">
                                            <span className="number">{index + 1}.</span>
                                            <div className="requestItemLine">
                                                <Link className="requestItemLink" to={`/title/${title.id}`}>{ title.title }</Link>
                                                <Year title={title} />
                                                <div className="labelId">NFSA ID: {title.id}</div>
                                            </div>
                                            { results.length > 1 && //needs to be at least 2 titles to show remove option, cant have a form without an title.
                                                <button onClick={() => removeItem(index)} title="Remove this title" className="trashButton">
                                                </button>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>



                    </div>

                    
                    <div className="requestForm" id="contentStart">
                       
                       <div className="formHeader">
                            
                            <div className="formHeaderIcon">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                                </svg>
                            </div>

                            <div className="formInfo" role="alert">
                                <strong>Please note</strong> the NFSA does not supply copies or provide online access to collection items for 
                                personal interest and will not respond to such requests.
                            </div>

                       </div>

                        <form className={`generalForm ${submitting ? "submitting" : ""}`}
                            onSubmit={handleSubmit((data) => {
                                submitData(data);
                            })}
                        >

                            <fieldset aria-label="Access Request Form" className="formFields" disabled={submitting ? "disabled" : ""}>
                            <legend>Please fill in your details for your Access Request</legend>
                            <h3>Your details</h3>

                            <div className="formSection">
                                <label className="labelTitle required">
                                    Name
                                </label>

                                <div className="fieldGroup field2">
                                    <div className="formField">
                                        <label className="labelSub" htmlFor="u_first_name">First name</label>
                                        
                                        <input aria-label="First Name" {...register("u_first_name",  
                                        {
                                            required: errorMsg, 
                                            minLength: {
                                                value: 2,
                                                message: "Minimum length is 2"
                                            } 
                                        })} 
                                        placeholder="" id="u_first_name" type="text"/>

                                        { errors.u_first_name && <span className="formError">{errors.u_first_name?.message}</span> }
                                    </div>
                                    
                                    <div className="formField">
                                        <label className="labelSub" htmlFor="u_last_name">Last name</label>
                                        
                                        <input aria-label="Last Name" {...register("u_last_name",  
                                        {
                                            required: errorMsg, 
                                            minLength: {
                                                value: 2,
                                                message: "Minimum length is 2"
                                            } 
                                        })} 
                                        placeholder="" id="u_last_name" type="text"/>
                                        { errors.u_last_name && <span className="formError">{errors.u_last_name?.message}</span> }
                                        
                                    </div>
                                </div>
                            </div>
                            
                            <div className="formSection">
                                <label className="labelTitle" htmlFor="u_organisation">
                                    Organisation
                                    <span className="optFlag">optional</span>
                                </label>
                                <div className="fieldGroup">
                                    <div className="formField">
                                            <input {...register("u_organisation")} 
                                            placeholder="" id="u_organisation" type="text"/>
                                    </div>
                                </div>
                            </div>

                            <div className="formSection">
                                <label className="labelTitle" htmlFor="u_role">
                                    Role <span className="optFlag">optional</span>
                                </label>
                                <div className="fieldGroup">
                                    <div className="formField">
                                            <input {...register("u_role")} 
                                            placeholder="" id="u_role" type="text"/>
                                    </div>
                                </div>
                            </div>

                            <div className="formSection">

                                <label className="labelTitle required">
                                    Address
                                </label>
                                
                                <div className="fieldGroup field1">
                                    <div className="formField">
                                        <label className="labelSub" htmlFor="u_street_address">Street Address</label>
                                        
                                        <input aria-label="Street Address" {...register("u_street_address",  
                                        {
                                            required: errorMsg, 
                                            minLength: {
                                                value: 2,
                                                message: "Minimum length is 2"
                                            } 
                                        })} 
                                        placeholder="" id="u_street_address" type="text"/>
                                        { errors.u_city && <span className="formError">{errors.u_street_address?.message}</span> }
                                    </div>
                                </div>
                                <div className="fieldGroup field4">

                                    <div className="formField">
                                        <label className="labelSub" htmlFor="u_city">City/Suburb</label>
                                        
                                        <input aria-label="City" {...register("u_city",  
                                        {
                                            required: errorMsg, 
                                            minLength: {
                                                value: 2,
                                                message: "Minimum length is 2"
                                            } 
                                        })} 
                                        placeholder="" id="u_city" type="text"/>
                                        { errors.u_city && <span className="formError">{errors.u_city?.message}</span> }
                                    </div>

                                    <div className="formField">
                                        <label className="labelSub" htmlFor="u_state">State</label>
                                        { selectedCountry !== 'Australia' && 
                                            <>
                                                <input aria-label="State" {...register("u_state",  
                                                {
                                                    required: errorMsg, 
                                                })} 
                                                placeholder="" id="u_state" type="text"/>
                                                { errors.u_state && <span className="formError">{errors.u_state?.message}</span>}
                                            </>
                                        }

                                        { selectedCountry === 'Australia' && 
                                            <>
                                                <select aria-label="State" {...register("u_state",
                                                    {
                                                        required: errorMsg, 
                                                    })}
                                                    id="u_state">
                                                    <option value="ACT">ACT</option>
                                                    <option value="NSW">NSW</option>
                                                    <option value="NT">NT</option>
                                                    <option value="QLD">QLD</option>
                                                    <option value="SA">SA</option>
                                                    <option value="TAS">TAS</option>
                                                    <option value="VIC">VIC</option>
                                                    <option value="WA">WA</option>
                                                </select>
                                                { errors.u_state && <span className="formError">{errors.u_state?.message}</span>}
                                            </>
                                        }

                                    </div>

                                    <div className="formField">
                                        <label className="labelSub" htmlFor="u_post_code">Postal/Zip code</label>
                                        <input aria-label="Post Code" {...register("u_post_code")}
                                        placeholder="" id="u_post_code" type="text"/>
                                    </div>

                                    <div className="formField">
                                        <label className="labelSub" htmlFor="u_country">Country</label>
                                        
                                        <select defaultValue="Australia" aria-label="Country" {...register("u_country",
                                        {
                                            required: errorMsg, 
                                            onChange: (e) => {setSelectedCountry(e.target.value)},
                                        })}     
                                    
                                        id="u_country">
                                            {
                                                countries.map((country, i) => {
                                                    return (
                                                        // <option key={country} selected={`${country === 'Australia' ? 'selected' : ''}`}>{country}</option>
                                                          <option key={country} value={country}>{country}</option>
                                                       // <option key={country}>{country}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                        { errors.u_country && <span className="formError">{errors.u_country?.message}</span>}
                                        
                                    </div>

                                </div>

                            </div>

                            <div className="formSection">
                                <label className="labelTitle required" htmlFor="u_email">
                                    Email address
                                </label>
                                <div className="fieldGroup">
                                    <div className="formField">
                                            <input aria-label="Email Address" {...register("u_email",
                                            {
                                                required: errorMsg, 
                                                minLength: {
                                                    value: 2,
                                                    message: "Minimum length is 2"
                                                } 
                                            })}
                                            placeholder="" id="u_email" type="email"/>
                                            { errors.u_email && <span className="formError">{errors.u_email?.message}</span> }
                                    </div>
                                </div>
                            </div>

                            <div className="formSection">
                                <label className="labelTitle required" htmlFor="u_phone">
                                    Phone number
                                </label>
                                <div className="fieldGroup">
                                    <div className="formField">
                                            <input aria-label="Phone Number" {...register("u_phone",
                                            {
                                                required: errorMsg, 
                                                minLength: {
                                                    value: 9,
                                                    message: "Minimum length is 9"
                                                } 
                                            })}
                                            placeholder="" id="u_phone" type="phone"/>
                                            { errors.u_phone && <span className="formError">{errors.u_phone?.message}</span> }
                                    </div>
                                </div>
                            </div>

                            <div className="formSection">
                                <label className="labelTitle required" htmlFor="u_client_type">
                                    Client type
                                </label>
                                <div className="fieldGroup">
                                    <div className="formField">
                                        <select aria-label="Client Type" {...register("u_client_type",
                                        {
                                            required: errorMsg, 
                                        })}     
                                    
                                        id="u_client_type">
                                            <option value="">Please Select</option>
                                            <option value="Academic">Academic</option>
                                            <option value="Audiovisual industry">Audiovisual industry</option>
                                            <option value="Charity">Charity</option>
                                            <option value="Community">Community</option>
                                            <option value="Corporate">Corporate</option>
                                            <option value="Cultural">Cultural</option>
                                            <option value="Education">Education</option>
                                            <option value="Individual">Individual</option>
                                            <option value="Government">Government</option>
                                            <option value="Media">Media</option>
                                        </select>
                                        { errors.u_client_type && <span className="formError">{errors.u_client_type?.message}</span> }
                                    </div>
                                </div>
                            </div>

                            <h3>Project details</h3>

                            <div className="formSection">
                                <label className="labelTitle required" htmlFor="u_project_type">
                                    Project type
                                </label>
                                <div className="fieldGroup">
                                    <div className="formField">
                                        <select aria-label="Project Type" id="u_project_type" {...register("u_project_type",
                                        {
                                            required: errorMsg, 
                                        })}>
                                            <option value="">Please Select</option>
                                            <option value="Distribution">Distribution </option>
                                            <option value="Documentary film">Documentary film </option>
                                            <option value="Exhibition - online or physical">Exhibition - online or physical </option>
                                            <option value="Feature film"> Feature film </option>
                                            <option value="Publication - digital or print">Publication - digital or print </option>
                                            <option value="Research">Research </option>
                                            <option value="Radio or podcast">Radio or podcast </option>
                                            <option value="Screening">Screening </option>
                                            <option value="Short film">Short film </option>
                                            <option value="Television">Television </option>
                                        </select>
                                        { errors.u_project_type && <span className="formError">{errors.u_project_type?.message}</span> }
                                    </div>

                                </div>
                            </div>
                            
                            <div className="formSection">
                                <label className="labelTitle required" htmlFor="u_request_description">
                                    Reason for your request
                                </label>
                                <div className="fieldGroup">
                                    <div className="formField">
                                        <label className="labelSub">
                                        e.g. exhibition, film, production
                                        </label>
                                        <textarea aria-label="Reason for your request" id="u_request_description" {...register("u_request_description", {
                                            required: errorMsg, 
                                        })}></textarea>
                                        { errors.u_request_description && <span className="formError">{errors.u_request_description?.message}</span> }
                                    
                                    </div>
                                </div>
                            </div>

                            <div className="formSection">
                                <label className="labelTitle required" htmlFor="u_deadline">
                                    What is your deadline?
                                </label>
                                <div className="fieldGroup">
                                    <div className="formField">
                                        <label className="labelSub">
                                        
                                        We will advise you if we can meet your deadline.
                                            
                                        </label>
                                        
                                        <input aria-label="Deadline" {...register("u_deadline", {
                                            required: errorMsg, 
                                        })} type="date" id="u_deadline"/>
                                        { errors.u_deadline && <span className="formError">{errors.u_deadline?.message}</span> }
                                    </div>
                                </div>
                            </div>
                            
                            <div className="formSection">
                                <div className="buttonBar">
                                    <button type="submit">Submit</button>
                                </div>
                            </div>
                            </fieldset>
                        </form>
                        <div className="requiredDesc"><strong>*</strong> Indicates required field</div>
                    </div>
                    

                </div>
            </div>
            
        </div>
    );

}