//import { Link } from 'react-router-dom';
import SeoHeader from "./SeoHeader";
import useClearTabIndex from "../hooks/useClearTabIndex";
export default function About() {

    useClearTabIndex();

    return (
        <>
        <SeoHeader title={"About"} metadesc={"National Film and Sound Archive, Search the Collection, About page"} />
        <div className="about wrapper simpleFade">
            
            <div className="content cms" id="contentStart">

                <h2>About</h2>

                <h3>The Catalogue</h3>

                <p>This catalogue enables users to search the collection of the National Film and Sound Archive (NFSA).</p>

                <p>Requests to access and use items in the collection are facilitated by the Access Services team.</p>

                <p>Detail about our service is available at <a href="https://www.nfsa.gov.au/collection/using-collection/using-collection" rel="noreferrer" target="_blank">Using the Collection.</a></p>

                <h3>Access Services</h3>

                <p>The Access Services team assists professionals working in a broad range of sectors including the audiovisual production industry, media and broadcast, cultural organisations, education, government, corporate, researchers and students.
                </p>

                <p>We are unable to service requests for personal copies. Our services for personal requests are limited to onsite viewing at the <a href="https://www.nfsa.gov.au/collection/using-collection/access-centres" rel="noreferrer" target="_blank">NFSA or Access Centres.</a>
                </p>

                <h3>Timeframe for Responses</h3>

                <p>The Access team prioritises requests from the audio-visual industry, media, cultural, and education sectors with clear deadlines, and we will respond to these requests within 5 working days. For urgent requests we aim to respond the same day the request is received.
                </p>
                <p>For all other requests we will provide an initial response within 10-15 working days.
                </p>

                <h3>Copyright</h3>

                <p>The majority of the NFSA collection is in copyright, with rights owned by parties outside the NFSA.</p>

                <p>The supply of copies from the NFSA collection is subject to the Copyright Act (1968).</p>

                <p>Further information about the NFSA's obligations is available at <a href="https://www.nfsa.gov.au/collection/using-collection/copyright" rel="noreferrer" target="_blank">Collection Ownership and Copyright.</a></p>

                <h3>Indigenous Cultural &amp; Intellectual Property</h3>

                <p>The supply of collection material containing images or sound of Aboriginal and Torres 
                    Strait Islander peoples is subject to the NFSA's <a href="https://www.nfsa.gov.au/conditions-use#ICIP" rel="noreferrer" target="_blank">Indigenous Cultural & Intellectual Property protocol.</a></p>

                <p>Clients may be required to obtain permission from relevant
                     First Nations communities before copies of collection material will be supplied.</p>

                <h3>Fees</h3>

                <p>The Access Services team charge fees for our services.</p>

                <p>This includes administration, research, digitisation, supply of copies, and the loan of collection items. Fees for the use of collection material owned by the NFSA may apply.
                </p>

                <p>Details about our fees are available at <a href="https://www.nfsa.gov.au/collection/using-collection/access-fees" rel="noreferrer" target="_blank">Using the Collection.</a>
                </p>

                <h3>Access Questions</h3>

                <p>Questions about accessing the NFSA collection can be made using our <a target="_blank" rel="noreferrer" href="/request-form">Access Enquiries Form</a>.</p>
                
                    
            </div>
        </div>
        </>
    );

}