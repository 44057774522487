import { NavLink } from 'react-router-dom';
import "../assets/css/NavBar.css";
//import React, { useEffect } from "react";
//import {useNavigate} from 'react-router-dom';
import { useSelector } from 'react-redux';
import React from "react";
export default function NavBar() {

    

    const { items } = useSelector((state) => {
        return state.list;
    });

    return (
        <>
            
            

            <nav className="menu">
                
                <ul>
                    <li className="menu-item">
                        <NavLink to="/about">About</NavLink>
                    </li>
                    <li className="menu-item">
                        <NavLink to="/help">Help</NavLink>
                    </li>
                    <li className="menu-item">
                        <NavLink className="nav-list" to="/list">Your List
                            { items.length > 0 ? 
                                <span>{items.length}</span> : null
                            }
                            {/* <span>{`${ items.length > 0 ? ` ${items.length}` : '' }`}</span> */}
                        
                        </NavLink>
                    </li>
                </ul>
            </nav>
            
        </>
    );

}