//import React, { useEffect, useCallback } from "react";
import React, { useEffect } from "react";
import generateIconClass from "../../helpers/generateIconClass";

export default function TitleRelations({titleRelations, relatedHeading}) {


	const maxItemsPerFetch = 9;

	const [loadedItemsOffset, setLoadedItemsOffset] = React.useState(0);

	let hasListIndex = titleRelations.some(obj => obj.hasOwnProperty('listIndex')); //some relationship arrays dont contain listindex to sort on

	const [sortedTitleRelations] = React.useState(() => { //sortedTitleRelations are the sorted version of titleRelations

		if (hasListIndex) {
			let sortedItems = titleRelations.toSorted((a, b) => {
				return a.listIndex - b.listIndex;
			});

			//console.log(relatedHeading + " " + sortedItems.length);
			return sortedItems;
		} else {
			//console.log(relatedHeading + " " + titleRelations.length);
			return titleRelations;
		}
		
	});

    const setCommaListUpdated = () => {

		let requestIdString = "";

		for (let i = loadedItemsOffset; i < sortedTitleRelations.length; i++) {

			if (i < (loadedItemsOffset + maxItemsPerFetch)) {
				requestIdString += sortedTitleRelations[i].id + ",";
			}

		}
		requestIdString = requestIdString.substring(0, requestIdString.length - 1);
		//console.log("requestIdString: " + requestIdString);
		return requestIdString;
	}

	const [commaListedIds, setCommaListedIds] = React.useState(() => {
		return setCommaListUpdated()
	});
	
	const [loadedRelatedItems, setLoadedRelatedItems] = React.useState([]); //loadedRelatedItems are the items currently loaded and displaying (eg + more 9..etc)


	useEffect(() => {

		/*The AbortController cancels the fetch on unmount (see useEffect return function)
		this is here to stop multiple mounts adding/pushing to the  loadedRelatedItems array.
		This can happen in strict mode (all effects are run twice) or possibly with the back
		button clicked.
		*/
		const abortController = new AbortController(); 
		const signal = abortController.signal;

		async function getData() {

			let data = "";

			try {
				//console.log(relatedHeading + " - now fetching: " + commaListedIds);
				let getURL = `${process.env.REACT_APP_APIURL}title-list/${commaListedIds}`;
		        //console.log("GetURL: " + getURL);
		        let response = await fetch(getURL, { signal });

		        if (!signal.aborted) {
			        if (response.ok) { 
			            data = await response.json();
			            //console.log(relatedHeading);
						//console.log(data);
			        }
			    }

		    } catch(error) {
		    	if (!signal.aborted) {
		        	//console.log(error);
		        }
		    }

			if (!data) return;

			let newItems = [];

			if (hasListIndex) {
				//we need to apply the listIndex from the original titleRelations into the loaded items and them re-sort them.
				for (let i = 0; i < data.length; i++) {
					//find the matching item in the original titleRelations array
					const index = titleRelations.findIndex(e => e.id === data[i].id);
					data[i]['listIndex'] = titleRelations[index].listIndex;
				}

				//now listindex is applied we can sort based on that.
				newItems = data.toSorted((a, b) => {
		 			return a.listIndex - b.listIndex;
				});
			} else {
				newItems = data;
			}
			
			//console.log(relatedHeading);
			//console.log(newItems);

			//currentLoadedItems = loadedRelatedItems ..... linter gets angry we dare use loadedRelatedItems
			setLoadedRelatedItems(currentLoadedItems => { 
				const newLoadedRelatedItems = [...currentLoadedItems];
				newLoadedRelatedItems.push(...newItems);
				return newLoadedRelatedItems;
			});

			
			setLoadedItemsOffset(currentLoadedItemOffset => {
				return currentLoadedItemOffset + maxItemsPerFetch;
			})

		}

		getData();

		return () => {
	    	// Cancel the request when the component unmounts
	    	abortController.abort();
	  	};

	}, [commaListedIds, titleRelations, hasListIndex]);

	const showNextAmount = () => {
		setCommaListedIds(setCommaListUpdated());
	}

	return (
		<>
			{ loadedRelatedItems && loadedRelatedItems.length > 0 && 
	            <>
		            <h3 className="relatedTitlesTitle">{relatedHeading}
			            <span aria-label={`${titleRelations.length} related items`}>
			            {titleRelations.length}</span>
		            </h3>
		            
		            <ul className="relatedTitlesList">
		            	{ loadedRelatedItems.map((title, i) => {
		                        return (
		                            <li key={i} className={`titleName${generateIconClass('subMedium', title.subMedium)}`}>
		                                
		                                <a href={`/title/${title.id}`}>
		                                	{title.title }
		                                    {
		                                    	title.name && title.name !== 'TITLE VERSION' && <span> - {title.name}</span>
		                                	}
		                                </a>
		                            </li>
		                        )
		                    })
		                }

		                
		            </ul>
		            { (titleRelations.length > maxItemsPerFetch) && (loadedItemsOffset < titleRelations.length) &&
		                <div className="relatedBarShow">
		                	<button className="showAllButton" onClick={() => showNextAmount()}>
		                		<span></span>+ Show more
                        	</button>
		                </div>
		            }
		           
	            </>
        	}
        </>
    	
	);
}