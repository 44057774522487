import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
//import React, {useEffect} from "react";
import React from "react";
import { setShowFieldDrop } from '../redux/fieldDropSlice';
import "../assets/css/FieldSelectDrop.css";

export default function FieldSelectDrop({handleFieldCheck, selectedFields}) {

    const dispatch = useDispatch();

    let showFieldDrop = useSelector((state) => {
        return state.fieldDrop.showFieldDrop;
    });
    
    function handleDropDown() {
        dispatch(setShowFieldDrop(!showFieldDrop));
    }


    return (
        <div className={`fieldSelectDrop ${showFieldDrop ? "open" : ""}`} onClick={e => {e.stopPropagation()}}> 

            <button type="button" className={`fieldSelectDropButton${selectedFields.length > 0 ? ' selected' : ''}`} onClick={handleDropDown}>
                <span className='buttonText'>{selectedFields.length > 0 ? <span className='blueDot'></span> : ""}{selectedFields.length > 0 ? "Fields" : "All Fields"}</span>
            </button>

            <div className="fieldSelectDropdown">
                <div className="fieldSelectItems">
                    
                    <div className="fieldSelectItem">
                        <input id="fieldTitle" type="checkbox" value="title" onChange={e => handleFieldCheck(e)} checked={selectedFields.includes("title") ? "checked" : ""} />
                        <label htmlFor="fieldTitle">Title</label>
                    </div>
                    <div className="fieldSelectItem">
                        <input id="fieldSummary" type="checkbox" value="summary" onChange={e => handleFieldCheck(e)} checked={selectedFields.includes("summary") ? "checked" : ""} />
                        <label htmlFor="fieldSummary">Summary</label>
                    </div>
                    <div className="fieldSelectItem">
                        <input id="fieldPersonOrg" type="checkbox" value="credits.name" onChange={e => handleFieldCheck(e)} checked={selectedFields.includes("credits.name") ? "checked" : ""}/>
                        <label htmlFor="fieldPersonOrg">Person / Organisation</label>
                    </div>
                    <div className="fieldSelectItem">
                        <input id="fieldVersionId" type="checkbox" value="id" onChange={e => handleFieldCheck(e)} checked={selectedFields.includes("id") ? "checked" : ""}/>
                        <label htmlFor="fieldVersionId">NFSA ID</label>
                    </div>
                    <div className="fieldSelectItem">
                        <input id="fieldSeriesTitle" type="checkbox" value="parentTitle.seriesTitle" onChange={e => handleFieldCheck(e)} checked={selectedFields.includes("parentTitle.seriesTitle") ? "checked" : ""}/>
                        <label htmlFor="fieldSeriesTitle">Series Title</label>
                    </div>
                    <div className="fieldSelectItem">
                        <input id="fieldVersionName" type="checkbox" value="name" onChange={e => handleFieldCheck(e)} checked={selectedFields.includes("name") ? "checked" : ""}/>
                        <label htmlFor="fieldVersionName">Version Name</label>
                    </div>
                    <div className="fieldSelectItem">
                        <input id="fieldAltTitle" type="checkbox" value="alternativeTitles" onChange={e => handleFieldCheck(e)} checked={selectedFields.includes("alternativeTitles") ? "checked" : ""}/>
                        <label htmlFor="fieldAltTitle">Alternative Title</label>
                    </div>
                    <div className="fieldSelectItem">
                        <input id="fieldSeriesYear" type="checkbox" value="year" onChange={e => handleFieldCheck(e)} checked={selectedFields.includes("year") ? "checked" : ""}/>
                        <label htmlFor="fieldSeriesYear">Year</label>
                    </div>
                </div>
            </div>

    </div>


    );
}