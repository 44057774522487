import { createSlice } from "@reduxjs/toolkit";

export const loaderSlice = createSlice({
    name: "loader",
    initialState: {
        isLoading: false 
    },
    reducers: {
        toggleLoading: (state) => {
            state.isLoading = !state.isLoading;
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        }
    },
});

export const { toggleLoading, setLoading } = loaderSlice.actions;

export default loaderSlice.reducer;