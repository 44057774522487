import { useParams } from 'react-router-dom';
//import { Link } from 'react-router-dom';
//import { useLocation } from "react-router-dom";
import React, { useEffect } from "react";
//import HoldingModal from "./HoldingModal";
//import Modal from "../Modal";
import SeoHeader from "../SeoHeader";
import TitleDetails from "./TitleDetails";
import TitleSummary from './TitleSummary';
//import SimilarTitles from "./SimilarTitles";
import TitleCredits from "./TitleCredits";
import TitleRelations from "./TitleRelations";
// import Holdings from "./Holdings/Holdings";
import generateIconClass from '../../helpers/generateIconClass'
import MasonryHoldings from "./Holdings/MasonryHoldings";
import MasonryPreviews from "./Holdings/MasonryPreviews";

import "../../assets/css/Title.css";
import googleTagPush from '../../helpers/googleTagPush';
import { useDispatch, useSelector } from 'react-redux';
import { addItemToList, removeItemFromList } from '../../redux/listSlice';
import { setLoading } from '../../redux/loaderSlice';
import { setPopMessage } from '../../redux/popMessageSlice';
import useClearTabIndex from "../../hooks/useClearTabIndex";


export default function Item() {

    const { id } = useParams();

    useClearTabIndex();

    const [title, setTitle] = React.useState(null);
    const [error, setError] = React.useState(null);
   // const [showHoldingModal, setShowHoldingModal] = React.useState(false);
   //const [showCorrectionsForm, setShowCorrectionsForm] = React.useState(false);
    //const [currentHolding, setCurrentHolding] = React.useState(null);
    const [restricted, setRestricted] = React.useState(false); // set because we need to check all media/holding items for restricted status 
   

    const dispatch = useDispatch();
    const isInList = useSelector((state) => {
        if (title) {
            return state.list.items.includes(title.id);
        } else {
            return false;
        }
        
    });

    useEffect(() => {
        
        if (title) {
            if (title.restrictionStatus === "Restricted") {
                setRestricted(true);
            } else {
                if (title.media) {
                    let foundRestricted = title.media.find((media) => {
                        return media.restrictionStatus === "Restricted"
                    });
                    if(foundRestricted !== undefined) {
                        setRestricted(true);
                    }
                }
            }
        }
    }, [title, restricted]);

    useEffect(()=> {
        if (title) {
            googleTagPush("title_viewed", {
                "title_viewed_number" : title.id,
                "title_viewed_name" : title.title
            });
        }
    }, [title]);

    function onListClick(id, textTitle) {
        if (isInList) {
            dispatch(removeItemFromList(id));
            dispatch(setPopMessage(false));
        } else {
            googleTagPush("title_added_to_list", {
                "add_to_list_title_number" : id,
                "add_to_list_title" : textTitle
            });
            dispatch(addItemToList(id));
            dispatch(setPopMessage(true));
        }
    }

    useEffect(() => {

        // async function fetchPreviews(jsonTitle) {
            
        //     let manifestUrl = "https://media.nfsacollection.net/" + jsonTitle.id + "/manifest.json";
        //     //let manifestUrl = "https://localhost/" + jsonTitle.id + "/manifest.json";
        //     let response = null;
        //     try {
        //         response = await fetch(manifestUrl); //if not found this will always throw a 404 error in the console.

        //         if (response.ok) {
        //             let manifestJson = await response.json();
                    
        //             //manifest file will contain a path with the holding id
        //             jsonTitle.media.forEach((holding, j) => {
    
        //                 manifestJson.every((mediaPreview, i) => {
        //                     if (mediaPreview.file_path.includes(holding.id)) {
    
        //                         let previewType = "";
        //                         let previewPath = "";
                                
        //                         if (mediaPreview.file_path.includes("video")) { 
        //                             previewType = "video";
        //                             previewPath = mediaPreview.file_path;
        //                             jsonTitle.media[j]['previewPath'] = previewPath;
        //                             jsonTitle.media[j]['previewType'] = previewType;
        //                             jsonTitle.media[j]['previewExt'] = previewPath.split('.').pop();
        //                             jsonTitle.media[j]['previewSize'] = mediaPreview.size;
        //                             return false;
        //                         } else if (mediaPreview.file_path.includes("audio")) {
        //                             previewType = "audio";
        //                             previewPath = mediaPreview.file_path;
        //                             jsonTitle.media[j]['previewPath'] = previewPath;
        //                             jsonTitle.media[j]['previewType'] = previewType;
        //                             jsonTitle.media[j]['previewExt'] = previewPath.split('.').pop();
        //                             jsonTitle.media[j]['previewSize'] = mediaPreview.size;
        //                             return false;
        //                         } else if (mediaPreview.file_path.includes("image")) {
                                    
        //                             previewType = "image";
        //                             previewPath = mediaPreview.file_path;
        //                             jsonTitle.media[j]['previewPath'] = previewPath;
        //                             jsonTitle.media[j]['previewType'] = previewType;
        //                             jsonTitle.media[j]['previewExt'] = previewPath.split('.').pop();
        //                             jsonTitle.media[j]['previewSize'] = mediaPreview.size;
        //                             return false;
        //                         } else if (mediaPreview.file_path.includes("file")) {
        //                             previewType = "file"; //always pdf?
        //                             previewPath = mediaPreview.file_path;
        //                             jsonTitle.media[j]['previewPath'] = previewPath;
        //                             jsonTitle.media[j]['previewType'] = previewType;
        //                             jsonTitle.media[j]['previewExt'] = previewPath.split('.').pop();
        //                             jsonTitle.media[j]['previewSize'] = mediaPreview.size;
        //                             return false;
        //                         }
        //                     }
        //                     return true;
        //                 });
        //             });
    
                    
        //         }


        //     } catch (e) {
        //         //console.log("Failed to fetch manifest");
        //         //console.log(e);
        //     }
            
        //     return jsonTitle;
        // }

        async function fetchTitle() { 
            try {

                let getURL = `${process.env.REACT_APP_APIURL}title/${id}`;
                //console.log("GetURL: " + getURL);

                let response = await fetch(getURL);
                let jsonData = "";

                if (response.ok) { // if HTTP-status is 200-299
                    // get the response body (the method explained below)
                    jsonData = await response.json();

                    // console.log(response);
                    if (jsonData !== "") {
                       //console.log(jsonData);

                        if (jsonData.type !== "api") { //api means error i guess?
                            //let titleWithPreviews = await fetchPreviews(jsonData); //first fill out preview path data
                            //setTitle(titleWithPreviews);
                            setTitle(jsonData);
                            dispatch(setLoading(false));
                        } else {    
                            throw new Error("ID #" + id + " - " + jsonData.message);
                        }
                    } else {
                        console.log("No data returned: " + JSON.stringify(jsonData));
                        throw new Error("No information found for record: " + id);
                    }
                } else {
                    let err = await response.json();
                    console.log(err);
                    throw new Error(err.error.replace(/"/g,"") + " for record: " + id); //seems to return the string with quotes around it?
                }
            } catch(error) {
                dispatch(setLoading(false));
                setError(error);
            }
        }

        if (id !== "") {
            setError(null);
            dispatch(setLoading(true));
            fetchTitle();
        }
        
    }, [id, dispatch]);

    // function onHoldingClick(h) {
    //     setCurrentHolding(h);
    //     setShowHoldingModal(!showHoldingModal);
    // }

    function processSeoTitle(title) {
        //append version title to seo title
        if (title.name && title.name !== 'TITLE VERSION') {
            return title.title + " - " + title.name;
        } else {
            return title.title;
        }
    }

    if (error) return (
        <div className="title wrapper">
            <div className="content">
                <div className='topError'>{ error.message }</div>
            </div>
        </div>
    );

    if (!title) return null;

    return (
        
        <div className="title wrapper simpleFade">

            { title !== null && <SeoHeader title={processSeoTitle(title)} metadesc={title.summary} />}
            <div className="content" id="contentStart">

                <div className='titleHeader'>
                    <h2>
                        <span className={`titleName${generateIconClass('subMedium', title.subMedium)}`}>{ title.title }</span>
                    </h2>

                    { title.name && title.name !== 'TITLE VERSION' &&
                            <span className="titleVersionTitle">{ title.name }</span>
                        }
                    <div className="titleButtons" aria-live="polite">

                        { restricted && <span className="restricted"><span>Restrictions may apply</span></span> }
                        
                        <div className='titleButton' aria-live="assertive">
                        { isInList ? 
                            <button aria-label="Remove this result from your list?" className="addListButton listed" onClick={() => onListClick(title.id, title.title)}>
                                <span>Remove</span>
                            </button>
                            : 
                            <button aria-label="Add this result to your list?" className="addListButton" onClick={() => onListClick(title.id, title.title)}>
                                <span>Add</span>
                            </button>
                            } 
                        </div>
                    </div>

                </div>

                <div className="titleCols">
                    <div className="mainCol">
                        <TitleSummary title={title} />
                        {/* <TitleCredits title={title} /> */}
                    </div>
                    
                    <div className='sideCol'>
                        <TitleDetails title={title} />
                        <div className='correctionsBar'>
                            If you find any errors in this information please let us know at <a href={`mailto:corrections@nfsa.gov.au?subject=Data Correction for Title ${title.id}`}>corrections@nfsa.gov.au</a>
                        </div>
                    </div>

                   <TitleCredits title={title} />

                </div>
                    
                { title.hasMedia === 'yes' && <MasonryPreviews title={title}/> }
                { title.media && title.media.length > 0 && <MasonryHoldings title={title}/> }
                    
                
                { (title.relatedTitles !== null || title.containedIn !== null || title.contains !== null) && 
                    <div className="relatedTitles">
                        
                        {/* title.contains && title.contains.length > 0 &&
                            <>
                                <div className="relatedTitlesTitle">This title contains <span aria-label={`Title contains ${title.contains.length}items`}>{title.contains.length}</span> </div>
                                <ul className='relatedTitlesList'>
                                    { title.contains.map((title, i) => {
                                            return (
                                                <li key={i} className={`titleName${generateIconClass('subMedium', title.subMedium)}`}>
                                                    <Link to={`/title/${title.id}`}>
                                                        {title.title}
                                                    </Link>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </>
                        */}

                       { title.contains && title.contains.length > 0 && <TitleRelations titleRelations={title.contains} relatedHeading={"This title contains"} /> }

                        {/* title.relatedTitles && title.relatedTitles.length > 0 &&
                            <>
                                <div className="relatedTitlesTitle">Related Titles <span aria-label={`${title.relatedTitles.length} related items`}>{title.relatedTitles.length}</span></div>
                                <ul className='relatedTitlesList'>
                                    { title.relatedTitles.map((title, i) => {
                                            return (
                                                <li key={i} className={`titleName${generateIconClass('subMedium', title.subMedium)}`}>
                                                    <Link to={`/title/${title.id}`}>
                                                        {title.title}
                                                    </Link>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </>
                        */}

                         { title.relatedTitles && title.relatedTitles.length > 0 && <TitleRelations titleRelations={title.relatedTitles} relatedHeading={"Related Titles"} /> }

                        {/* title.containedIn && title.containedIn.length > 0 &&
                            <>
                                <div className="relatedTitlesTitle">Contained In <span aria-label={`Contained in ${title.containedIn.length} items`}>{title.containedIn.length}</span></div>
                                <ul className='relatedTitlesList'>
                                    { title.containedIn.map((title, i) => {
                                                return (
                                                    <li key={i} className={`titleName${generateIconClass('subMedium', title.subMedium)}`}>
                                                        <Link to={`/title/${title.id}`}>
                                                            {title.title ? title.title : title.id}
                                                        </Link>
                                                    </li>
                                                )
                                            })
                                        
                                    }
                                </ul>
                            </>
                        */}

                       { title.containedIn && title.containedIn.length > 0 && <TitleRelations titleRelations={title.containedIn} relatedHeading={"Contained In"} /> }

                    </div>
                }

            </div>
        </div>
        
    );
}