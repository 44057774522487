import { useDispatch } from 'react-redux';
import { useEffect } from "react";
import { setShowSkip } from '../redux/skipSlice';

/* Function resets focus status to a normal initial page load, so each new page resets tab index to the top */

const useClearTabIndex = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        //console.log("resetTabIndex");
        dispatch(setShowSkip(false));
        document.activeElement?.blur && document.activeElement.blur();
        document.body.tabIndex = 0;
        document.body.focus();
    });
}

export default useClearTabIndex;