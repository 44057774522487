import "../../assets/css/SearchResults.css";
import SeoHeader from "../SeoHeader";
import React, { useCallback, useEffect } from "react";
import SearchItem from "./SearchItem/SearchItem";
import SearchSidebar from "./SearchSideBar/SearchSidebar";
import Paginator from "../Paginator";
import { Link } from 'react-router-dom';
import getQueryValue from "../../helpers/getQueryValue";
import getSearchFromRoute from "../../helpers/getSearchFromRoute";
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../redux/loaderSlice';
import { setSearchString, setSearchFields } from '../../redux/searchSlice';
import SortControl from "./SortControl";
import { setShowFilterMenu } from "../../redux/filterMenuSlice";
import { useParams } from 'react-router-dom';
import createFacetToggleUrl from "../../helpers/createFacetToggleUrl";
import isFacetValueQuoted from "../../helpers/isFacetValueQuoted";
import formatCreditName from "../../helpers/formatCreditName";
import useClearTabIndex from "../../hooks/useClearTabIndex";
import { setSearchFacets } from '../../redux/searchSlice';
import FilterButton from "../FilterButton";
import BurgerHeader from "../BurgerHeader";

export default function SearchResults() {
    const dispatch = useDispatch();

    useClearTabIndex();

    const { sParams = "" } = useParams(); //this needs to remain in place to ensure that the component re-renders when the url changes.

    const isLoading = useSelector((state) => {
        return state.loader.isLoading;
    });

    const searchFacets = useSelector((state) => {
        return state.search.searchFacets;
    });

    const showMobileControls = window.matchMedia("(max-width: 1020px)").matches;
    
    let page = getQueryValue("page");
    if (page === "") page = 1;
    
    let searchText = getQueryValue("query");
    if (!searchText) searchText = getQueryValue("queryExact");


    const [results, setResults] = React.useState(null);
    const [facetCounts, setFacetCounts] = React.useState(null);
    const [total, setTotal] = React.useState(0);
    const [error, setError] = React.useState(null);
    const [isNoResults, setNoResults]  = React.useState(false);
    const [creditSearchObj, setCreditSearchObj] = React.useState({});

    let searchFields = useSelector((state) => {
        return state.search.searchFields
    });

    let showFilters = useSelector((state) => {
        return state.filterMenu.showFilterMenu
    });

    const getAppliedFacets = useCallback(() => {
        let facets = [];
        let urlSearch = getSearchFromRoute("/search/");
        var vars = urlSearch.split('&');
        for (var i = 0; i < vars.length; i++) {
            
            var pair = vars[i].split('=');
            if (pair[0] === 'query' 
            || pair[0] === 'page' 
            || pair[0] === 'queryIn'
            || pair[0] === 'hasMedia'
            || pair[0] === 'queryExact'
            || pair[0] === 'queryExclude'
            || pair[0] === 'credits.role'
            || pair[0] === 'credits.id'
            || pair[0] === 'sort'
            ) continue;

            let facetName = pair[0];
            //check if there are OR'ed or AND'd symbols in the pair value
            let decodedFacet = decodeURIComponent(pair[1]);
            //console.log(facetName);
            if (pair[1] && pair[1].length > 1 && ( decodedFacet.includes(",") || decodedFacet.includes("|") )) {
                let andOrFacets = [];

                //don't split if quotes are around the decodeURIComponent(pair[1])
                if (!isFacetValueQuoted(decodedFacet) && decodedFacet.includes(",") && !decodedFacet.includes("|")) { //!decodedFacet.includes("|") because of OR facet values with commmas
                    andOrFacets = decodedFacet.split(",");
                } else {
                    andOrFacets = decodedFacet.split("|");
                }
                let facetObjects = andOrFacets.map((e,i) => {
                    let qs = createFacetToggleUrl(urlSearch, facetName, e);
                    //return {facetValue: e, url: qs};
                    return {facetName: facetName, facetValue: e, url: qs};
                });
                
                facets.push(...facetObjects);
            } else if (pair[0] === 'queryIn') { //acount for queryIn strings
                let newfacetValue = decodedFacet;//decodeURIComponent(pair[1]);
                //let queryString = createFacetToggleUrl(urlSearch, facetName, decodeURIComponent(pair[1])); //NOTE: always decode before passing to createFacetToggleUrl()
                let queryString = createFacetToggleUrl(urlSearch, facetName, decodedFacet);
                facets.push({facetName: facetName, facetValue: newfacetValue, url: queryString});
            }
            else {
                //let queryString = createFacetToggleUrl(urlSearch, facetName, decodeURIComponent(pair[1])); //NOTE: always decode before passing to createFacetToggleUrl()
                let queryString = createFacetToggleUrl(urlSearch, facetName, decodedFacet); //NOTE: always decode before passing to createFacetToggleUrl()
                
                //facets.push({facetName: facetName, facetValue: decodeURIComponent(pair[1]), url: queryString});
                facets.push({facetName: facetName, facetValue: decodedFacet, url: queryString});
            }
        }
        
        //console.log(facets);

        dispatch(setSearchFacets([...facets])); //put selected facets into global state
    }, [dispatch]);

    useEffect(() => { 
        async function fetchSearchResults() {
            try {
                let urlString = getSearchFromRoute("/search/");
                let getURL = `${process.env.REACT_APP_APIURL}search?${urlString}`;
                //console.log("GetURL: " + getURL);

                let response = await fetch(getURL);
                //console.log(response);
                let jsonData = "";
                if (response.ok) { // if HTTP-status is 200-299
                    try {
                        jsonData = await response.json();
                    } catch(jsonError) { //catch empty body / no results
                        console.error(jsonError);
                    }
                } else {
                    throw new Error("API Error: " + response.status + " " + response.statusText);
                }

                if (jsonData !== "" && jsonData.results.length >= 1) {
                    //console.log(jsonData);
                    setError(null); //clear any old errors
                    setResults(jsonData.results);
                    setTotal(jsonData.meta.count.total);
                    setFacetCounts(jsonData.meta.facet);
                    dispatch(setLoading(false));
                    setNoResults(false);
                    setupCreditSearch(jsonData.results[0]);
                    getAppliedFacets();
                    
                } 
                else {
                    dispatch(setLoading(false));
                    setNoResults(true);
                }
            } catch(e) {
                dispatch(setLoading(false));
                console.error(e);
                setError(e);
            }
        }

        if (sParams !== "") {
            dispatch(setLoading(true));
            dispatch(setSearchString(searchText));
            if (!searchText) {
                dispatch(setSearchFields([]));
            }
            fetchSearchResults();
        }

    }, [sParams, page, searchText, dispatch, getAppliedFacets]);

    let setupCreditSearch = (topItem) => {
        let creditID = getQueryValue("credits.id");
        let creditRole = getQueryValue("credits.role");
        if (creditID === "" || creditRole === "") {
            setCreditSearchObj({});
        } else {
            let creditRow = topItem.credits.find((c) => {
                return c.id.toString() === creditID;
            });
            setCreditSearchObj({
                creditBio: creditRow.about.bio,
                creditDates: creditRow.about.dates,
                creditAltNames: creditRow.alternativeNames,
                creditRelatedNames: creditRow.relatedNames,
                creditSynonymNames: creditRow.synonymNames,
                //creditName: creditRow.name, 
                creditName: formatCreditName(creditRow),
                creditID: creditID, 
                creditRole: creditRole}
                )
        }
    }
    

    function closeFilter() {
        dispatch(setShowFilterMenu(false));
    }

    

    function convertPrettyField(field) {
        switch(field) {
            case "title": return "Title";
            case "summary": return "Summary";
            case "credits.name": return "Person/Organisation";
            case "alternativeTitles": return "Alternative Title";
            case "parentTitle.seriesTitle": return "Series Title";
            case "name": return "Version Name";
            case "id": return "NFSA ID";
            default: return field;
        }
    }

    function listTextFacets(appliedFacets) { //for SEO title
        let facetsString = "";
        appliedFacets.forEach((facet, i) => {
            facetsString += decodeURIComponent(facet.facetValue) + ", ";
        });
        return facetsString.slice(0, -2);
    }
    
    function processBioDates(strDates) { //wtf did i do this?
        return strDates;
    }

    if ((isNoResults && !isLoading) || error) return ( //errors sneakily managed as 'no results'
        <div className="searchResults wrapper">
            <div className="content">
                <div>
                    No Search results found for <strong><em>{ searchText }</em></strong>
                    
                        { searchFields && searchFields.length > 0 && 
                            <span>
                                &nbsp;within fields:&nbsp;
                                { searchFields.map((field, i) => {
                                        return (
                                            <em key={i} >
                                                {convertPrettyField(field)}{(i > (searchFields.length - 2)) ? "" : "," }
                                            </em>
                                        )
                                    })}
                            </span>
                        }
                    
                </div>

            </div>
        </div>
    );


    return (
        <>
        <div className="searchResults wrapper simpleFade">
            <div className="content">
            
            {/* { showMobileControls && showFilters && <BurgerHeader /> } */}
            <BurgerHeader />

                <div className="resultsCols">
                    <div 
                        className={`colSide ${showFilters ? 'open' : ''}`} 
                        onClick={() => closeFilter()} 
                        style={ (showFilters) ? {height: document.body.scrollHeight} : {}}
                        tabIndex="-1"> 
                        { facetCounts && <SearchSidebar facetCounts={facetCounts}/> }
                    </div>
                    
                    <div className="colResults">
                    
                        { !isLoading && 
                            <div className="topSearchResults">

                                <div className="topSearchSummary">
                                    { searchText && 
                                    <div className="searchTitle">
                                        <h2>Search results for <strong>{searchText}</strong></h2>
                                        <SeoHeader title={searchText} canonical={false} metadesc={"National Film and Sound Archive, Search the Collection, search results"} />
                                    </div> }

                                    { !searchText && searchFacets && Object.keys(creditSearchObj).length === 0 &&
                                        
                                        <div className="listingTitle searchTitle">
                                            <span>Showing all titles under:&nbsp;

                                                { searchFacets.map((facet, i) => {
                                                    return (
                                                        <strong key={i}>{decodeURIComponent(facet.facetValue)}</strong>
                                                    )
                                                }) }
                                            </span>
                                            <SeoHeader title={listTextFacets(searchFacets)} canonical={false} metadesc={"National Film and Sound Archive, Search the Collection, search results"} />
                                        </div> }
                                
                                    { Object.keys(creditSearchObj).length > 0 && 
                                        <div className="creditTitle">
                                            <span>Showing all titles under '<em>{creditSearchObj.creditName}</em>' with the role '<em>{creditSearchObj.creditRole}</em>'</span>
                                            <SeoHeader title={creditSearchObj.creditName} canonical={false} metadesc={"National Film and Sound Archive, Search the Collection, search results"} />
                                        </div>
                                    }
                                    
                                    <div className="searchSummary">
                                        { page > 1 ? 
                                            <span className="pager">showing {parseInt(page - 1) * 25}-{`${((page * 25) > total) ? total : (page * 25)}`} of {total} titles</span> : 
                                            <span className="pager">showing {page}-{`${(total < 25) ? total : (page * 25)}`} of {total} titles</span>
                                        }
                                    </div>
                                </div>
                                
                                <div className="topSearchSortControl">
                                    <SortControl />
                                </div>

                                { showMobileControls && <FilterButton /> }


                            </div>
                        }

                        { searchFields && searchFields.length > 0 && 
                            <div className="searchFields"> 
                                <div className="searchFieldsTitle"> Within fields </div> 
                                    <div className="searchFieldsList">
                                    { searchFields.map((field, i) => {
                                            return (
                                                <span key={i} className="searchFieldTag">*{ convertPrettyField(field) }</span>
                                            )
                                        })}
                                    </div>
                            </div>
                            
                        }

                        {/* {  appliedFacets && appliedFacets.length > 0 && */}
                        {  searchFacets && searchFacets.length > 0 &&
                            <>
                                <div className="searchFacetList">
                                    <div className="searchFacetListTitle">Filters applied ({searchFacets.length})</div>
                                    
                                    <div className="searchFacets">
                                       
                                        
                                         {/* if just doing pure facet listing, and its a single facet, you can't toggle off because there is nothing else to load */}
                                        { (searchFacets.length === 1 && !searchText && Object.keys(creditSearchObj).length === 0) ? 
                                            <span className="facetSearchTag noClick">{decodeURIComponent(searchFacets[0].facetValue.replaceAll("\"", ""))}</span>
                                            :
                                            searchFacets.map((facet, i) => {
                                                return (
                                                    <Link className="facetSearchTag" key={i} to={`/search/${facet.url}`}>{decodeURIComponent(facet.facetValue.replaceAll("\"", ""))}</Link>
                                                )
                                            }) 
                                        }
                                    </div>
                                </div>
                            </>
                        }

                        { creditSearchObj.creditBio && 
                            <div className="bioSnippet">
                                <strong>
                                    {creditSearchObj.creditName}
                                    { creditSearchObj.creditDates && <>&nbsp;({ processBioDates(creditSearchObj.creditDates) })</>}
                                </strong>
                                
                                <div className="bio"> 
                                    { creditSearchObj.creditAltNames && 
                                        <div className="bioRow">
                                            <strong>Alternative Names:</strong>
                                                <div className="bioList">
                                                { creditSearchObj.creditAltNames.map((alternativeName, i) => {
                                                    return (
                                                        <span key={i}>&nbsp;{alternativeName.name}</span>
                                                    )
                                                }) }
                                                </div>
                                        </div>
                                    }
                                    { creditSearchObj.creditSynonymNames && 
                                        <div className="bioRow">
                                            <strong>Synonyms:</strong>
                                                <div className="bioList">
                                                { creditSearchObj.creditSynonymNames.map((synonym, i) => {
                                                    return (
                                                        <span key={i}>&nbsp;{synonym.name}</span>
                                                    )
                                                }) }
                                                </div>
                                        </div>
                                    }
                                    { creditSearchObj.creditRelatedNames && 
                                        <div className="bioRow">
                                            <strong>Related:</strong>
                                                <div className="bioList">
                                                { creditSearchObj.creditRelatedNames.map((relName, i) => {
                                                    return (
                                                        <span key={i}>&nbsp;{relName.name}</span>
                                                    )
                                                }) }
                                                </div>
                                        </div>
                                    }
                                    <p>{creditSearchObj.creditBio}</p>
                                </div>
                            </div> 

                        }

                        <div className="searchItems" id="contentStart">
                            {  
                               results && results.map((title, index) => {
                                    return (
                                        <SearchItem key={title.id} title={title} />
                                    )
                                })
                            }
                        </div>
                        <Paginator current={page} total={total} route={"search"}/>

                    </div>
                    
                    
                </div>
            </div>
            
        </div>
        
        </>

        
    )
}