import React, { useEffect } from "react";

export default function TitleSummary({title}) {

    const [summaryCount, setSummaryCount] = React.useState(0); //create TOGGLE
    const [summaryText, setSummaryText] = React.useState("");
    const [topSummaryText, setTopSummaryText] = React.useState("");
    const [bottomSummaryText, setBottomSummaryText] = React.useState("");
    const [summaryShowFull, setSummaryShowFull] = React.useState(false);

    useEffect(()=> {
        if (title && title.summary) {
            let textCount = title.summary.split(" ").length;
            setSummaryCount(textCount);
            if (textCount > 150) {
                setTopSummaryText(title.summary.split(" ").slice(0, 149).join(" ")); //only show 150 words with "..." at the end
                setBottomSummaryText(title.summary.split(" ").slice(149).join(" ")); //show the rest after 150 words
            } else {
                setSummaryText(title.summary);
            }
            
        }
    }, [title]);

    function onFullSummary() {
        setSummaryShowFull(!summaryShowFull);
    }

    return (

        <div className="titleSummary">
            
            {summaryCount < 150 && summaryText}

            { summaryCount > 150 && 
                <>
                    <span className="topSummaryText">
                        {topSummaryText}{ !summaryShowFull ? "..." : " "}
                    </span>
                    { summaryShowFull && 
                        <span className="bottomSummaryText simpleFade">
                            {bottomSummaryText}
                        </span>
                    }
                    <button className='showMore' onClick={() => onFullSummary()}>
                        {summaryShowFull ? "Show less" : "Show more"}
                    </button>
                </>
            }

            { !title.summary && <span className='noSum'>No summary information available.</span>}

            {/* <br /><br />
            {title.summary} */}
        </div>

    );
}