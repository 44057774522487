

export default function HoldingsMoreButton({showAll, holdingItemsLength, filteredItemsLength, viewLimit, onShowAll}) {
    return (
        <>
            {filteredItemsLength !== null &&
            <>
                { !showAll && holdingItemsLength === filteredItemsLength && holdingItemsLength > viewLimit &&
                    <button className="showAllButton" onClick={() => onShowAll()}>
                        <span>+ {holdingItemsLength - viewLimit}</span> additional holdings
                    </button>
                }

                { 
                showAll && holdingItemsLength === filteredItemsLength && 
                    <button className="showAllButton remove" onClick={() => onShowAll()}>
                        Hide {holdingItemsLength - viewLimit} holdings
                    </button>
                }
            </>
            }


            {filteredItemsLength === null &&
                <>
                    { !showAll && holdingItemsLength > viewLimit &&
                        <button className="showAllButton" onClick={() => onShowAll()}>
                            <span>+ {holdingItemsLength - viewLimit}</span> additional holdings
                        </button>
                    }

                    { showAll && 
                        <button className="showAllButton remove" onClick={() => onShowAll()}>
                            Hide {holdingItemsLength - viewLimit} holdings
                        </button>
                    }
                </>
            }
        </>
    )
    
}