import "../../assets/css/SortControl.css";
import React from "react";
import { setShowSortDrop } from '../../redux/sortDropSlice';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import getSearchFromRoute from '../../helpers/getSearchFromRoute';
import getQueryValue from '../../helpers/getQueryValue';
import removeQueryValue from '../../helpers/removeQueryValue';

export default function SortControl() {

    const navigate = useNavigate();

    const dispatch = useDispatch();

    let showSortDrop = useSelector((state) => {
        return state.sortDrop.showSortDrop;
    });

    const [selectedSort, setSelectedSort] = React.useState("");

    function handleSortDrop() {
        dispatch(setShowSortDrop(!showSortDrop));
    }

    function handleSortCheck(e) {

        let urlString = getSearchFromRoute("/search/"); //get current search and facets etc

        if (e.target.value === '') { //back to default sorting - so strip 'sort' param completely and go back to default
            setSelectedSort('');
            urlString = removeQueryValue(urlString, 'sort');
            navigate("/search/" + urlString);
            return;
        }

        let currentUrlSortValue = getQueryValue('sort');
        
        if (currentUrlSortValue.length > 0) { //check if the sort query parameter is in use
            //console.log("replacing value")
            urlString = urlString.replace(currentUrlSortValue, e.target.value); //replace old with new
        } else {
            urlString = urlString + "&sort=" + encodeURIComponent(e.target.value); //if not set, append
        }
        
        setSelectedSort(e.target.value);
        navigate("/search/" + urlString);
    }

    React.useEffect(() =>{
        setSelectedSort(getQueryValue('sort'));
        dispatch(setShowSortDrop(false));
    }, [selectedSort, dispatch]);

    return (
        <div className={`SortControl ${showSortDrop ? 'open' : ''} ${((selectedSort === 'a-z') || (selectedSort === 'z-a')) ? 'highlight' : ''}`} onClick={e => {e.stopPropagation()}}>
            <button aria-label="View sorting options" className="SortControlDropButton" onClick={handleSortDrop}>
                {selectedSort === '' && 
                    <>Sorted by Relevance</>
                }
                {selectedSort === 'a-z' && 
                    <>Sorted by Alphabetical, A-Z</>
                }
                {selectedSort === 'z-a' && 
                    <>Sorted by Alphabetical, Z-A</>
                }
                 
            </button>
            { showSortDrop && 
                <div className="SortControlDrop simpleFade">
                    <label className={`${(selectedSort === '') ? 'selected' : ''}`}>
                        <span>Relevance</span>
                        <input value="" onChange={e => handleSortCheck(e)} type="radio" name="sortOrder" checked={`${(selectedSort === '') ? 'checked' : ''}`}/>
                    </label>
                    <label className={`${(selectedSort === 'a-z') ? 'selected' : ''}`}>
                        <span>Alphabetical, A-Z</span>
                        <input value="a-z" onChange={e => handleSortCheck(e)} type="radio" name="sortOrder" checked={`${(selectedSort === 'a-z') ? 'checked' : ''}`}/>
                    </label>
                    <label className={`${(selectedSort === 'z-a') ? 'selected' : ''}`}>
                        <span>Alphabetical, Z-A</span>
                        <input value="z-a" onChange={e => handleSortCheck(e)} type="radio" name="sortOrder" checked={`${(selectedSort === 'z-a') ? 'checked' : ''}`}/>
                    </label> 
                </div>
            }
        </div>
    )
}