import "../assets/css/SearchControlBox.css";
import SearchClear from "./SearchClear";
import FieldSelectDrop from "./FieldSelectDrop";
import React from "react";
// import FilterButton from "./FilterButton";

export default function SearchControlBox({handleSearchString, isLoading, searchString, clearText, handleFieldCheck, selectedFields}) {

    const [searchFocus, setSearchFocus] = React.useState(false);
    // const showMobileControls = window.matchMedia("(max-width: 1020px)").matches;

    return (
        <>
            <div className={`searchControlBox ${searchFocus ? "focused" : ""}`}>
                <input 
                onFocus={() => setSearchFocus(true)} 
                onBlur={() => setSearchFocus(false)}
                aria-label="Search Text" type="text" id="searchText" 
                disabled={isLoading ? "disabled" : ""} 
                value={searchString} 
                onChange={handleSearchString} 
                placeholder="I'm looking for..." />
                
                { searchString && 
                    <SearchClear clearText={clearText} />
                }
                {/* { !showMobileControls && 
                <FieldSelectDrop handleFieldCheck={handleFieldCheck} selectedFields={selectedFields}/> } */}
                <FieldSelectDrop handleFieldCheck={handleFieldCheck} selectedFields={selectedFields}/>
                
            </div>
            
            <button aria-label="Submit Search" className="seachSubmit" type="submit">
                <span>Submit</span>
            </button>
            {/* { showMobileControls && 
                <FieldSelectDrop handleFieldCheck={handleFieldCheck} selectedFields={selectedFields}/> } */}
            
            {/* { showMobileControls && <FilterButton /> } */}
        </>
    );
}