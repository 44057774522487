import { useEffect } from "react";
import { useLocation } from 'react-router-dom';

export default function SeoHeader({title, metadesc, canonical = true}) {

    let location = useLocation();

    useEffect(() => {
        //update title
        document.title = title + " - Search the Collection - National Film and Sound Archive";
        return () => { //clear title on unmount
            document.title = "Search the Collection - National Film and Sound Archive";
        }
    }, [title]);

    useEffect(() => {

        if (canonical) {

            //template canonical:
            // <link rel="canonical" href="https://example.com/dresses/green-dresses" />
            var canonicalUrl = document.createElement('link');
            canonicalUrl.rel = 'canonical';

            canonicalUrl.href = document.location.href;

            document.head.appendChild(canonicalUrl);

            return () => { //clear on unmount
                canonicalUrl.remove();
            }

        }
        
    });

    

    useEffect(() => {
        //<meta name="description" content="Author: A.N. Author, Illustrator: P. Picture, Category: Books, Price:  £9.24, Length: 784 pages">
        var metaTag = document.createElement('meta');
        metaTag.name = 'description';
        metaTag.content = metadesc;
        document.head.appendChild(metaTag);
        return () => {
            metaTag.remove();
        }
    }, [metadesc]);

    useEffect(() => {
        //<meta name="robots" content="noindex">
        if (location.pathname.includes('search')) {
            var metaRobots = document.createElement('meta');
            metaRobots.name = 'robots';
            metaRobots.content = 'noindex';

            document.head.appendChild(metaRobots);
            return () => {
                metaRobots.remove();
            
            }
        }

    });

    return null;
}