/*
Create a new queryString based off the data in the current string:
If facetName/FacetValue is present in current, then generate new queryString that either removes or replaces that value.
This creates a 'toggle' link for facet links: whatever is selected, get unselected etc.
This also account for OR values.
*/
import checkFullFacetInUse from "./checkFullFacetInUse";
import checkMultiFacetInUse from "./checkMultiFacetInUse";
import checkFacetInUse from "./checkFacetInUse";
//import replaceQueryValue from "./replaceQueryValue";
import removeQueryValue from "./removeQueryValue";
import removeANDORValue from "./removeANDORValue";
import insertANDValue from "./insertANDValue";
import isFacetValueQuoted from "./isFacetValueQuoted";
import wrapFacetValueCommas from "./wrapFacetValueCommas";

export default function createFacetToggleUrl(urlPath, facetName, facetValue) { //facetValue SHOULD NOT be encoded - decode that shit before using

    //before anything strip out the 'page' string in case of pagination
    if (urlPath.includes("page")) {
        urlPath = removeQueryValue(urlPath, "page"); 
    }
    
    //because commas are considered 'AND' queries we need to wrap facet values in quotes if they contain commas
    if (!isFacetValueQuoted(facetValue)) { //dont do it if already quoted
        
        // if (facetValue.includes(",") || facetValue.includes("%2C")) { 
        //     facetValue = `"${facetValue}"`;
        // }
        facetValue = wrapFacetValueCommas(facetValue);
    }

    /*need to run this AFTER adding quotes so the quotes get encoded as well.
     this is so when we compare the URLPATH with the facetvalue we can match on the exact encoded string. */
     // NOTE: DO NOT pass an already encoded string into this function
    facetValue = encodeURIComponent(facetValue); 


    let newQueryString = "";

    const fullFacetInUse = checkFullFacetInUse(urlPath, facetName, facetValue); //check if full [facetName][facetValue] is already in use
    const multiFacetInUse = checkMultiFacetInUse(urlPath, facetName, facetValue); //checks if facet in use that has multiple ORs and ANDS
    const facetInUse = checkFacetInUse(urlPath, facetName); //check if just [facetName] is already in use
    //console.log(facetValue + " fullFacetInUse: " + fullFacetInUse + " multiFacetInUse:" + multiFacetInUse + " facetInUse:" + facetInUse);
    /*
        TOGGLE ON/OFF facets

        1) multiFacetInUse:
        This is for OR'ed or AND strings
        It indicates that this particular facetName/Value is located with an OR/AND'ed string, eg
        subMedium=Film|Television
        subMedium=Film,Television
        We use to the check to build a new link that toggles off the value in the OR/AND'ed string

        2) fullFacetInUse:
        if full [variable=value] exists in the url, 
        then display a link that removes that 
        [variable=value] from the string but leaves the rest intact.
        This provides a link to toggle this facet off

        3) facetInUse: (facet in use WITHIN group)
        if facet [facetname] (eg genres) already exists in the url then append an extra facet to the end with an AND comma
    */
    
    if (multiFacetInUse) {
        newQueryString = removeANDORValue(urlPath, facetName, facetValue);
    } else if (fullFacetInUse) {
       // console.log("full facet in use for: " + facetValue);
        if (urlPath.length > 1 && urlPath.includes("query")) { 
            //console.log("remove query value: " + facetName + "=" + facetValue);
            //let encodedRemove = encodeURIComponent(facetName + "=" + facetValue);
            //console.log("removing &" + facetName + "=" + facetValue + " from: " + urlPath);
            
            newQueryString = urlPath.replace("&" + facetName + "=" + facetValue, ""); //remove facetName=FacetValue from string

            //console.log("newQueryString is now: " + newQueryString);
            //newQueryString = urlPath.replace("&" + facetName + "=" + encodeURIComponent(facetValue), "");
        } else { //if pure facet search there is no 'query' string
            newQueryString = removeQueryValue(urlPath, facetName);
        }
        
        newQueryString = newQueryString.replace("/", "%2F"); //manually remove any forward slash
    } else if (facetInUse) { //we now add an AND comma to the existing facet
        //console.log("adding AND comma for: " + facetValue);
        newQueryString = insertANDValue(urlPath, facetName, facetValue);
    } else { //facet doesn't exist, so just append the facet link to the end
        newQueryString = `${urlPath}&${facetName}=${facetValue}`;
    }
    
    return newQueryString;
}