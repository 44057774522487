
//function removes the param=value pair from the url.
//cant just do a simple .replace on the string because we don't always know the [value] part of the param=value
export default function removeQueryValue(urlPath, queryParam) {
    //console.log("REMOVE QUERY for urlPath=" + urlPath + " queryparam: " + queryParam);
    let newQuery = "";
    var vars = urlPath.split('&');
    for (var i = 0; i < vars.length; i++) {
        //console.log(i);
        var pair = vars[i].split('=');
        if (urlPath.length > 1 && urlPath.includes("sText")) { //if contains search string we can just replace values along with ampersand
            if (decodeURIComponent(pair[0]) === queryParam) {
                newQuery = urlPath.replace("&" + pair[0] + "=" + pair[1], "");
                return newQuery;
            }
        } else { //otherwise rebuild query without the queryParam to remove
            if (decodeURIComponent(pair[0]) === queryParam) continue;
            newQuery += `${pair[0]}=${pair[1]}&`;
        }
    }

    //strip any ampersands on the end
    newQuery = newQuery.replace(/&$/, "");
    //console.log("newQuery is now: " + newQuery);
    if (newQuery === "") return urlPath;
    return newQuery;
}