import "./assets/css/fontawesome.css";
import "./assets/css/Main.css";

import SearchResults from "./components/SearchResults/SearchResults";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React, { useEffect } from "react";
import Home from "./components/Home/Home";
import About from "./components/About";
import AllTitles from "./components/AllTitles/AllTitles";
import Help from "./components/Help";
import Title from "./components/Title/Title";
import List from "./components/List/List";
import Loader from "./components/Loader";
import RequestForm from "./components/RequestForm";
import ScrollToTop from "./components/ScrollToTop";
import { useDispatch, useSelector } from 'react-redux';
import { setListItems } from './redux/listSlice';
import { setShowFieldDrop } from './redux/fieldDropSlice';
import { setShowSortDrop } from "./redux/sortDropSlice";

function App() {

  const dispatch = useDispatch();

  const isLoading = useSelector((state) => {
      return state.loader.isLoading;
  });

  const showFieldDrop = useSelector((state) => {
    return state.fieldDrop.showFieldDrop;
  });

  const showSortDrop = useSelector((state) => {
    return state.sortDrop.showSortDrop;
  });


  function mainClick() { //we use this to disable the search fields dropdown or any other 'floating windows' that should close on a 'general' body click
    if (showFieldDrop) { //close search fields drop down on body click
      dispatch(setShowFieldDrop(false));
    }

    if (showSortDrop) { //close SORT drop down on body click
      dispatch(setShowSortDrop(false));
    }
  }

  useEffect(() => { 
    // on app load, grab the stored list 
    let nfsaItems = JSON.parse(localStorage.getItem("nfsaItems"));
    if (nfsaItems !== null) dispatch(setListItems(nfsaItems));
  }, [dispatch]);

  useEffect(() => { //load google tag manager in head

    const headTagManager = document.createElement('script');
    headTagManager.text = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-WVHRZ2T');`;
    document.head.appendChild(headTagManager); 
    return (() => {
      headTagManager.remove();
    });
    
  }, []);

  useEffect(() => { //load google tag manager in body
    const bodyTagManager = document.createElement('noscript');
    bodyTagManager.text = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WVHRZ2T" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>`;
    document.body.prepend(bodyTagManager); 
    return (() => {
      bodyTagManager.remove();
    });
    
  }, []);



  return (
    <Router>
        <ScrollToTop />
        <div className="main" onClick={() => mainClick()}>
          <Header />

          <div className={`page`}>
            { isLoading && 
                <Loader />
            }
            <Routes> 
              <Route exact path="/" element={<Home />} />
              <Route path="/search/:sParams" element={<SearchResults />} />
              <Route path="/title/:id" element={<Title />} />
              <Route path="/list" element={<List />} />
              <Route path="/all-titles" element={<AllTitles />} />
              <Route path="/all-titles/:page" element={<AllTitles />} />
              <Route path="/request-form" element={<RequestForm />} />
              <Route path="/request-form/:ids" element={<RequestForm />} />
              <Route path="/about" element={<About />} />
              <Route path="/help" element={<Help />} />
              <Route path="*" element={<Home />} /> {/* default to home page if no route */}
            </Routes>
          </div>

          <Footer />
        </div>

        

    </Router>
  )
}

export default App;
