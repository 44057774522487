import "../../../assets/css/FacetSidebar.css"

import { Link } from 'react-router-dom';
import createFacetToggleUrl from "../../../helpers/createFacetToggleUrl";
import getSearchFromRoute from "../../../helpers/getSearchFromRoute";
import sendFacetGoogle from "../../../helpers/sendFacetGoogle";
import { useSelector} from 'react-redux';
import cleanFacetValue from "../../../helpers/cleanFacetValue";
import React from "react";
import generateIconClass from '../../../helpers/generateIconClass';

export default function FacetSidebar({facet, facetName, title, groupOrderCount, setGroupOrderCount}) {

    const searchFacets = useSelector((state) => {
        return state.search.searchFacets;
    });
    
    if (!facet.buckets && facet.buckets.length < 1) return null;

    let urlSearch = getSearchFromRoute("/search/");

    let facetBuckets = [...facet.buckets]; //we make a copy so we can sort

    facetBuckets.sort((a, b) => { //sort so that the SELECTED facets are placed at the top (within a group)
        let facetAUp = searchFacets.some((e, i) => { //match with the global searchFacets array
            let cleanFacetVal = cleanFacetValue(e.facetValue); //remove any quotes or %20 stuff for compare
            if (cleanFacetVal === a._id && e.facetName === facetName) {
                return true;
            }
            return false;
        });
        
        let facetBUp = searchFacets.some((e, i) => { //match with the global searchFacets array
            let cleanFacetVal = cleanFacetValue(e.facetValue); //remove any quotes or %20 stuff for compare
            if (cleanFacetVal === b._id && e.facetName === facetName) {
                return true;
            }
            return false;
        });

        if (facetAUp === facetBUp) return 0;
        if (facetAUp) return -1; 
        if (facetBUp) return 1;

        return 0;
    });

    let facetLinks = [];
    facetBuckets.forEach((f,i) => {
        facetLinks.push(<li key={i}>{createLink(f)}</li>)
    });

    function createLink(f) {

        let facetValue = f._id; 

        let newQueryString = createFacetToggleUrl(urlSearch, facetName, facetValue);

        //check if this facet link is in use
        let facetToggled = searchFacets.some((e, i) => {
            let unquotedFacetValue = cleanFacetValue(e.facetValue); //remove quotes for compare
            if (unquotedFacetValue === facetValue && e.facetName === facetName) {
                return unquotedFacetValue === facetValue
            }
            return false;
        })

        return (
            <>
                <Link rel="nofollow" 
                onClick={() => facetLinkClick(facetName, f._id, facetToggled, newQueryString)} 
                className={`facetLink${(facetName === 'subMedium') ? generateIconClass(facetName, f._id) : ''}${ facetToggled ? ' toggle' : ''}`} 
                to={`/search/${newQueryString}`}>
                    
                    <span className="facetTitle">{f._id}</span>
                    { facetToggled ? null : <span className="facetCount"><span>{f.count}</span></span> }
                </Link>
            </>
            
        );
        
    }

    const facetLinkClick = (facetName, facetValue, toggle, queryString) => {
        if (!toggle) { //if turning a facet on, send the click data to the google.
            sendFacetGoogle(facetName, facetValue);
            let tmpFacets = [...searchFacets];
            tmpFacets.push({facetName: facetName, facetValue: facetValue, url: queryString});
        } //WE NEED TO REMOVE AS WELL


    }
    

    return (
        <>
            <div className="inner">
                <h3 className="sidebarHeader">{title}</h3>
                <ul>
                { facetLinks.map((f, i) => {
                    return f;
                })}
                </ul>
            </div>
        </>
    );


}