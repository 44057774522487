import "../assets/css/SearchClear.css";

export default function SearchControlBox({clearText}) {
    return (
        <div className="clear">
            <span className="clearX" aria-label="Clear the current search" role="button" onClick={clearText}>
                <span>Clear</span>
            </span>
        </div>
    );
}