


export default function ListButton({isInList, title, onListClick}) {
    return (
        <>
        { isInList ? 
            <button 
            aria-label="Remove this result from your list?" 
            className="addListButton listed small" 
            onClick={() => onListClick(title.id, title.title.replaceAll("<mark>", "").replaceAll("</mark>", ""))}>
                <span>Remove</span>
            </button>
            : 
            <button 
            aria-label="Add this result to your list?" 
            className="addListButton small" 
            onClick={() => onListClick(title.id, title.title.replaceAll("<mark>", "").replaceAll("</mark>", ""))}>
                <span>Add</span>
            </button>
        } 
        </>
    )
}