import "../../assets/css/Home.css";
//import {useNavigate} from 'react-router-dom';
import SeoHeader from "../SeoHeader";
import React from "react";
//import resetTabIndex from "../helpers/resetTabIndex";
import useClearTabIndex from "../../hooks/useClearTabIndex";
import FacetButton from "./FacetButton";
import SearchControl from "../SearchControl";


export default function Home() {

    useClearTabIndex();

    const [facetChecks, setFacetChecks] = React.useState([]);
    const [showFacetsPanel, setShowFacetsPanel] = React.useState(false);

    function handleHomeFieldCheck(e) {
        let facet = e.target.value;
        if (facetChecks.includes(facet)) {
            let filteredFacets = facetChecks.filter(item => item !== facet);
            setFacetChecks([...filteredFacets]);
        } else {
            setFacetChecks( (newFacet) => {
                return [...newFacet, e.target.value]
            });
        }
    }

    return (
        <>
        <SeoHeader title={"NFSA"} metadesc={"National Film and Sound Archive, Search the Collection, Home Page"} /> 
        <div className="home wrapper simpleFade">

            <div className="homeCenter">

                <div className="homeTitleSection">
                    <div className="homeTitleSectionText">
                        <h1>
                            Australia's audiovisual archive
                        </h1>
                        <h2>
                            Search the Collection
                        </h2>
                    </div>
                </div>
                
                <div className={`homeFormSection${showFacetsPanel ? ' show' : ''}`}>
                {/* <div className="homeFormSection"> */}

                    <div className="homeFormSectionInner" id="contentStart">
                        <SearchControl homeFacets={facetChecks} setShowFacetsPanel={setShowFacetsPanel}/>
                    
                        <div className={`facetBoxContainer${showFacetsPanel ? ' show' : ''}`}>
                            <div className="facetBoxInner">
                                <div className="facetBoxText">Limit search to</div>
                                
                                <div className="facetButtons">

                                            <div className="mediaCol">
                                                <FacetButton handleHomeFieldCheck={handleHomeFieldCheck} iconClass={"iconArtefact"} labelText="Artefact" />
                                                <FacetButton handleHomeFieldCheck={handleHomeFieldCheck} iconClass={"iconMultimedia"} labelText="Multimedia" />
                                                <FacetButton handleHomeFieldCheck={handleHomeFieldCheck} iconClass={"iconSoftware"} labelText="Software"/>
                                                <FacetButton handleHomeFieldCheck={handleHomeFieldCheck} iconClass={"iconTelevision"} labelText="Television"/>
                                            </div>

                                            <div className="mediaCol">
                                                <FacetButton handleHomeFieldCheck={handleHomeFieldCheck} iconClass={"iconDocumentation"}labelText="Documentation"/>
                                                <FacetButton handleHomeFieldCheck={handleHomeFieldCheck} iconClass={"iconOnlineMedia"} labelText="Online Media"/>
                                                <FacetButton handleHomeFieldCheck={handleHomeFieldCheck} iconClass={"iconSoundPublished"} labelText="Sound Recording, Published"/>
                                            </div>

                                            <div className="mediaCol">
                                                <FacetButton handleHomeFieldCheck={handleHomeFieldCheck} iconClass={"iconFilm"} labelText="Film"/>
                                                <FacetButton handleHomeFieldCheck={handleHomeFieldCheck} iconClass={"iconRadio"} labelText="Radio"/>
                                                <FacetButton handleHomeFieldCheck={handleHomeFieldCheck} iconClass={"iconSoundUnpublished"} labelText="Sound Recording, Unpublished"/>
                                            </div>
                                </div>
                            </div>
                        </div>

                        {/* </form> */}
                    </div>
                </div>

                
                

                
            </div>
            
            <div className="bottomNotification wrapper">
                    <div className="inner">
                        Disclaimer: Titles and related text are often exact transcriptions from original items and reflect attitudes of the time. Some text may also contain inappropriate or offensive language. This collection contains descriptions, images and audio recordings from audiovisual material relating to Indigenous Australians. Users should be aware that, in some Aboriginal and Torres Strait Islander communities, seeing the names and/or images of deceased persons may cause sadness or distress, particularly to the relatives of these people.
                    </div>
                </div>
            
            

        </div>
        </>
    );

}