import { Link } from 'react-router-dom';
import React from "react";
import sendFacetGoogle from '../../helpers/sendFacetGoogle';
import wrapFacetValueCommas from '../../helpers/wrapFacetValueCommas';

export default function TitleDetails({title}) {

    // function showDate(date) {

    //     //new Date(year, monthIndex, day)
    //     // title.FromMonth
    //     // title.FromDay
    //     // title.FromYear
        
    //     // //only if year has a value return the year
    //     // if (
    //     //     (title.FromDay === null || title.FromDay === 0) &&  
    //     //     (title.FromMonth === null || title.FromMonth === 0) && 
    //     //     (title.FromYear !== null && title.FromYear !== 0)
    //     //     ) {
    //     //     return title.FromYear;
    //     // }

    //     // // if only month and year
    //     // if (
    //     //     (title.FromDay === null || title.FromDay === 0) &&  
    //     //     (title.FromMonth !== null || title.FromMonth !== 0) && 
    //     //     (title.FromYear !== null && title.FromYear !== 0)
    //     //     ) {
            
    //     //     let _displayDate = new Date(title.FromYear, title.FromMonth);
    //     //     var _month = _displayDate.toLocaleString('default', { month: 'long' });
    //     //     let _stringDate = _month + " " + _displayDate.getFullYear();

    //     //     return _stringDate;
    //     // }

    //     // let displayDate = new Date(title.FromYear, title.FromMonth, title.FromDay);
    //     // var month = displayDate.toLocaleString('default', { month: 'long' });
    //     // let stringDate = displayDate.getDay() + " " + month + " " + displayDate.getFullYear();

    //     return stringDate;
    // }


    return (

        <div className="sideTitle details">
            <ul>
                { title.id && 
                    <li>
                        <span className='titleLabel'>NFSA ID</span>
                        <span className='titleValue'>{ title.id }</span>
                    </li>
                }
                {/* { title.alternativeTitles && title.alternativeTitles.length > 0 && 
                    <li>
                        <span className='titleLabel'>Alternative Title:</span>
                        <span className='titleValue'>
                        { title.alternativeTitles.map((t, index) => {
                                return (
                                    <span key={index}>{t}</span>
                                )
                            })
                        }
                        </span>
                    </li>
                } */}
                
                { title.subMedium &&
                    <li>
                        <span className='titleLabel'>Medium</span>
                        <span className='titleValue'>
                            <Link onClick={() => sendFacetGoogle("subMedium", title.subMedium)} className="tagTitle" to={`/search/subMedium=${encodeURIComponent(wrapFacetValueCommas(title.subMedium))}`}>{ title.subMedium }</Link>
                        </span>
                    </li>
                }

                { title.productionDates && title.productionDates.length > 0 && (
                    title.productionDates.map((d, index) => {
                        
                        return (
                            <li key={index} className="smalltitleRow">
                                <span className='titleLabel'>{ d.type } date</span>
                                <span className='titleValue'>
                                    { d.toYear == null ?
                                        <span> {d.fromYear} </span> :
                                        <span> {d.fromYear} - { d.toYear} </span>
                                    }
                                </span>
                            </li>
                        )})
                )}


                { title.colour &&
                    <li>
                        <span className='titleLabel'>Colour</span>
                        <span className='titleValue'>
                            <Link onClick={() => sendFacetGoogle("colour", title.colour)} className="tagTitle" to={`/search/colour=${encodeURIComponent(title.colour)}`}>{ title.colour }</Link>
                        </span>
                    </li>
                }
                { title.medium &&
                    <li>
                        <span className='titleLabel'>Medium</span>
                        <span className='titleValue'>
                            <Link onClick={() => sendFacetGoogle("medium", title.medium)} className="tagTitle" to={`/search/medium=${wrapFacetValueCommas(encodeURIComponent(title.medium))}`}>{ title.medium }</Link>
                        </span>
                        
                    </li>
                }
                { title.forms &&
                    <li>
                        <span className='titleLabel'>Form</span>
                        <span className='titleValue'>
                            { title.forms.map((title, index) => {
                                    return (
                                        <React.Fragment key={index}><span>&nbsp;
                                            <Link onClick={() => sendFacetGoogle("forms", title)} className="tagTitle" to={`/search/forms=${wrapFacetValueCommas(encodeURIComponent(title))}`}>{ title }</Link>
                                        </span> </React.Fragment>
                                    )
                                })
                            }
                        </span>
                    </li>
                }
                { title.parentTitle && title.parentTitle.genres && title.parentTitle.genres.length > 0 &&
                    <li className="categories">
                        <span className='titleLabel'>Genres</span>
                        <span className='titleValue'>
                            {   title.parentTitle.genres.map((title, index) => {
                                    return (
                                    <React.Fragment key={index}><span>&nbsp;
                                        <Link onClick={() => sendFacetGoogle("genres", title)} className="tagTitle" to={`/search/parentTitle.genres=${encodeURIComponent(title)}`}>{ title }</Link>
                                    </span> </React.Fragment>
                                    )
                                })
                            }
                        </span>
                    </li>
                }
                
                { title.durationSeconds && 
                    <li>
                        <span className='titleLabel'>Duration</span>
                        <span className='titleValue'>{ Math.floor(parseFloat(title.durationSeconds) / 60) } minutes </span>
                    </li>
                }
                { title.parentTitle && title.parentTitle.seasonNumber && 
                    <li>
                        <span className='titleLabel'>Season</span>
                        <span className='titleValue'>{ title.parentTitle.seasonNumber } </span>
                    </li>
                }
                { title.episodeNumber && 
                    <li>
                        <span className='titleLabel'>Episode</span>
                        <span className='titleValue'>{ title.episodeNumber } </span>
                    </li>
                }
                { title.parentTitle && title.parentTitle.seriesTitle && 
                    <li>
                        <span className='titleLabel'>Series Title</span>
                        <span className='titleValue'>{ title.parentTitle.seriesTitle } </span>
                    </li>
                }
                { title.countries && title.countries.length > 0 &&
                    <li>
                        <span className='titleLabel'>Countries</span>
                        <span className='titleValue'>
                        { 
                            title.countries.map((country, index) => {
                                return (
                                    <span key={index}>&nbsp;
                                        <Link onClick={() => sendFacetGoogle("country", country)} className="tagTitle" to={`/search/countries=${encodeURIComponent(country)}`}>{ country }</Link>
                                    </span>
                                )
                            })
                        }
                        </span>
                    </li>
                }
                { title.places && title.places.length > 0 &&
                    <li> 
                        <span className='titleLabel'>Locations</span>
                        <span className='titleValue'>
                        { 
                            title.places.map((location, index) => {
                                return (
                                    <span key={index}>&nbsp;
                                        <Link onClick={() => sendFacetGoogle("places", location.place)} className="tagTitle" to={`/search/places.place=${encodeURIComponent(location.place)}`}>
                                            { location.place }
                                        </Link>
                                    </span>
                                )
                            })
                        }
                        </span>
                    </li>
                }
                { title.languages && title.languages.length > 0 &&
                    <li>
                        <span className='titleLabel'>Languages</span>
                        <span className='titleValue'>
                        {  title.languages.map((language, index) => {
                                    return (
                                        <span key={index}>&nbsp;
                                            <Link onClick={() => sendFacetGoogle("languages", language)} className="tagTitle" to={`/search/languages=${encodeURIComponent(language)}`}>{ language }</Link>
                                        </span>
                                    )
                                })
                            }
                        </span>
                    </li>
                }
                
            </ul>
        </div>

    );
}