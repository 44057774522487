import { createSlice } from "@reduxjs/toolkit";

//This is for the FILTER POP OUT ON MOBILE
export const filterMenuSlice = createSlice({
    name: "filterMenu",
    initialState: {
        showFilterMenu: false,
    },
    reducers: {
        setShowFilterMenu: (state, action) => {
            state.showFilterMenu = action.payload;
        },
    },
});

export const { setShowFilterMenu } = filterMenuSlice.actions;

export default filterMenuSlice.reducer;