/* this function removes a value from a string of ORs or ANDs eg:
query=mad max&fSubMedium=Film||Television||Documentation
OR
query=mad max&fSubMedium=Film&,Television,Documentation
it takes: query (full query string) FacetName (eg fSubMedium) and removeValue

It also needs to clean the ||'s from the string depending if there are extra OR values left over*/

export default function removeANDORValue(query, facetName, removeValue) {
    //console.log("removing OR value from: " + query + "[" + facetName + "][" + removeValue + "]");
    //query=green&fSubMedium=Film||Television||Documentation&fFormList=Short

    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
       // console.log("checking AND/OR on facetName " + facetName + " removevalue " + removeValue);
        if (pair[0] === facetName) {
            
            let orTrue = true; //if false, then its an AND
            //console.log("Removing AND/OR on:" + decodeURIComponent(pair[1]));
            let individualItems = [];
            if (decodeURIComponent(pair[1]).includes("|")) {
                individualItems = decodeURIComponent(pair[1]).split("|");
            } else if (decodeURIComponent(pair[1]).includes(",")) {
                //console.log("Removing AND comma on:" + decodeURIComponent(pair[1]));
                individualItems = decodeURIComponent(pair[1]).split(",");
                orTrue = false;
            }

            //remove the removeValue
            let leftoverItems = individualItems.filter(item => item !== decodeURIComponent(removeValue));

            //now rebuild the string with the leftover OR/AND items.
            let newItemStr = "";
            leftoverItems.forEach((o,i) => {
                newItemStr += o;
                if (i < (leftoverItems.length - 1)) {
                    if (orTrue) {
                        newItemStr += "|";
                    } else {
                        newItemStr += ",";
                    }
                    
                }
            });

            newItemStr = encodeURIComponent(newItemStr);
            //now replace the original OR'ed string with our new one
            let newQuery = query.replace(pair[1], newItemStr);
            return newQuery;
        }
    }

    return query;
}

// export default function removeANDORValue(query, facetName, removeValue) {
//     //console.log("removing OR value from: " + query + "[" + facetName + "][" + removeValue + "]");
//     //query=green&fSubMedium=Film||Television||Documentation&fFormList=Short

//     var vars = query.split('&');
//     for (var i = 0; i < vars.length; i++) {
//         var pair = vars[i].split('=');
//         if (pair[0] === facetName) {
            
//             let orTrue = true; //if false, then its an AND

//             let individualItems = [];
//             if (decodeURIComponent(pair[1]).includes("||")) {
//                 individualItems = decodeURIComponent(pair[1]).split("||");
//             } else if (decodeURIComponent(pair[1]).includes("&&")) {
//                 individualItems = decodeURIComponent(pair[1]).split("&&");
//                 orTrue = false;
//             }

//             //remove the removeValue
//             let leftoverItems = individualItems.filter(item => item !== decodeURIComponent(removeValue));

//             //now rebuild the string with the leftover OR items.
//             let newItemStr = "";
//             leftoverItems.forEach((o,i) => {
//                 newItemStr += o;
//                 if (i < (leftoverItems.length - 1)) {
//                     if (orTrue) {
//                         newItemStr += "||";
//                     } else {
//                         newItemStr += "&&";
//                     }
                    
//                 }
//             });

//             newItemStr = encodeURIComponent(newItemStr);
//             //now replace the original OR'ed string with our new one
//             let newQuery = query.replace(pair[1], newItemStr);
//             return newQuery;
//         }
//     }

//     return query;
// }