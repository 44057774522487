export default function checkFacetInUse(sText, queryParam) {
    //console.log("checking query value for: " + queryParam);
    var vars = sText.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (pair[0] === queryParam) {

            //console.log("returning true for: " + queryParam);
            return true;
            //console.log("returning" + pair[1]);
            //return decodeURIComponent(pair[1]);
        }
    }
    //console.log('Query variable %s not found', v);
   // console.log("returning FALSE for: " + queryParam);
    return false;
}