//Just on onClick function used across multiple components to capture facet actions.
import googleTagPush from "./googleTagPush";

const creditLinkClicked = (creditName, creditRole) => {
    //console.log("link clicked with CREDIT: " + creditName + " and ROLE " + creditRole);
    googleTagPush('credit_selected', {
        "credit_name": creditName,
        "credit_role": creditRole
    });
}

export default creditLinkClicked;