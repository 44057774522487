import FacetSidebar from "./FacetSidebar";
import FacetSidebarYear from "./FacetSidebarYear";
import React from "react";
import { useSelector} from 'react-redux';

export default function SearchSideBar({facetCounts}) {

    const searchFacets = useSelector((state) => {
        return state.search.searchFacets;
    });


    let facetGroups = [
        "subMedium",
        "forms",
        //"mediaFormatNames", //this has facet values with inches quotes: " that break the listing.
        "colour",
        "year", //needs custom stuff because it just does.
        "countries",
        "parentTitle.genres",
       // "durationSeconds", //this needs custom stuff because its a number not a string
        "languages",
        "mediaFileExtensions",
        "mediaFileTypes",
        "mediaItemTypes",
        "mediaItemUsages",
       // "mediaTypes",
        //"parentTitle.medium",
        "restrictionStatus",
        //"techCodes",
    ];


    const getGroupTitle = (facet) => { //this is just easier than multi/object array
        switch(facet) {
            case "colour" : return "Colour";
            case "countries" : return "Country";
            case "forms" : return "Form";
            case "languages" : return "Language";
            case "mediaFileExtensions": return "File Extensions";
            case "mediaFileTypes": return "File Type";
            case "mediaItemUsages" : return "Usage";
            case "mediaItemTypes" : return "Format";
            //case "mediaTypes" : return "Type";
            case "parentTitle.genres": return "Genre";
            //case "parentTitle.medium": return "Medium"; //no idea what to call this
            case "restrictionStatus": return "Status";
            case "subMedium": return "Medium";
            case "mediaFormatNames": return "File Formats";
            case "techCodes": return "Technical Info";
            default: return facet;
        }
    }

    const listFacetGroups = () => {

        facetGroups.sort((a, b) => { //sort so the groups with selected facets are moved to the top

            let facetANameMatch = searchFacets.some((e, i) => { //match with the global searchFacets array
                if (a === e.facetName) {
                    return true;
                }
                return false;
            });

            let facetBNameMatch = searchFacets.some((e, i) => { //match with the global searchFacets array
                if (b === e.facetName) {
                    return true;
                }
                return false;
            });
            if (facetANameMatch === facetBNameMatch) return 0;
            if (facetANameMatch) return -1;
            if (facetBNameMatch) return 1;

            return 0; 
        });

        let groups = [];

        facetGroups.forEach((fg, i) => {
            if (facetCounts[fg] && facetCounts[fg].buckets && facetCounts[fg].buckets.length > 0) {
                if (fg === "year") {
                    groups.push(<FacetSidebarYear facetCounts={facetCounts} />)
                } else {
                    groups.push(<FacetSidebar facet={facetCounts[fg]} facetName={fg} title={getGroupTitle(fg)} />)
                }
                
            }
        });

        return (
            <>
            { groups.map((group, index) => {
               
                return (
                    <React.Fragment key={index}>
                        <div className="facetSidebar">
                        {group}
                        </div>
                    </React.Fragment>
                )
            })}
            </>
        )
    }

    return (

        <>
            <h2 className="sidebarTitle">Filter your search by:</h2>
            {listFacetGroups()}
        </>

    );
}