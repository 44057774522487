/*
    strip off all the encoding AND quotes, but only do this on the url path

So if url path comes in as: 
query=alice&subMedium=%22Sound%20Recording%2C%20Published%22

And the facet value to compare is:
"Sound Recording, Published"

We need to create a function that converts the urlPath facet VALUE into >>Sound Recording, Published<<
*/
export default function cleanFacetValue(str) {
    //turn %22Sound%20Recording%2C%20Published%22 into Sound Recording, Published 
   // console.log("cleanFacetValue: " + str);

    let cleanStr = "";
    //first decode
    cleanStr = decodeURIComponent(str);

    //now remove any quotes from start or end
    cleanStr = cleanStr.replaceAll("\"", "");

    return cleanStr;

}