import SeoHeader from "../SeoHeader";
import "../../assets/css/AllTitles.css";
import AllTitlesSearchItem from "./AllTitlesSearchItem";
import { useParams } from 'react-router-dom';
import React, { useEffect } from "react";
import { setLoading } from '../../redux/loaderSlice';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
//import { useDispatch, useSelector } from 'react-redux';

export default function AllTitles() {

    const dispatch = useDispatch();


    const { page = "" } = useParams();
   //console.log(page);

    const [results, setResults] = React.useState(null);
    const [error, setError] = React.useState(null);
    const [nextToken, setNextToken] = React.useState(null);

     //previous can only work going back once, any more and we need to be provided with a prevToken (or store all nextTokens in big array that keeps getting bigger)
    //const [prevToken, setPrevToken] = React.useState(null);


    useEffect(() => { 
        async function fetchAllTitles() {
            try {
                let getURL = `${process.env.REACT_APP_APIURL}all-titles`;
                if (page) getURL = `${process.env.REACT_APP_APIURL}all-titles/${page}`;
                //console.log("GetURL: " + getURL);

                let response = await fetch(getURL);
                let jsonData = "";
                if (response.ok) { // if HTTP-status is 200-299
                    try {
                        jsonData = await response.json();
                    } catch(jsonError) { //catch empty body / no results
                        console.error(jsonError);
                    }
                } else {
                    throw new Error("HTTP-Error: " + response.status);
                }
                
                if (jsonData !== "") {
                    //console.log(jsonData);
                    setError(null); //clear any old errors
                    setResults(jsonData.titles);
                    setNextToken(jsonData.nextPageToken);
                    dispatch(setLoading(false));
                } 
                else {
                    //console.log(jsonData);
                    dispatch(setLoading(false));
                }
            } catch(e) {
                dispatch(setLoading(false));
                console.log(e);
                setError(e);
            }
        }

        fetchAllTitles();
        
    }, [page, dispatch]);

    // const nextClicked = (currentToken) => {
    //     console.log("setting prev token to: " + currentToken);
    //     setPrevToken(currentToken);
    // }

    if (error) return (
        <div className="searchResults wrapper">
            <div className="content">
                <div className="topError">{ error.message }</div>
            </div>
        </div>
    );

    return (
        <>
            {/* <SeoHeader canonical={`${document.location.href}/${page}`} title={"Browse All Titles"} metadesc={"National Film and Sound Archive, Search the Collection, Browse all titles in the collection"} />*/}
            
            <SeoHeader title={"Browse All Titles"} metadesc={"National Film and Sound Archive, Search the Collection, Browse all titles in the collection"} /> 

            <div className="alltitles wrapper simpleFade">
                <div className="content cms">
                    <h2>Browse all Titles</h2>
                    <br />

                    <div className="searchItems">
                        {  
                            results && results.map((title) => {
                                return (
                                    <AllTitlesSearchItem key={title.id} title={title} />
                                )
                            })
                        }
                    </div>
                    <br />
                    <nav className="allTitlesNav">
                        {/* <Link to={`/all-titles/${prevToken}`}>Prev</Link> */ /*cant do PREVIOUS links as we only have next page tokens, no previous page tokens. */}
                        {/* <Link onClick={() => nextClicked(page)} to={`/all-titles/${nextToken}`}>Next</Link> */}
                        <Link to={`/all-titles/${nextToken}`}>Next Page</Link>
                    </nav>
                    <br /><br />
                </div>
            </div>

           

        </>
    );

}