import { Link } from 'react-router-dom';
import replaceQueryValue from "../helpers/replaceQueryValue";
import "../assets/css/Paginator.css";
import getSearchFromRoute from "../helpers/getSearchFromRoute";

export default function Paginator({current, total, route}) {
    let query = getSearchFromRoute("/search/");
    var pageNumbers = [];
    let displayAmount = 5; //how many number links shown at a time
    let resultsCount = 25; //how many search results per page - need this for calculations!

    let totalPages = Math.ceil(total / resultsCount);

    //responsive only show 5 numbers at a time
    //if (window.matchMedia("(max-width: 1240px)").matches) displayAmount = 5;

    var edge = Math.ceil(total / resultsCount); //edge is the FINAL/LAST PAGE

    var offset = 1;
    if (current >= (displayAmount / 2)) { //if current page is past halfway from the start
        //how far is it past halfway?
        var pastOffset = current - Math.floor(displayAmount / 2);
        offset = offset + pastOffset;

        if (current >= (edge - Math.floor(displayAmount / 2))) { //also is current page past halfway before the end? eg within the last 5 before the end
            //move offset backwards to grab the full displayamount
            offset = offset - Math.floor(displayAmount / 2);
        }
    }

    for(let i = 0; i < displayAmount; i++) {

        var pageNum = offset + i;

        //stop if no more results
        if ( pageNum > edge) {
            break;
        }

        let newQuery = replaceQueryValue(query, "page", pageNum);
        
        pageNumbers.push(
            <span className={`pageNumber ${ pageNum === parseInt(current) ? 'current' : '' }`} key={i}>
                { pageNum === parseInt(current) ? <span>{pageNum}</span> : <Link to={`/${route}/${newQuery}`}>{pageNum}</Link>}
            </span>
        );
    }

    return (
        <div className="paginator">
            <div className="inner">
                <div className="left">
                    <span className={`first${current <= 1 ? ' disabled': ''}`}>
                        { current <= 1 ? <span>First</span>: <Link to={`/${route}/${replaceQueryValue(query, 'page', 1)}`}>First</Link> }
                    </span>
                    <span className={`prev${current <= 1 ? ' disabled': ''}`}>
                        { current <= 1 ? <span>Previous</span>: <Link to={`/${route}/${replaceQueryValue(query, 'page', parseInt(current) - 1)}`}>Previous</Link> }
                    </span>
                </div>
                <div className="middle">
                    { current > 2 ?
                        <div className='pageNumbersStart'>
                            <Link to={`/${route}/${replaceQueryValue(query, 'page', 1)}`}>1</Link>
                            <span className='dots'>...</span>
                        </div>
                        : null
                    }
                    <div className='pageNumbers'>
                        {pageNumbers}   
                    </div>
                    { (parseInt(current) + 1) < edge ?
                    <div className='pageNumbersEnd'>
                        <span className='dots'>...</span>
                        <Link to={`/${route}/${replaceQueryValue(query, 'page', parseInt(totalPages))}`}>{totalPages}</Link>
                    </div>
                    : null
                    }
                </div>
                <div className="right">
                    <span className={`next${current >= edge ? ' disabled': ''}`}>
                        { current >= edge ? <span>Next</span> : <Link to={`/${route}/${replaceQueryValue(query, 'page', parseInt(current) + 1)}`}>Next</Link> }
                    </span>
                    <span className={`last${current >= edge ? ' disabled': ''}`}>
                        { current >= edge ? <span>Last</span> : <Link to={`/${route}/${replaceQueryValue(query, 'page', parseInt(totalPages))}`}>Last</Link> }
                    </span>
                </div>
            </div>
            
        </div>
    )
}