import "../../assets/css/List.css";

import ListItem from "./ListItem";
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from "react";
import { clearList } from '../../redux/listSlice';
import { removeItemFromList } from '../../redux/listSlice';
import { setLoading } from '../../redux/loaderSlice';
import { Link } from 'react-router-dom';
import Modal from "../Modal";
import SeoHeader from "../SeoHeader";
import useClearTabIndex from "../../hooks/useClearTabIndex";
//import { API } from "aws-amplify";

export default function List() {

    const dispatch = useDispatch();

    useClearTabIndex();

    const { items } = useSelector((state) => { //get list of item ids
        return state.list
    });

    //const [results, setResults] = React.useState(null);
    const [results, setResults] = React.useState([]);
    const [error, setError] = React.useState(null);
    const [noReload, setNoReload] = React.useState(false);
    const [showConfirmModal, setShowConfirmModal] = React.useState(false);
    const [commaString, setCommaString] = React.useState("");
    
    function removeItem(id) {
        setNoReload(true); 
        dispatch(removeItemFromList(id));

        //remove from results array (without making new api call)
        let newResults = results.filter(function( res ) {
            return res.id !== id;
        });
        setResults(newResults);
    }

    useEffect(() => {

        var idlist = "";
        items.forEach((e,i) => {
            idlist += e + ",";
        });
        setCommaString(idlist.slice(0, -1)); //slice off final ampersand

    }, [commaString, items]);

    useEffect(() => {
        
        async function fetchItems() {
            try { 
                
                let getURL = `${process.env.REACT_APP_APIURL}title-list/${commaString}`;
                //console.log("GetURL: " + getURL);

                let response = await fetch(getURL);
                let jsonData = "";
                if (response.ok) {
                    jsonData = await response.json();
                    if (jsonData !== "") {
                        //console.log(jsonData);
                        dispatch(setLoading(false));
                        setResults(jsonData); 
                    }
                } else {
                    throw new Error("HTTP-Error: " + response.status);
                }
                
            } catch(error) {
                dispatch(setLoading(false));
                setError(error); 
            }
        }

        //only refetch on load - wouldnt need to do this if react allowed me to set state without a dependency array!!! :P
        if ((items.length > 0) && !noReload && commaString !== "") { 
            dispatch(setLoading(true));
            fetchItems();
        }
        
    }, [noReload, items, dispatch, commaString]);

    if (items.length < 1) {
        return (
            <div className="list wrapper">
                <div className="content">
                    <div>You have no items in your list.</div>
                </div>
            </div>
        );
    }

    if (error) return (
        <div className="list wrapper">
            <div className="content">
                <div className="topError">{ error.message }</div>
            </div>
        </div>
    );

    return (
        <>
        <SeoHeader title={`Your List${items.length > 0 ? ` (${items.length})` : ''}`} metadesc={"National Film and Sound Archive, Search the Collection, Your list page"} />
        <div className="list wrapper simpleFade">

        { showConfirmModal && 
                <Modal confirm={() => dispatch(clearList())} close={() => setShowConfirmModal(!showConfirmModal)}>
                    <div className="confirmClear">
                        Are you sure you want to delete all of your List items?
                    </div>
                </Modal>
            }

            <div className="content" id="contentStart">

                <div className="listCols">
                    <div className="listSide">
                        <div className="listTitle">
                            <span>Your current list</span>
                        </div>
                        <p>The list function allows you to compile a list of titles. </p>
                        <p>Submit an Access Request using the button below. 
                            </p>

                        <p>Note that the majority of the NFSA collection is in copyright. We cannot supply copies or provide online access to collection items in copyright.</p>
                        <p>Access to this material is limited to onsite viewing at the NFSA.</p>
                        <div>You are about to request <strong>{ items.length }</strong> items from your list:</div>
                        <br />
                        <Link to={`/request-form/${commaString}`} className="accessLink">
                            Access Request <span>({ items.length })</span>
                        </Link>

                    </div>
                    <div className="listItems">
                        <ol> 
                            {  
                                results && results.map((title, index) => {
                                    return (
                                        <ListItem key={index} title={title} onRemove={removeItem} />
                                    )
                                })
                            }
                        </ol>
                        
                        <div className="listBottom">
                            <div onClick={() => setShowConfirmModal(!showConfirmModal)} className="clearListButton">
                                Clear All
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
        </>
    );
}