import React, { useEffect } from "react";
import MasonryHoldingItem from "./MasonryHoldingItem";
import "../../../assets/css/MasonryHoldings.css";
import HoldingsMoreButton from "./HoldingsMoreButton";


export default function MasonryPreviews({title}) {

    const [fadeIn, setFadeIn] = React.useState(false);

    const [displayedItems, setDisplayedItems] = React.useState([]);

    const [masonryCol1, setMasonryCol1] = React.useState([]);
    const [masonryCol2, setMasonryCol2] = React.useState([]);
    const [masonryCol3, setMasonryCol3] = React.useState([]);

    //const [viewLimit, setViewLimit] = React.useState(6);
    const [viewLimit, setViewLimit] = React.useState(6);
    const [showAll, setShowAll] = React.useState(false);

    const tabletLayout = window.matchMedia("(max-width: 1020px)").matches;
    const mobileLayout = window.matchMedia("(max-width: 630px)").matches;
   
    useEffect(() => {

        const previewItems = title.media.filter( //remove duplicate itemUsage types for display
            (obj, index) => {
                return Object.hasOwn(obj, 'preview'); 
            }
        );
        setDisplayedItems([...previewItems]);

    }, [title.media]);

    useEffect(() => { //after 300ms remove the fadeIn class on all child holding items
        const timeoutId = setTimeout(() => {
            setFadeIn(true);
        }, 300);
        return () => clearTimeout(timeoutId);
    }, [fadeIn]);
    
    useEffect(() => {
        if (tabletLayout || mobileLayout) {
            setViewLimit(6);
        }
    },[viewLimit, tabletLayout, mobileLayout]);

    useEffect(() => {

        let iterations = 3;
        if (tabletLayout && !mobileLayout) {
            iterations = 2;
        } else if (mobileLayout) {
            iterations = 1;
        }

        //create 3 arrays representing each column but ordered as counting from left to right across the columns:
        let newCol1 = [];
        let newCol2 = [];
        let newCol3 = [];

        for (let i = 0; i <= displayedItems.length; i += iterations) { //every three iterations desktop, 2 tablet, 1 mobile

            if (i >= viewLimit && !showAll) {
                break;
            }

            newCol1.push(displayedItems[i]); //col 1 first row item (1 - 0 - 0)

            if (mobileLayout) continue; //only need 1 col for mobile.

            if ((i + 1) <= (displayedItems.length - 1)) { //col 2 contains first row + 1 offset (0 - 1 - 0)
                newCol2.push(displayedItems[i + 1]);
            }
            if (!tabletLayout) { //third col not needed tablet layout, only 2 cols
                if ((i + 2) <= (displayedItems.length - 1)) { //col 3 contains first row + 2 offset (0 - 0 - 1)
                    newCol3.push(displayedItems[i + 2]);
                }
            }

            

        }
        setMasonryCol1([...newCol1]);
        if (!mobileLayout) setMasonryCol2([...newCol2]);
        if (!tabletLayout) setMasonryCol3([...newCol3]);
        // setMasonryCol1([...newCol1]);
        // setMasonryCol2([...newCol2]);
        // setMasonryCol3([...newCol3]);

    }, [title.media, displayedItems, viewLimit, showAll, tabletLayout, mobileLayout]);

    const onShowAll = () => {
        //setFadeIn(false);
        setShowAll(!showAll);
    }

    return (
        <>
            <h3 className="previewsTitle">Previews</h3>
            <div className="masonryHoldings">

                <div className="col col1">
                
                    {
                        masonryCol1.map((holding, i) => {
                            return (
                                holding && <MasonryHoldingItem holding={holding} fadeIn={fadeIn} title={title} key={i} />
                            )
                        })
                    }
                    { mobileLayout && <HoldingsMoreButton showAll={showAll} holdingItemsLength={displayedItems.length} filteredItemsLength={null} viewLimit={viewLimit} onShowAll={onShowAll}/>}
                </div>
                
                {!mobileLayout &&
                    <div className="col col2">
                        {
                            masonryCol2.map((holding, i) => { 
                                return (
                                    holding && <MasonryHoldingItem holding={holding} fadeIn={fadeIn} title={title} key={i} />
                                )
                            })
                        }
                        { tabletLayout && <HoldingsMoreButton showAll={showAll} holdingItemsLength={displayedItems.length} filteredItemsLength={null} viewLimit={viewLimit} onShowAll={onShowAll}/>}
                    </div>
                }

                {!tabletLayout && 
                    <div className="col col3">
                        {
                            masonryCol3.map((holding, i) => {
                                return (
                                    holding && <MasonryHoldingItem holding={holding} fadeIn={fadeIn} title={title} key={i} />
                                )
                            })
                        }
                        {/* { !showAll && displayedItems.length > viewLimit &&
                            <button className="showAllButton" onClick={() => onShowAll()}>
                                <span>+ {displayedItems.length - viewLimit}</span> additional holdings
                            </button>
                        }

                        { showAll && 
                            <button className="showAllButton remove" onClick={() => onShowAll()}>
                                Hide {displayedItems.length - viewLimit} holdings
                            </button>
                        } */}
                        <HoldingsMoreButton showAll={showAll} holdingItemsLength={displayedItems.length} filteredItemsLength={null} viewLimit={viewLimit} onShowAll={onShowAll}/>

                    </div>
                }

            </div>

            
        </>
    )
}