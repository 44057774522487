import { createSlice } from "@reduxjs/toolkit";

//This is for the Sort items dropdown
export const sortDropSlice = createSlice({
    name: "sortDrop",
    initialState: {
        showSortDrop: false,
    },
    reducers: {
        setShowSortDrop: (state, action) => {
            state.showSortDrop = action.payload;
        },
    },
});

export const { setShowSortDrop } = sortDropSlice.actions;

export default sortDropSlice.reducer;